<mat-card sm-restrict-width sm-center>
  <mat-card-title *ngIf='!clientHasStripeAccount'>
    {{requirementText}}
  </mat-card-title>
  <p sm-center *ngIf='!this.userService.isLoggedIn()'>In case of no-show your card will be charged {{noShowPercentage}}% of the booking cost.</p>
  <mat-card-content *ngIf="this.userService.isLoggedIn()">
    <div *ngIf="clientHasStripeAccount && !changeCard && cardInfo !== undefined">
      <p sm-center>In case of no-show your {{cardInfo.brand}} card ending in {{cardInfo.last4}} will be charged {{noShowPercentage}}% of the booking cost.</p>

      <a  class="change-card" (click)="changeCardOnFile()"  target="_blank">Change card on file</a>

    </div>
    <div *ngIf="!clientHasStripeAccount || cardInfo === undefined">
      <p sm-center>In case of no-show your card will be charged {{noShowPercentage}}% of the booking cost.</p>
      <p sm-center style='font-weight:bold'>Please enter your card details:</p>
      <div class='card-element'>
        <div #cardElement class='card-element-inner'>
        </div>
      </div>

      <div class='stripe-charge-buttons'>
        <p *ngIf='errorMessage || cardErrors' class='smc-stripe-error text-align-center'>{{errorMessage}}{{cardErrors}}</p>
      </div>

      <mat-checkbox [(ngModel)]="saveCardChecked">I authorize securely storing my card</mat-checkbox>
      <p sm-center class="gray-text margin-top-10 width-50 margin-left-right-auto">I authorize {{this.userService.getSalon().getName()}} to save my card securely with Stripe Payments and automatically charge this card in the future as agreed until I cancel this authorization.</p>
    </div>
    <div *ngIf="changeCard">
      <p sm-center style='font-weight:bold'>Please enter your card details:</p>
      <div class='card-element'>
        <div #cardElementChangeCard class='card-element-inner'>
        </div>
      </div>

      <div class='stripe-charge-buttons'>
        <p *ngIf='errorMessage || cardErrors' class='smc-stripe-error text-align-center'>{{errorMessage}}{{cardErrors}}</p>
      </div>
    </div>
  </mat-card-content>
  <div sm-center class="margin-top-20 ">
    <button
    class='smc-button-large smc-button-primary margin-top-20'
    mat-raised-button
    *ngIf="this.userService.isLoggedIn() && (!this.clientHasStripeAccount || cardInfo === undefined)"
    [disabled]="!carInputComplete || !saveCardChecked || savingStripe"
    (click)="saveCardToPayOnNoShow()"
    >
    {{ buttonText}} <mat-progress-spinner *ngIf="savingStripe"[diameter]="20" mode="indeterminate"></mat-progress-spinner>

    </button>
    <button
      class='smc-button-large smc-button-primary margin-top-20'
      mat-raised-button
      *ngIf="this.userService.isLoggedIn() && changeCard"
      [disabled]="!carInputComplete || savingStripe"
      (click)="saveCardToPayOnNoShow()"
    >
    {{ buttonText}} <mat-progress-spinner *ngIf="savingStripe" [diameter]="20" mode="indeterminate"></mat-progress-spinner>
    </button>
    <button
      class='smc-button-large smc-button-primary margin-top-20'
      mat-raised-button
      *ngIf="this.userService.isLoggedIn() && this.clientHasStripeAccount && !changeCard && cardInfo !== undefined"
      [disabled]="savingStripe"
      (click)="setupConfirmationSavedCard()"
    >
      {{ buttonText}} <mat-progress-spinner *ngIf="savingStripe"[diameter]="20"  mode="indeterminate"></mat-progress-spinner>
    </button>
<!--    <button-->
<!--      (click)="chargeAfterSave()"-->
<!--    >-->
<!--      charge after save-->
<!--    </button>-->
    <p *ngIf="this.userService.isLoggedIn()" sm-center class="gray-text margin-top-20"><strong>Cancellation Policy</strong></p>
    <p *ngIf="this.userService.isLoggedIn()" sm-center class="gray-text margin-top-10 width-50 margin-left-right-auto">{{ cancellationPolicy }}</p>
  </div>
</mat-card>
<!--*ngIf="!chargeOnNoShow"-->
<!--mat-raised-button-->
