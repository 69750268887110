<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

<salonmonster-tap-retry
  *ngIf="errorOccurred"
  (click)="loadPage()"
></salonmonster-tap-retry>

<mat-card *ngIf="!isLoading && !errorOccurred">
  <form [formGroup]="clientForm" (ngSubmit)="onClientSave()">
    <h2>Thank you for keeping your profile up to date.</h2>
    <br />

    <mat-form-field appearance="outline">
      <mat-label>First name *</mat-label>
      <input
        matInput
        type="text"
        name="firstName"
        formControlName="firstName"
      />
      <mat-error *ngIf="firstName.hasError('required')">
        Oops, a first name is required.
      </mat-error>

      <mat-error *ngIf="firstName.hasError('maxlength')">
        A first name can't exceed 30 letters.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Last name *</mat-label>
      <input
        matInput
        placeholder="Last Name"
        type="text"
        name="lastName"
        formControlName="lastName"
      />
      <mat-error *ngIf="lastName.hasError('maxlength')">
        A last name can't exceed 35 letter.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Select a Pronoun</mat-label>
      <mat-select
        placeholder="Select a Pronoun"
        name="pronouns"
        formControlName="pronouns"
      >
        <mat-option
          *ngFor="let pronoun of pronounOptions"
          [value]="pronoun.value"
        >
          {{ pronoun.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="pronouns.value == 4" appearance="outline">
      <mat-label>Enter Custom Pronouns</mat-label>
      <input
        matInput
        type="text"
        name="customerPronouns"
        formControlName="customerPronouns"
      />
      <mat-error *ngIf="email.hasError('maxlength')"
        >An customerPronouns can't exceed 35 characters.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email *</mat-label>
      <input matInput type="email" name="email" formControlName="email" />
      <mat-error *ngIf="email.hasError('validateEmail')"
        >Oops, a valid email address is required.</mat-error
      >
      <mat-error *ngIf="email.hasError('required')"
        >Oops, an email is required.</mat-error
      >
      <mat-error *ngIf="email.hasError('maxlength')"
        >An email can't exceed 100 characters.</mat-error
      >
      <mat-error *ngIf="email.hasError('clientEmailAvailability')"
        >Email already taken.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Home phone</mat-label>
      <input matInput type="tel" name="phone1" formControlName="phone1" />
      <mat-error *ngIf="phone1.hasError('maxlength')">
        Oops, this can't be longer than 20 characters.
      </mat-error>
      <mat-error *ngIf="phone1.hasError('validatePhone')"
        >Oops, this phone is invalid.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Mobile phone</mat-label>
      <input matInput type="tel" name="phone2" formControlName="phone2" />
      <mat-error *ngIf="phone2.hasError('maxlength')">
        Oops, this can't be longer than 20 characters.
      </mat-error>
      <mat-error *ngIf="phone1.hasError('validatePhone')"
        >Oops, this phone is invalid.</mat-error
      >
    </mat-form-field>
    <mat-error
      *ngIf="clientForm.hasError('atLeastOnePhoneRequired')"
      style="margin-bottom: 15px"
    >
      Either a home phone or work phone is required.
    </mat-error>

    <mat-form-field appearance="outline">
      <mat-label>Work phone</mat-label>
      <input matInput type="tel" name="workPh" formControlName="workPh" />
      <mat-error *ngIf="workPh.hasError('maxlength')">
        Oops, this can't be longer than 20 characters.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Address 1</mat-label>
      <input matInput type="text" name="address1" formControlName="address1" />
      <mat-error *ngIf="address1.hasError('maxlength')">
        Oops, this can't be longer than 40 characters.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Address 2</mat-label>
      <input matInput type="text" name="address2" formControlName="address2" />
      <mat-error *ngIf="address2.hasError('maxlength')">
        Oops, this can't be longer than 40 characters.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <input matInput type="text" name="city" formControlName="city" />
      <mat-error *ngIf="city.hasError('maxlength')">
        Oops, this can't be longer than 20 characters.
      </mat-error>
    </mat-form-field>

    <mat-list-item>
      <mat-form-field appearance="outline">
        <mat-label>Province</mat-label>
        <input
          matInput
          type="text"
          name="province"
          formControlName="province"
        />
        <mat-error *ngIf="province.hasError('maxlength')">
          Oops, this can't be longer than 25 characters.
        </mat-error>
      </mat-form-field>
    </mat-list-item>

    <mat-form-field appearance="outline">
      <mat-label>Postal</mat-label>
      <input matInput type="text" name="postal" formControlName="postal" />
      <mat-error *ngIf="postal.hasError('maxlength')">
        Oops, this can't be longer than 25 characters.
      </mat-error>
    </mat-form-field>

    <br />
    <mat-form-field appearance="outline">
      <mat-label>Select a country</mat-label>
      <mat-select
        placeholder="Select a Country"
        name="country"
        formControlName="country"
      >
        <mat-option *ngFor="let country of countries" [value]="country.getID()">
          {{ country.getCountryName() }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button
        mat-stroked-button
        secondaryButton
        sm-full-width
        (click)="viewChangePassword()"
      >
        Change Password
      </button>
      <button mat-raised-button primaryButton sm-full-width type="submit">
        {{ saveButtonText }}
      </button>
    </div>
  </form>
</mat-card>
