import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { StripeService } from '../../services/stripe.service';
import { UserService } from '../../services/user.service';
import { Booking } from '../../models/booking.model';
import { ClientService } from '../../services/client.service';
import { Client } from '../../models/client.model';
import { environment } from '../../../../environments/environment';
import { Stylist } from '../../models/stylist.model';


// @ts-ignore
declare var Stripe: stripe.StripeStatic;

@Component({
  selector: 'salonmonster-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['stripe-payment.component.scss']
})
export class StripePaymentComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('cardElementChangeCard') cardElementChangeCard: ElementRef;
  @Output('onBookingSave') onBookingSave: EventEmitter<any> = new EventEmitter();

  @Input() booking?: Booking;
  @Input() client?: Client;
  @Input() errorOccurredWhileSaving: boolean;

  @Input() mode: String = "booking"; //booking, waitlist, cardrequest
  public clientHasStripeAccount: Boolean = false;
  public noShowPercentage: number;
  @Input() refreshStripe;

  public stripe;
  public cardErrors;
  public newStripeClient;
  public card;
  public errorMessage;
  public saveCardChecked;
  public carInputComplete = false;

  public buttonText: string;
  public requirementText: string;
  public clientBookingPayments;
  public savingStripe: boolean = false;
  public stylist: Stylist;
  public cancellationPolicy: string;

  public style = {
    base: {
      color: '#32325D',
      fontWeight: 500,
      fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#CFD7DF',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };
  public totalPrice;
  public currency;
  public cardInfo = {
    brand: '',
    last4: '',
    exp_month: '',
    exp_year: '',
    funding: ''
  };
  public changeCard;

  constructor(
    private stripeService: StripeService,
    private ref: ChangeDetectorRef,
    public userService: UserService,
    private clientService: ClientService
  ) {


  }
  public async ngOnInit() {
    if (this.client) {
    } else if (this.booking) {
      if (this.userService.isLoggedIn()) {
        this.checkUserStripeAccount();
      }
      this.clientBookingPayments = this.userService.getSalon().getClientBookingPayments();
      this.noShowPercentage = this.userService.getSalon().getNoShowPercentage();

      if (this.mode === 'waitlist') {
        this.buttonText = 'Add me to the wait list';
        this.requirementText = 'We require a card on file to add you to the wait list.'
      } else if (this.mode === 'cardrequest') {
        this.buttonText = 'Save it';
        this.requirementText = 'We require a card on file for your appointments.';
      } else {
        this.buttonText = 'Book it';
        this.requirementText = 'We require a card on file to hold this booking.'
      }

      this.cancellationPolicy = this.stylist.getCancellationPolicy();
    }

  }


  public async ngAfterViewInit() {
    if (this.client) {
    } else if (this.booking) {
      const services = this.booking.getServices();
    }
    this.loadStripeCustomer();
    // this.clientBookingPayments = this.userService.getSalon().getClientBookingPayments);
    // console.log("hi");
    // console.log("enableOnlineBooking:", this.userService.getSalon().getEnableOnlineBooking);
    // console.log("clientBookingPayments:", this.userService.getSalon().getClientBookingPayments);
    // console.log("noShowPercentage:", this.userService.getSalon().getNoShowPercentage);

  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.userService.isLoggedIn()) {
      this.client = await this.clientService.getClientInfo().toPromise();
      this.checkUserStripeAccount();
    }
    if (changes.errorOccurredWhileSaving) {
      this.savingStripe = false;
    }
    // console.log('something changed: ', changes);
  }

  public async checkUserStripeAccount() {

    const client = await this.clientService.getClientInfo().toPromise();
    // console.log('checking user stripe account', client);
    const response = await this.stripeService.getClientStripeID({ clientID: client['id'] }).toPromise();
    (response) ? this.clientHasStripeAccount = response : this.clientHasStripeAccount = false;
    // console.log("has stripe account val:", this.clientHasStripeAccount);
    if (this.clientHasStripeAccount) {
      this.loadStripeCustomer();
    } else {
      this.newStripeClient = true;
      this.setupStripeElements('saveCard');
    }
  }

  async loadStripeCustomer() {
    // TODO: Move to config file
    this.stripe = Stripe(environment.stripePublishableKey);

    if (this.clientHasStripeAccount) {
      // console.log('client has stripe account - checking card number');

      this.cardInfo = await this.stripeService.getCustomerCardInfo({ clientID: this.client.getID() }).toPromise();
  
      // console.log('cardInfo: ' , this.cardInfo);

      //  } else {console.log("no card");}

      if (this.userService.isLoggedIn()) { // Don't initate stripe when user not logged in
        if (this.clientHasStripeAccount) {
          this.newStripeClient = false;
          // todo: not sure that this is returning the data
          this.cardInfo = await this.stripeService.getCustomerCardInfo({ clientID: this.client.getID() }).toPromise();
      
        }
        if (!this.clientHasStripeAccount || this.cardInfo === undefined) {
          this.newStripeClient = true;
          // console.log('no card: setup stripe elements');
          this.setupStripeElements('saveCard');
        }
        this.ref.detectChanges();
      } else if (this.mode === 'cardrequest') {
        //  card request mode - don't use logged in user id
      }
    }
  }

  public setupStripeElements(section) {
    const elements = this.stripe.elements();
    this.card = elements.create('card', { style: this.style });
    if (section === 'changeCard') {
      this.card.mount(this.cardElementChangeCard.nativeElement);
    } else {
      // console.log('trying to mount to' ,this.cardElement.nativeElement );
      this.card.mount(this.cardElement.nativeElement);
    }
    this.card.addEventListener('change', ({ error, complete }) => {
      this.carInputComplete = complete;
      this.cardErrors = error && error.message;
    });
  }

  public changeCardOnFile() {
    this.changeCard = true;
    this.ref.detectChanges();
    this.setupStripeElements('changeCard');
  }

  public saveCardToPayOnNoShow = async () => {
    this.savingStripe = true;
    // console.log("savingStripe: ", this.savingStripe);
    const clientSecret = await this.stripeService
      .clientIntentSecret({ clientID: this.client.getID(), newClient: this.newStripeClient }).toPromise();
    try {
      const { setupIntent, error } = await this.stripe.confirmCardSetup(

        clientSecret,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.client.getFirstName() + ' ' + this.client.getLastName(),
            },
          },
        }
      );
      // console.log('saveCardToPayOnNoShow, setupIntent: ', setupIntent, ' error: ', error);


      this.cardSetupConfirmationDone({ error, setupIntent });

    } catch (error) {
      this.savingStripe = false;
      // console.log('oops confirmCardSetup didnt work: ', error);
    }

  }

  public setupConfirmationSavedCard = async () => {
    // console.log('setupConfirmationSavedCard');
    this.savingStripe = true;
    const clientSecret = await this.stripeService
      .clientIntentSecret({ clientID: this.client.getID(), newClient: this.newStripeClient }).toPromise();
    const paymentMethods = await this.stripeService.getPaymentMethods({ clientID: this.client.getID() }).toPromise();
    try {
      const { setupIntent, error } = await this.stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethods['data'][0].id
      });
      // console.log('setupConfirmationSavedCard, setupIntent: ', setupIntent, ' error: ', error);

      this.cardSetupConfirmationDone({ error, setupIntent });

    } catch (err) {
      this.savingStripe = false;
      // console.log('setupConfirmationSavedCard error: ', error);
    }

  }

  public cardSetupConfirmationDone = ({ error, setupIntent }) => {
    if (error) {
      // Display error.message in your UI.
      this.savingStripe = false;
      this.cardErrors = `Sorry we couldn't process your card: ${error.message}`;
    } else {
      // console.log('not a network error', setupIntent);
      if (setupIntent.status === 'succeeded') {
        // The setup has succeeded. Display a success message. Send
        // setupIntent.payment_method to your server to save the card to a Customer
       // this.savingStripe = false;
        this.onBookingSave.emit();
      }
    }
  }

  public async chargeAfterSave() {
    const result = await this.stripeService
      .chargeSavedCard({ clientID: this.client.getID(), amount: this.totalPrice * 100, currency: this.currency }).toPromise();
    // console.log(result);
  }
}
