import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Client } from '../../../models/client.model';
import { Country } from '../../../models/country.model';
import { ClientService } from '../../../services/client.service';
import { UserService } from '../../../services/user.service';
import { CustomValidator } from '../../../utilities/custom-validator';
import { ClientEmailChecker } from '../../../utilities/client-email-checker';
import { Utilities } from '../../../utilities/utilities';
import { DialogsService } from '../dialogs.service';

@Component({
  selector: 'client-sign-up',
  templateUrl: 'client-sign-up.component.html'
})

export class ClientSignUpComponent implements OnInit {

  public isLoading: boolean;

  public submitted: boolean;

  public client: Client;

  public clientForm: FormGroup;

  public firstName: FormControl;

  public lastName: FormControl;

  public email: FormControl;

  public password: FormControl;

  public phone2: FormControl;

  public address1: FormControl;

  public address2: FormControl;

  public city: FormControl;

  public province: FormControl;

  public postal: FormControl;

  public country: FormControl;

  public countries: Array<Country>;

  public reqPass: number;

  public reqAddress: number;

  @Output() signUp = new EventEmitter<Client>();
  @Output() viewLogIn = new EventEmitter();

  constructor(private clientService: ClientService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dialogsService: DialogsService,
    private clientEmailChecker: ClientEmailChecker) {
    this.clientService.setEmailCheckerClientID(0);
    this.submitted = false;
    this.isLoading = false;
    this.reqPass = 0;
    this.reqAddress = 0;
    this.firstName = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(30)]));
    this.lastName = new FormControl('', Validators.maxLength(35));
    this.email = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(100), CustomValidator.validateEmail]), this.clientEmailChecker.clientEmailAvailabilityChecker());
    this.password = new FormControl('', Validators.compose([Validators.maxLength(25)]));
    this.phone2 = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(20), CustomValidator.validatePhone]));

    this.address1 = new FormControl('', Validators.maxLength(40));
    this.address2 = new FormControl('', Validators.maxLength(40));
    this.city = new FormControl('', Validators.maxLength(20));
    this.province = new FormControl('', Validators.maxLength(25));
    this.postal = new FormControl('', Validators.maxLength(25));
    this.country = new FormControl(null);

    const salon = this.userService.getSalon()
    if (salon.getReqPass() === 1) {
      this.password.setValidators([Validators.required, Validators.maxLength(25)]);
      this.reqPass = 1;
    }

    if (salon.getReqAddress() === 1) {
      this.address1.setValidators([Validators.required, Validators.maxLength(40)]);
      this.address2.setValidators([Validators.required, Validators.maxLength(40)]);
      this.city.setValidators([Validators.required, Validators.maxLength(20)]);
      this.province.setValidators([Validators.required, Validators.maxLength(25)]);
      this.postal.setValidators([Validators.required, Validators.maxLength(25)]);
      // this.country.setValidators([Validators.required]);
      this.reqAddress = 1;
    }

    this.clientForm = formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      phone2: this.phone2,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      province: this.province,
      postal: this.postal,
      country: this.country
    });
  }

  public ngOnInit() {

  }

  public ngOnChanges(changes: any) {
  }

  public onViewLogIn() {
    this.viewLogIn.emit();
  }

  public onClientSave() {

    this.submitted = true;
    if (this.clientForm.valid === false) {
      return;
    }

    this.isLoading = true;
    const newClient = new Client({
      id: undefined,
      stylistID: undefined,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      password: this.password.value,
      phone2: this.phone2.value,
      address1: this.address1.value,
      address2: this.address2.value,
      city: this.city.value,
      province: this.province.value,
      postal: this.postal.value,
      // country: this.country.value,
      sendEmail: true
    });

    this.isLoading = true
    this.clientService.createClient(newClient, this.userService.getSalon().getId())
      .subscribe(
        (client: Client) => {
          // this.viewCtrl.dismiss(client);
          client.setPassword(this.password.value);
          this.signUp.emit(client)
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.dialogsService.errorAlert().subscribe();
        }
      );

  }
}
