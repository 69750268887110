import { Injectable }     from '@angular/core';
import { Observable }     from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConfirmDialog } from './confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog.component';
import { ClientLoginComponent } from './client-login/client-login.component';
import { AddNoteComponent } from './add-note/add-note.component';

@Injectable()
export class DialogsService {

  constructor(private dialog: MatDialog) { }

  public alert (title: string, message: string): Observable<boolean> {
    let dialogRef: MatDialogRef<AlertDialogComponent>;
    dialogRef = this.dialog.open(AlertDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  public errorAlert (): Observable<boolean> {
    let dialogRef: MatDialogRef<AlertDialogComponent>;
    dialogRef = this.dialog.open(AlertDialogComponent);
    dialogRef.componentInstance.title = 'Error';
    dialogRef.componentInstance.message = `Oops! An error occured, if this problem persists please contact at support.salonmonster.com`;
    return dialogRef.afterClosed();
  }

  public confirm (title: string, message: string): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialog>;
    dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }
  public addNote (): Observable<string> {
    let dialogRef: MatDialogRef<AddNoteComponent>;
    dialogRef = this.dialog.open(AddNoteComponent);
    return dialogRef.afterClosed();
  }


  public login (allowSignUp: boolean = true, allowFBLogin: boolean = true): Observable <any> {

    let dialogRef: MatDialogRef<ClientLoginComponent>;
    dialogRef = this.dialog.open(ClientLoginComponent, {
      data: {
        allowSignUp: allowSignUp,
        allowFBLogin: allowFBLogin
      },
      height: '95%',
      width: '500px',
      panelClass: 'loginPanel',
    });
    return dialogRef.afterClosed();
  }

}

