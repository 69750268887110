<h2>{{ title }}</h2>
<mat-card sm-restrict-width *ngFor="let service of booking.getServices()">
  <div sm-center>
    <h3 *ngIf="subTitle">{{ subTitle }}</h3>
  </div>
  <mat-list>
    <mat-list-item>
      <img mat-list-avatar *ngIf="service.getStylist() && service.getStylist().getPhotoUrl() != undefined && service.getStylist().getPhotoUrl() != ''" [alt]="service.getStylist().getFirstName() + ' ' + service.getStylist().getLastName()"  src='https://salonmonster.com/userfiles/profile_pics/{{service.getStylist().getPhotoUrl()}}' />
      <h3 mat-line style="font-size: 18px; font-weight: 600;">
        {{ service.getName() }} with <span *ngIf="service.getStylist()"> {{ service.getStylist().getFirstName() }} {{ service.getStylist().getLastName() }}</span>
        <span *ngIf="service.getStylist() == undefined">Any Provider</span>
      </h3>
      <p mat-line>
        {{ service.getServiceDefinition().getStartingAt() == 1 ? 'Starting at' : '' }} 
        {{ service.getServiceDefinition().getPrice() | salonMonsterCurrency }}
        {{ 
          ', about ' + (service.getDurations().startDuration + service.getDurations().processDuration + service.getDurations().finishDuration | SalonMonsterFormatDurationPipe) 
        }}      </p>

      <button mat-icon-button (click)=removeService(service) *ngIf="showDeleteButton == true">
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</mat-card>