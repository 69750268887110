import { Injectable }     from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

import { Booking, RecurringOptions } from '../models/booking.model';
import { UserService } from './user.service';

export enum BookingStatus {
  PreBooing = 1,
  Requested = 2,
  Unconfirmed = 3,
  Arrived = 4,
  NoShow = 5,
  Deleted = 6,
  Declined = 7,
  Paid = 8,
  ConfirmedByPhone = 9,
  ConfirmedByEmail = 10,
  ConfirmedByText = 11,
  ConfirmedByEmailAndText = 12,
  WaitList = 13
}

@Injectable()
export class UserBookingService {

  private booking: Booking;

  private bookingChangeRef = new Subject<Booking>();
  
  private bookingChangeRef$ = this.bookingChangeRef.asObservable();

  constructor (private userService: UserService) {
    this.resetBooking();
  }

  public getBooking (): Booking {
    return this.booking;
  }

  public getBookingChangeRef (): Observable<Booking> {
    return this.bookingChangeRef$;
  }

  public setBooking (booking: Booking) {
    this.booking = booking;
  }

  public resetBooking () {
    this.booking = new Booking({
      id: undefined,
      salonID: undefined,
      startDateTime: undefined,
      client: undefined,
      status: 3,
      services: [],
      recurring: undefined,
      reminderSent: undefined,
      creationDate: new Date(),
      // sendEmail: 3
      sendEmail: 0 // temporary
    });
    this.bookingChangeRef.next(this.booking);

    if (this.userService.getSalon()) {
      this.booking.setSalonID(this.userService.getSalon().getId());
    }
  }

}