import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Salon } from '../../models/salon.model';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'salonmonster-footer',
  templateUrl: 'sm-footer.component.html',
  styleUrls: ['sm-footer.component.scss']
})

export class SalonMonsterFooterComponent implements OnInit {

  @Input() message: boolean;

  public salon: Salon;
  public sameCancellationPolicyForAllStaff: boolean = false;
  public salonCancellationPolicy: string;
  
  constructor (private userService: UserService, private router: Router) {
    this.userService.getSalonInitMonitor().subscribe((init: boolean) => {
      if (init) {
        this.salon = this.userService.getSalon();
        this.sameCancellationPolicyForAllStaff = this.salon.getSameCancellationPolicyForAllStaff() === 1 ? true : false;
        this.salonCancellationPolicy = this.salon.getCancellationPolicy();    
      }
    });
    // userService isn't returning any value
    // console.log(this.salon);
  }

  ngOnInit(): void {
    
  }

}
