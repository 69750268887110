<div class="login-container">
    <form (ngSubmit)="onSaveNote()">
      <h2>Add note</h2>
      <textarea
        matInput
        placeholder="Your note"
        type="text"
        [rows]="5"
        [(ngModel)]="noteText" 
      ></textarea>
      <div class="add-note-button-wrapper">
        <button style="margin-right: 10px;" mat-raised-button type="submit">
          Save
        </button>
        <button mat-raised-button (click)="onClose()">
          Close
        </button>
      </div>
    </form>
  </div>
  