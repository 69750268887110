import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms'

import { Utilities } from '../../shared/utilities/utilities';
import { UserService } from '../../shared/services/user.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'sm-password-reset',
  templateUrl: 'password-reset.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetComponent implements OnInit {

  public password: FormControl;

  public passwordResetForm: FormGroup;

  public submitAttempt: boolean;

  public token: string;

  public isCheckingTokenValidity: boolean;

  public errorOccurredWhileCheckingTokenValidity: boolean;

  public isTokenValid: boolean;

  public showPassword: boolean;

  public isLoading: boolean;

  public anErrorOccurred: boolean;

  constructor (private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dialogsService: DialogsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder) {
    this.password = new FormControl('', Validators.required);
    this.passwordResetForm = this.formBuilder.group({
      password: this.password
    });
    this.showPassword = false;
  }

  public ngOnInit () {
    this.isCheckingTokenValidity = true;
    this.errorOccurredWhileCheckingTokenValidity = false;
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.token = params['token'];
          return this.userService.checkPasswordResetTokenValidity(this.token);
        })
      )
      .subscribe(
        (valid: boolean) => {
          this.isCheckingTokenValidity = false;
          this.isTokenValid = valid;
          this.changeDetectorRef.markForCheck();
        },
        error => {
          this.isCheckingTokenValidity = false;
          this.errorOccurredWhileCheckingTokenValidity = true;
          this.changeDetectorRef.markForCheck();
        }
      );

  }

  // public checkTokenValidity () {
  //   this.isCheckingTokenValidity = true;
  //   this.errorOccurredWhileCheckingTokenValidity = false;
  //   this.userService.checkPasswordResetTokenValidity(this.token)
  //     .subscribe((valid: boolean) => {
  //       this.isCheckingTokenValidity = false;
  //       this.isTokenValid = valid;
  //     },
  //     (err) => {
  //       this.isCheckingTokenValidity = false;
  //       this.errorOccurredWhileCheckingTokenValidity = true;
  //     });

  // }

  public onSave () {
    this.submitAttempt = true;
    
    if (this.passwordResetForm.valid === false) {
      return;
    }

    this.isLoading = true;
    this.anErrorOccurred = false;
    this.userService.passwordReset(this.token, this.password.value)
      .subscribe((review) => {
        this.isLoading = false;
        this.dialogsService.alert("Password reset", "Your password has been successfully changed.")
          .subscribe(() => {
            this.router.navigate(['/']);
          });
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.isLoading = false;
        this.anErrorOccurred = true;
        this.changeDetectorRef.markForCheck();
        this.dialogsService.errorAlert().subscribe();
      });

  }

  public requestPasswordReset () {
    this.router.navigate(['/passwordreset']);
  }


}
