import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BookingsHomeComponent } from './bookings-home/bookings-home.component';
import { StylistServiceListComponent } from './stylist-service-list/stylist-service-list.component';
import { AnyProviderPickerComponent } from './any-provider-picker/any-provider-picker.component';
import { StylistAvailabilityComponent } from './stylist-availability/stylist-availability.component';
import { BookingsSummaryComponent } from './bookings-summary/bookings-summary.component';
import { BookingsConfirmationComponent } from './bookings-confirmation/bookings-confirmation.component';
import { WaitListComponent } from './wait-list/wait-list.component';
import { CalendarComponent } from './shared/components/calendar/calendar.component';
import { CalendarCellComponent } from './shared/components/calendar/calendar-cell.component';

import { SalonMonsterLoadMaskModule } from '../shared/components/sm-load-mask/sm-load-mask.module';
import { SalonMonsterTapRetryModule } from '../shared/components/sm-tap-retry/sm-tap-retry.module';
import { BookingServiceListModule } from '../shared/components/booking-service-list/booking-service-list.module';
import { BookingDetailsModule } from '../shared/components/booking-details/booking-details.module';
import { SharedModule } from '../shared/shared.module';
// import { MaterialModule, MdCardModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import {StripePaymentModule} from '../shared/components/stripe-payment/stripe-payment.module';
import { StylistAnyProviderComponent } from './stylist-any-provider/stylist-any-provider.component';

@NgModule({
  declarations: [
    BookingsHomeComponent,
    StylistServiceListComponent,
    CalendarComponent,
    CalendarCellComponent,
    StylistAvailabilityComponent,
    BookingsSummaryComponent,
    BookingsConfirmationComponent,
    WaitListComponent,
    AnyProviderPickerComponent,
    StylistAnyProviderComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    // MaterialModule,
    // MdCardModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    RouterModule,
    SalonMonsterLoadMaskModule,
    SalonMonsterTapRetryModule,
    FlexLayoutModule,
    BookingDetailsModule,
    StripePaymentModule,
    BookingServiceListModule,
    SharedModule,
    SalonMonsterTapRetryModule
  ],
  exports: [
    BookingsHomeComponent
  ],
  providers: [
  ],
  bootstrap: []
})
export class BookingsModule { }
