import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


export class SalonmonsterHttpClient {

  constructor (protected http: HttpClient) {}

  protected createAuthorizationHeader () {
    let headers = new HttpHeaders();
    if(localStorage.getItem('clientToken')){
      headers = headers.append('Authorization', localStorage.getItem('clientToken')); 
    }
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  protected get(url: string, params?: any): Observable<any> {
    return this.http.get(url, {
      headers: this.createAuthorizationHeader(),
      params: params || {}  
    });
  }

  protected post (url, data) : Observable<any>  {
    return this.http.post(url, data, {
      headers: this.createAuthorizationHeader()
    });
  }

  protected put (url, data) : Observable<any>  {
    return this.http.put(url, data, {
      headers: this.createAuthorizationHeader()
    });
  }

  protected delete (url, data) : Observable<any>  {
    return this.http.delete(url, {
      headers: this.createAuthorizationHeader(),
      // body: data
    });
  }
}