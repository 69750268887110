import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
// import { MaterialModule, MdCardModule, matInputModule, MdIconModule, MdListModule } from '@angular/material';

import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { ClientLoginComponent } from './client-login.component';
import { ClientSignUpComponent } from './client-sign-up.component';
import { SharedModule } from '../../../shared.module';
import { SalonMonsterLoadMaskModule } from '../../../components/sm-load-mask/sm-load-mask.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    ClientLoginComponent,
    ClientSignUpComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // MaterialModule,
    // MdCardModule,
    // matInputModule,
    // MdListModule,
    // MdIconModule,
    MatCardModule,
    MatListModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    FlexLayoutModule,
    SharedModule,
    SalonMonsterLoadMaskModule,
    MatTabsModule
  ],
  exports: [
    ClientLoginComponent
  ],
  entryComponents: [
    ClientLoginComponent
  ],
  providers: []
})
export class ClientLoginModule {}
