import { Injectable }     from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable }     from 'rxjs';

import { ServiceDefinition } from '../models/servicedefinition.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { environment } from '../../../environments/environment';
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class ServiceDefinitionService extends SalonmonsterHttpClient {

  private serviceDefinitions: Array<ServiceDefinition>;

  constructor (http: HttpClient,
    private errorHandlerService: ErrorHandlerService) {
    super(http);
    this.serviceDefinitions = [];
  }

  public getServiceDefinitions (salonID:number, stylistID: number): Observable<Array<ServiceDefinition>> {

    return new Observable<Array<ServiceDefinition>>((observer) => {
      const url = `${environment.API_ROOT}/salons/${salonID}/stylists/${stylistID}/servicedetails`;

      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;

            const data = body.data;
            const serviceDefinitions: Array<ServiceDefinition> = [];

            for (let i = 0; i < data.length; i++) {
              const serviceDefinition = data[i];
              serviceDefinitions.push(ServiceDefinition.parseServiceDefinition(serviceDefinition));
            }

            this.serviceDefinitions = serviceDefinitions;
            return this.serviceDefinitions;
          })
        )
        .subscribe((serviceDefinitions) => {
          observer.next(serviceDefinitions);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });

    });

  }

  public saveServiceDefinition (serviceDefinition: ServiceDefinition) : Observable<ServiceDefinition> {

    return new Observable<ServiceDefinition>((observer) => {

      let url = `${environment.API_ROOT}/servicedetails`;
      
      if (serviceDefinition.getID()) {
        url += '/' +serviceDefinition.getID();
        this.put(url, serviceDefinition.toJSON())
          .pipe(
            map((res: any) => {
              const body = res;
              const data = body.data;
              return ServiceDefinition.parseServiceDefinition(data);
            })
          )
          .subscribe((serviceDefinition) => {
            observer.next(serviceDefinition);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });

      
      } else {
        this.post(url, serviceDefinition.toJSON())
          .pipe(
            map((res: any) => {
              const body = res;
              const data = body.data;
              return ServiceDefinition.parseServiceDefinition(data);
            })
          )
          .subscribe((serviceDefinition) => {
            observer.next(serviceDefinition);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });
      }

    });

  }

}