import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';

import { SalonMonsterLoadMaskComponent } from './sm-load-mask.component';

@NgModule({
  declarations: [
    SalonMonsterLoadMaskComponent
  ],
  imports: [
    MatListModule,
    MatProgressSpinnerModule,
    MatGridListModule
  ],
  exports: [
    SalonMonsterLoadMaskComponent
  ],
  entryComponents: [],
  providers: []
})
export class SalonMonsterLoadMaskModule {}
