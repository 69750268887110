import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import * as Sentry from "@sentry/angular";

import { BookingsModule } from './bookings/bookings.module';
import { BookingsHomeComponent } from './bookings/bookings-home/bookings-home.component';
import { StylistServiceListComponent } from './bookings/stylist-service-list/stylist-service-list.component';
import { AnyProviderPickerComponent } from './bookings/any-provider-picker/any-provider-picker.component';
import { StylistAvailabilityComponent } from './bookings/stylist-availability/stylist-availability.component';
import { BookingsSummaryComponent } from './bookings/bookings-summary/bookings-summary.component';
import { BookingsConfirmationComponent } from './bookings/bookings-confirmation/bookings-confirmation.component';
import { WaitListComponent } from './bookings/wait-list/wait-list.component';
import { PasswordResetRequestComponent } from './password-reset/password-reset-request/password-reset-request.component';
import { PasswordResetComponent } from './password-reset/password-reset/password-reset.component';
import { StylistAnyProviderComponent } from './bookings/stylist-any-provider/stylist-any-provider.component';
import { ClientModule } from './client/client.module';
// import { ExistingLoginComponent } from './client/existing-login/existing-login.component';
import { ClientDetailsComponent }  from './client/client-profile/client-details.component';
import { ClientBookingsComponent }  from './client/client-profile/client-bookings.component';
import { ClientQuickloginComponent }  from './client/client-quicklogin/client-quicklogin.component';
import { ClientCardRequestComponent } from './client/client-cardrequest.component.ts/client-cardrequest.component';
import { ClientChangePasswordComponent } from './client/client-change-password/client-change-password.component';

import { HelpModule } from './help/help.module';
import { HelpPageComponent } from './help/help-page/help-page.component';

import { HomeModule } from './home/home.module';
import { HomePageComponent } from './home/home-page/home-page.component';

import { ContactModule } from './contact/contact.module';
import { ContactPageComponent } from './contact/contact-page/contact-page.component';
import { PasswordResetModule } from './password-reset/password-reset.module';

import { DialogsModule } from './shared/services/dialogs/dialogs.module';
import { UserService } from './shared/services/user.service';
import { SalonService } from './shared/services/salon.service';
import { StylistService } from './shared/services/stylist.service';
import { ClientService } from './shared/services/client.service';
import { LoginService } from './shared/services/login.service';
import { BookingService } from './shared/services/booking.service';
import { NotificationService } from './shared/services/notification.service';
import { CountryService } from './shared/services/country.service';
import { ServiceCategoryService } from './shared/services/service-category.service';
import { ServiceDefinitionService } from './shared/services/service-definition.service';
import { UserBookingService } from './shared/services/user-booking.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { StripeService} from './shared/services/stripe.service';

import { SalonMonsterLoadMaskModule } from './shared/components/sm-load-mask/sm-load-mask.module';

import { SalonMonsterFooterModule } from './shared/components/sm-footer/sm-footer.module';

import { SalonAuthGuard } from './shared/guards/salon-auth.guard';
import { OnlineBookingGuard } from './shared/guards/online-booking.guard';

import { ClientEmailChecker } from './shared/utilities/client-email-checker';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import 'hammerjs';
import { BookingDisabledComponent } from './booking-disabled/booking-disabled.component';

@NgModule({
  declarations: [
    AppComponent,
    BookingDisabledComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    BookingsModule,
    ClientModule,
    PasswordResetModule,
    HelpModule,
    HomeModule,
    ContactModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    // NgbModule.forRoot(),
    DialogsModule,
    FlexLayoutModule,
    SalonMonsterLoadMaskModule,
    SalonMonsterFooterModule,
    RouterModule.forRoot([

      // src: https://stackoverflow.com/questions/43487827/how-to-apply-canactivate-guard-on-all-the-routes
      {
        path: '',
        // redirectTo: '/home',
        // pathMatch: 'full',
        canActivate: [
          SalonAuthGuard,
        ],
        children: [
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full'
          },
          {
            path: 'home',
            component: HomePageComponent,
          },
          {
            path: 'bookings',
            component: BookingsHomeComponent,
            canActivate: [OnlineBookingGuard],
          },
          {
            path: 'stylists/:id/services',
            component: StylistServiceListComponent,
          },
          {
            path: 'stylists/any',
            component: StylistAnyProviderComponent,
          },
          {
            path: 'bookings/provider',
            component: AnyProviderPickerComponent,
            canActivate: [OnlineBookingGuard],
          },
          {
            path: 'stylists/availabilities',
            component: StylistAvailabilityComponent,
          },
          {
            path: 'bookings/summary',
            component: BookingsSummaryComponent,
            canActivate: [OnlineBookingGuard],
          },
          {
            path: 'bookings/confirmation',
            component: BookingsConfirmationComponent,
            canActivate: [OnlineBookingGuard],
          },
          {
            path: 'bookings/waitlist',
            component: WaitListComponent,
            canActivate: [OnlineBookingGuard],
          },
          {
            path: 'client/profile',
            component: ClientDetailsComponent
          },
          {
            path: 'client/bookings',
            component: ClientBookingsComponent,
          },
          {
            path: 'client/change-password',
            component: ClientChangePasswordComponent
          },
          {
            path: 'client/quicklogin/:quickLoginKey',
            component: ClientQuickloginComponent
          },
          {
            path: 'client/cardrequest/:requestHash',
            component: ClientCardRequestComponent,
            canActivate: [OnlineBookingGuard],
          },
          {
            path: 'help',
            component: HelpPageComponent
          },
          {
            path: 'contact',
            component: ContactPageComponent
          },
          {
            path: 'passwordreset/request',
            component: PasswordResetRequestComponent
          },
          {
            path: 'passwordreset/token/:token',
            component: PasswordResetComponent,
          },
          {
            path: 'booking-disabled',
            component:  BookingDisabledComponent,
          }
        ]
      }
    ], {useHash: true,   scrollPositionRestoration: 'enabled'},
    )
  ],
  exports: [],
  providers: [
    UserService,
    SalonService,
    StylistService,
    ServiceDefinitionService,
    ClientService,
    LoginService,
    BookingService,
    StripeService,
    ServiceCategoryService,
    CountryService,
    UserBookingService,
    NotificationService,
    ErrorHandlerService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    ClientEmailChecker,
    SalonAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
 }
