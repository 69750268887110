import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../shared/services/user.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { Booking } from '../../shared/models/booking.model';
import { Client } from '../../shared/models/client.model';
import { Service } from '../../shared/models/service.model';
import { BookingService } from '../../shared/services/booking.service';
import { ClientService } from '../../shared/services/client.service';
import { SalonService } from '../../shared/services/salon.service';
import { NotificationService, NOTIFICATION_TYPE } from '../../shared/services/notification.service';
import { Utilities } from '../../shared/utilities/utilities';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StripeService } from '../../shared/services/stripe.service';

@Component({
  templateUrl: 'bookings-summary.component.html'
})
export class BookingsSummaryComponent implements OnInit {

  public booking: Booking;

  public title: string;

  public subTitle: string;
  public chargeOnNoShow = true;
  public loadStripeComponent = false;
  public clientHasStripeAccount = false;
  public isLoading = false;
  public refreshStripe = 0;
  //todo: pull chargeNoShow from salon service (true if  clientBookingPayments > 0)
  public clientBookingPayments: number;
  public noShowPercentage: number;
  public isAnyBooking: boolean;
  public enableBookButton: boolean;
  constructor(public userService: UserService,
    private bookingService: BookingService,
    private userBookingService: UserBookingService,
    private salonService: SalonService,
    private router: Router,
    private dialogsService: DialogsService,
    private clientService: ClientService,
    private stripeService: StripeService,
    private notificationService: NotificationService) {
    this.booking = this.userBookingService.getBooking();
    this.title = 'Your booking so far:';
    this.subTitle = Utilities.formatDate(this.booking.getStartDateTime(), 'h:mm a, dddd, MMMM D, Y');
    this.isAnyBooking = this.booking.getServices().some((service) => service.getServiceDefinition().getCommonStylists()?.size > 0);
    console.log("anybooking", this.isAnyBooking);
    this.enableBookButton = true;
  }


  public async ngOnInit() {
    if (((this.booking.getStatus() === 2 || this.booking.getStatus() === 3) && this.booking.getServices().length === 0)) {
      await this.router.navigate(['/']);
    }

    this.clientBookingPayments = this.userService.getSalon().getClientBookingPayments();

  }


  public onPhotoUpdate(photos: Array<string>) {
    this.booking.setPhotos(photos);
  }

  public onBookingSave() {
    this.enableBookButton = false;
    if (this.userService.isLoggedIn()) {
      this.save(this.userService.getUser());
    } else {
      let allowSignUp = true;
      let allowFBLogin = true;
      let clientBookingPayments = this.clientBookingPayments;

      // if one of the stylists in this booking has getLetClientsRegister turned off,
      // disable client sign up
      for (let service of this.booking.getServices()) {
        if (service.getServiceDefinition()) {
          const stylist = service.getStylist();

          if (stylist && stylist.getLetClientsRegister() === 0) {
            allowSignUp = false;
            // break;
          }
          if (stylist && stylist.getLetClientsUseFBLogin() === 0) {
            allowFBLogin = false;
            // break;
          }
        }
      }

      this.dialogsService.login(allowSignUp, allowFBLogin).subscribe((client: Client) => {
        // console.log('dialog closed - triggering change');

        if (client) {
          if (this.clientBookingPayments > 0) {
            // salon requires payment on booking so we will return to the page and collect payment info:
            // this.checkUserStripeAccount();
            this.refreshStripe++;
            this.enableBookButton = true;
            // console.log("client info after login: ", client);
          } else {
            this.save(client);
          }
        }
        else {
          this.enableBookButton = true;
        }
      });
    }
  }

  private save(client: Client) {
    this.isLoading = true;
    let notificationType: NOTIFICATION_TYPE = NOTIFICATION_TYPE.NEW_BOOKING;
    this.booking.setClient(client);
    this.clientService.getClientInfo()
      .subscribe((client: Client) => {
        this.userService.setUser(client);
        if (this.userService.getUser().isBlockedOnline()) {
          this.dialogsService.alert('  ', 'This account can not book online. Please contact the salon by phone.');
          this.enableBookButton = true;
          return;
        }

        // default (Unconfirmed status)
        let status = 3;

        // if one of the stylists in this booking has confirmAppointmentsManually turned on,
        // set the booking status to requested
        for (let service of this.booking.getServices()) {
          if (service.getServiceDefinition()) {
            const stylist = service.getStylist();
            if (stylist && stylist.getConfirmApptsManually() === 1) {

              // requested
              status = 2;
              notificationType = NOTIFICATION_TYPE.NEW_BOOKING_REQUEST;
            }
          }


        }

        if (this.booking.getId()) {
          notificationType = NOTIFICATION_TYPE.BOOKING_EDITED;
        }

        this.checkBookingOverlaps()
          .pipe(
            switchMap((overlaps: Array<Service>) => {

              if (overlaps.length > 0) {
                this.dialogsService.alert('Booking Overlap', "Sorry but this timeslot is already taken. Please pick a new timeslot for your booking.").subscribe();
                this.router.navigate(['stylists/availabilities']);
                return;
              }

              this.booking.setStatus(status);
              return this.bookingService.saveBooking(this.booking);
            })
          )
          .subscribe(
            (booking: Booking) => {

              if (!booking) {
                this.enableBookButton = true;
                this.isLoading = false;
                return;
              }

              this.notificationService.send(booking, notificationType)
                .subscribe(() => {
                  this.isLoading = false;                  
                  this.router.navigate(['bookings/confirmation']);
                });
            },
            (error) => {
              this.isLoading = false;
              this.enableBookButton = true;
              throw error;
              // this.dialogsService.errorAlert().subscribe();
            });

      },
        (error) => {
          console.log("error on save:", error);
          if (error.error.err_msg === "Client not found") {
            console.log("err msg client not found");
            this.enableBookButton = true;
            // user not found, log out and instead of saving make them log in first
            this.userService.logout();
            this.onBookingSave();
          } else {
            throw error;
          }
          // this.dialogsService.errorAlert().subscribe();
        })
  }

  private checkBookingOverlaps(): Observable<Array<Service>> {

    return new Observable<Array<Service>>((observer) => {
      let overlaps: Array<Service> = [];
      let counter: number = 0;
      let successCount: number = 0;

      for (let service of this.booking.getServices()) {

        this.bookingService.bookingOverlapChecker(this.booking, service)
          .subscribe((result) => {
            successCount++;
            overlaps.push(...result);

            if (successCount === this.booking.getServices().length) {
              observer.next(overlaps);
              observer.complete();
            }

          },
            (err) => {
              observer.error(err);
              observer.complete();
            });
      }

    });

  }
}
