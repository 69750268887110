import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';

import { flatMap, filter, map, switchMap } from 'rxjs/operators';

import { Client } from '../../shared/models/client.model';
import { Salon } from '../../shared/models/salon.model';
import { Utilities } from '../../shared/utilities/utilities';
import { ClientService } from '../../shared/services/client.service';
import { SalonService } from '../../shared/services/salon.service';
import { UserService } from '../../shared/services/user.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';

@Component({
  selector: 'sm-client-cardrequest',
  templateUrl: 'client-cardrequest.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientCardRequestComponent implements OnInit {

  public isLoading: boolean;

  public errorOccurred: boolean;

  public requestHash: string;

  public client: Client;
  public salon: Salon;
  public clientBookingPayments: number = 1;
  public refreshStripe: number = 0;

  constructor(private clientService: ClientService,
    private salonService: SalonService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogsService: DialogsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {
    this.isLoading = true;
  }

  public ngOnInit() {
    this.clientBookingPayments = 3;
    this.fetchCardRequest();
  }

  public fetchCardRequest() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.requestHash = params['requestHash'];
          return this.clientService.getClientCardRequest(this.requestHash);
        })
      ).subscribe(response => {
        // this.client = this.clientService.getClientInfoByID(response.);
        this.client = response;

        // once we fetchCardRequest then we know the salonID and we can check if clientBookingPayments is enabled
        this.salonService.loadSalonByID(this.client.salonID).subscribe((salon: Salon) => {
          this.salon = salon;
          // console.log("salonBookingPayments", this.salon.getClientBookingPayments());
          // if(this.salon.getClientBookingPayments() == 1){
          this.clientBookingPayments = 1;
          // }
          // console.log("this.clientBookingPayments", this.clientBookingPayments);
          this.refreshStripe++;
        });
      }, (err) => {
        console.log("error: ", err);
        this.isLoading = false;
        this.errorOccurred = true;
        this.router.navigate(['/']);
      });
  }

  public onSave() {
  }

}
