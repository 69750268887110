import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { Observable }     from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientService } from '../services/client.service';

export interface ValidationResult {
  [key: string]: {[value: string]: boolean};
}

// class MyCookieXSRFStrategy extends CookieXSRFStrategy {}

@Injectable()
export class ClientEmailChecker {

  constructor (private clientService: ClientService) {}


  public clientEmailAvailabilityChecker (): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      const clientService = this.clientService;
        
      const email = control.value;

      return clientService.emailChecker(email)
        .pipe(
          map((available: any) =>
            available ? null : {clientEmailAlreadyExists: true}
          )
        )
    }
  }
}