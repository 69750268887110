import { Cloneable } from './cloneable';


export  class Country implements Cloneable <Country> {

  private id: number;

  private countryName: string;

  constructor(id?: number, countryName?: string) {
    this.id = id;
    this.countryName = countryName;
  }

  public clone () : Country {
    return new Country(this.getID(), this.getCountryName());
  }

  public getID () : number {
    return this.id;
  }

  public setID (newID: number) : void {
    this.id = newID;
  }

  public getCountryName(): string {
    return this.countryName;
  }

  public setCountryName(countryName: string): void {
    this.countryName = countryName;
  }

}