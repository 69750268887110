import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ErrorHandlerService } from '../services/error-handler.service';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

export interface IAuth {
  salon: Object;
  token: string;
  user: Object;
}

@Injectable()
export class LoginService {
  constructor(private http: HttpClient,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService) { }

  public loginUser(username: string, password: string): Observable<IAuth> {
    const apiURL = environment.API_ROOT + '/auth/clients';
    const body = {
      username: username,
      password: password,
      salonID: this.userService.getSalon().getId()
    }

    return new Observable<IAuth>((observer) => {
      this.http.post(apiURL, body)
        .pipe(
          map((res: any) => {
            return res;
          })
        )
        .subscribe(
          (response) => {
            const data = {
              salon: response.data.salon,
              token: response.data.token,
              user: response.data.user
            };

            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public facebookLogin(fbID: string, firstName: string, lastName: string, email: string): Observable<IAuth> {
    const apiURL = environment.API_ROOT + '/auth/clients/facebook';
    const body = {
      fbID: fbID,
      firstName: firstName,
      lastName: lastName,
      email: email,
      salonID: this.userService.getSalon().getId(),
      iOSID: undefined,
      androidID: undefined
    }

    return new Observable<IAuth>((observer) => {
      this.http.post(apiURL, body)
        .pipe(
          map((res: any) => {
            return res;
          })
        )
        .subscribe(
          (response) => {
            const data = {
              salon: response.data.salon,
              token: response.data.token,
              user: response.data.user
            };

            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

}