<div sm-restrict-width sm-center [hidden]="isLoading">
  <br />
  <mat-card *ngIf="resetSent">
    <mat-card-title sm-center>
      <mat-icon class="smc-icon-large">email</mat-icon>
      <h4>Please check your email</h4>
    </mat-card-title>

    <mat-card-content sm-center>
      <p>
        If a matching account was found an email was sent to {{ email.value }} to allow you to reset your password.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!resetSent">

    <mat-card-title>
      Reset your password
    </mat-card-title>
    Forgot your password? No worries, we'll email you a link to reset your password.
    <form [formGroup]="passwordResetForm">
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Enter your email</mat-label>
        <input matInput type='text' formControlName="email" />
      </mat-form-field>

      <div *ngIf="!email.valid && submitAttempt">
        <p *ngIf="email.hasError('required')" class='smr-error'>
          Oops, please enter an email.
        </p>
        <p *ngIf="!email.hasError('required') && email.hasError('email')" class='smr-error'>
          Oops, your email is invalid.
        </p>
      </div>
    </form>

  <div sm-center *ngIf="!resetSent">
    <button
      mat-raised-button
      class="smc-button-large smc-button-primary"
      (click)="onSave()"
      >
      Send reset email
    </button>
  </div>
</mat-card>
<br />
</div>

<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>