import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { Booking } from '../../models/booking.model';
import { Service } from '../../models/service.model';
import { Utilities } from '../../utilities/utilities';

@Component({
  selector: 'sm-booking-service-list',
  templateUrl: 'booking-service-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['booking-service-list.component.scss']
})

export class BookingServiceListComponent {

  @Input() booking: Booking;

  @Input() title: string;

  @Input() subTitle: string;

  @Input() showDeleteButton: boolean;

  @Output() serviceDelete = new EventEmitter<Service>();

  constructor () {}

  public removeService (service: Service) {
    this.serviceDelete.emit(service);
  }

}
