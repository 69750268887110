import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Salon } from '../models/salon.model';
import { Booking } from '../models/booking.model';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { environment } from '../../../environments/environment';
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class SalonService extends SalonmonsterHttpClient {

  public salonLoaded: boolean;

  public subDomain: string = 'salontastic';

  constructor(public http: HttpClient,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService) {
    super(http);

    this.subDomain = this.getSubDomainFromURL();

    if (!this.subDomain || this.subDomain === 'salonmonster.com' || this.subDomain.indexOf('localhost') !== -1) {
      // this.subDomain = 'clover';
      this.subDomain = 'Salontastic';
    } else {
      this.subDomain = this.subDomain.substr(0, this.subDomain.indexOf('.salonmonster.com'));
      this.subDomain = this.subDomain.replace("www.", "");
    }
  }

  public loadSalonBySubDomain(): Observable<boolean> {
    const url = `${environment.API_ROOT}/salons/search/${this.subDomain}`;
    return new Observable<boolean>((observer) => {
      this.get(url)
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            let salon: Salon;

            if (data.length === 1) {
              salon = Salon.parseSalon(data[0]);
              this.userService.setSalon(salon);
              this.salonLoaded = true;
              return true;
            } else {
              this.salonLoaded = false;
              return false;
            }
          })
        )
        .subscribe((loaded: boolean) => {
          observer.next(loaded);
          observer.complete();
        },
          (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          });
    });
  }


  public loadSalonByID(id: number): Observable<Salon> {
    const url = `${environment.API_ROOT}/salons/getbyid/${id}`;
    return new Observable<Salon>((observer) => {
      this.get(url)
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            let salon: Salon;

            if (data.length === 1) {
              salon = Salon.parseSalon(data[0]);
              this.userService.setSalon(salon);
              this.salonLoaded = true;
              return salon;
            } else {
              this.salonLoaded = false;
              return;
            }
          })
        )
        .subscribe((salon: Salon | undefined) => {
          observer.next(salon);
          observer.complete();
        },
          (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          });
    });
  }

  public getStylistsAvailabilities(stylistIds: Array<Number>, year: string, month: string): Observable<any> {
    const url = `${environment.API_ROOT}/salons/stylists/availabilities`;

    // Prepare URL parameters
    const params = new HttpParams()
      .set('salonID', this.userService.getSalon().getId())
      .set('year', year)
      .set('month', month)
      .set('selectedStylistIDs', stylistIds.join(',')); // Convert array to comma-separated string

    return new Observable<any>((observer) => {
      this.get(url, params)
        .pipe(
          map((res) => {
            if (res.success) {
              return res.data;
            }
            return;
          })
        ).subscribe((availabilities) => {
          const timeSlotMapping = {};
          for (const stylistID in availabilities) {
            const stylistData = availabilities[stylistID];          
            for (let day = 1; day <= 31; day++) {
              const dayData = stylistData[day];
              
              timeSlotMapping[day] = timeSlotMapping[day] || {};
              if (dayData) {
                for (const timeSlot in dayData) {
                  if (dayData[timeSlot] === true) {
                    timeSlotMapping[day][timeSlot] = timeSlotMapping[day][timeSlot] || [];
                    timeSlotMapping[day][timeSlot].push(Number(stylistID));
                  }
                }
              }
            }
          }
          observer.next(timeSlotMapping);
          observer.complete();
        },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });
    });
  }
  public getAvailabilities(booking: Booking, year: string, month: string): Observable<any> {
    const url = `${environment.API_ROOT}/salons/${this.userService.getSalon().getId()}/availabilities`;
    const payload = {
      year: year,
      month: month,
      services: []
    };

    for (let service of booking.getServices()) {

      payload['services'].push({
        stylistID: service.getServiceDefinition()?.getStylist()?.getID(),
        startDuration: service.getDurations().startDuration,
        processDuration: service.getDurations().processDuration,
        finishDuration: service.getDurations().finishDuration,
      });

    }

    return new Observable<any>((observer) => {
      this.post(url, payload)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            return data;
          })
        )
        .subscribe((availabilities) => {
          observer.next(availabilities);
          observer.complete();
        },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });

    });
  }

  public contactSalon(message: string, clientEmail: string): Observable<boolean> {
    const url = `${environment.API_ROOT}/salons/contact`;
    const payload = {
      clientEmail: clientEmail,
      salonID: this.userService.getSalon().getId(),
      message: message
    };

    return new Observable<boolean>((observer) => {
      this.post(url, payload)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            return data;
          })
        )
        .subscribe((success: boolean) => {
          observer.next(success);
          observer.complete();
        },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          })
    });
  }

  private getSubDomainFromURL(): string {
    const url: string = window.location.href;
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    const domain: string = matches && matches[1];  // domain will be null if no match is found
    return domain;
  }

}