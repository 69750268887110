import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BookingItemComponent } from './booking-item.component';
import {SharedModule} from '../../shared.module';
// import { MaterialModule, MdCardModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    BookingItemComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    // MaterialModule,
    // MdCardModule
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule

  ],
  exports: [
    BookingItemComponent
  ],
  entryComponents: [],
  providers: []
})
export class BookingItemModule {}
