<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

<salonmonster-tap-retry
  *ngIf="errorOccurred"
  (click)="loadPage()"
></salonmonster-tap-retry>

<mat-list *ngIf="!isLoading && !errorOccurred">
  <h3 mat-subheader>Upcoming Bookings</h3>
  <span
    class="sm-booking-empty"
    mat-list-item
    *ngIf="upcomingBookings.count() == 0"
    >You have no upcoming bookings<br /><br
  /></span>
  <sm-booking-item
    mat-list-item
    [booking]="booking"
    [showButtons]="true"
    [cancellationInProgress]="cancellationInProgress"
    (bookingDateChange)="bookingDateChange($event)"
    (bookingCancellation)="bookingCancellation($event, bookingRowIndex)"
    *ngFor="let booking of upcomingBookings; let bookingRowIndex = index"
  >
  </sm-booking-item>

  <h3 mat-subheader>Previous Bookings</h3>
  <span
    class="sm-booking-empty"
    mat-list-item
    *ngIf="previousBookings.count() == 0"
    >You have no previous bookings</span
  >
  <sm-booking-item
    mat-list-item
    [booking]="booking"
    *ngFor="let booking of previousBookings"
  >
  </sm-booking-item>
</mat-list>
