import { Component, Input } from '@angular/core';

@Component({
  selector: 'salonmonster-load-mask',
  templateUrl: './sm-load-mask.component.html'
})

export class SalonMonsterLoadMaskComponent {

  @Input() message: boolean;
  
  constructor () {}
}
