import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router} from '@angular/router';
import { Location } from '@angular/common';

import { Booking } from '../../shared/models/booking.model';
import { Service } from '../../shared/models/service.model';
import { Stylist } from '../../shared/models/stylist.model';
import { ServiceDefinition } from '../../shared/models/servicedefinition.model';
import { ServiceCategory } from '../../shared/models/servicecategory.model';
import { UserService } from '../../shared/services/user.service';
import { StylistService } from '../../shared/services/stylist.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';

import { mergeMap, filter } from 'rxjs';

interface IStylistOption {
  stylist: Stylist, serviceDefinition: ServiceDefinition
}

@Component({
  templateUrl: 'any-provider-picker.component.html'
})
export class AnyProviderPickerComponent implements OnInit {

  public booking: Booking;

  public servicesWithAnyProvider: Array<Service>;

  public stylistsWhoOfferAllServices: Array<Stylist>;

  public options: Array<Array<IStylistOption>>;

  public selectedOptions: Array<IStylistOption>;

  public combineServices: boolean;

  public serviceIndex: number;
  
  constructor (private userService: UserService,
              private userBookingService: UserBookingService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private stylistService: StylistService,
              private dialogsService: DialogsService) {
    this.booking = this.userBookingService.getBooking();
    this.servicesWithAnyProvider = this.booking.getServicesWithAnyProvider();
    this.stylistsWhoOfferAllServices = [];
    this.options = [];
    this.selectedOptions = [];
    for (let service of this.servicesWithAnyProvider) {
      this.options.push([]);
    }

    this.combineServices = true;
    this.serviceIndex = 0;
  }

  public ngOnInit () {
    let forbidden = false;
    
    if (this.userService.isLoggedIn() === false) {
      forbidden = true;
    }

    if (this.servicesWithAnyProvider.length === 0) {
      forbidden = true;
    }

    if (forbidden) {
      this.router.navigate(['home']);
      return;
    }

    // find stylists that offer all the services
    this.stylistService.getStylists(this.userService.getSalon().getId())
      .pipe(
        mergeMap((stylists: Array<Stylist>) => stylists),
        filter((stylist: Stylist) => {
          let match = 0;
          let index = 0;
          for (let service of this.servicesWithAnyProvider) {
            const stylistServiceDefinition = this.doesStylistOffer(stylist, service.getServiceDefinition());
            if (stylistServiceDefinition) {
              this.options[index].push({stylist: stylist, serviceDefinition: stylistServiceDefinition});
              match ++;
            }
            index++;
          }
          return match === this.servicesWithAnyProvider.length;
        })
      )
      .subscribe(
        (stylist: Stylist) => {
          this.stylistsWhoOfferAllServices.push(stylist);
        },
        (error) => {
          throw error;
          // this.dialogsService.errorAlert().subscribe();
        },
        () => {
          if (this.stylistsWhoOfferAllServices.length === 0) {
            this.combineServices = false;

          } else {
            this.combineServices = true;
          }
        }
      );

  }

  public doesStylistOffer (stylist: Stylist, serviceDefinition: ServiceDefinition): ServiceDefinition {
    for (let serviceCategory of stylist.getServiceCategories()) {
      for (let stylistServiceDefinition of serviceCategory.getServiceDefinitions()) {
        if (stylistServiceDefinition.isClientBookable() && serviceDefinition.getParentID() === stylistServiceDefinition.getParentID()) {
          return stylistServiceDefinition;
        }
      }
    }

    return undefined;
  }

  public selectStylistForCombinedServices (stylist: Stylist) {
    const serviceProviders: Array<IStylistOption> = [];

    for (let option of this.options) {
      for (let stylistOpt of option) {
        if (stylistOpt.stylist.getID() === stylist.getID()) {
          serviceProviders.push(stylistOpt);
          break;
        }
      }
    }

    // attach the service and redirect to the calendar
    this.replaceAnyServicesWithRealOnes(serviceProviders);
    this.router.navigate(['stylists/availabilities']);
    return;
  }

  public selectStylistForIndividualService (option: IStylistOption) {

    // save the serviceDefinition that was picked
    this.selectedOptions.push(option);

    if ((this.serviceIndex + 1)  === this.servicesWithAnyProvider.length) {
      
      // attach the service and redirect to the calendar
      this.replaceAnyServicesWithRealOnes(this.selectedOptions);
      this.router.navigate(['stylists/availabilities']);
      return;
    }

    this.serviceIndex++;
  }

  private replaceAnyServicesWithRealOnes (serviceProviders: Array<IStylistOption>) {
    let sIndex = 0;
    let optionIndex = 0;
    for (let service of this.booking.getServices()) {
      if (service.hasAnyProvider()) {
        const currentOption: IStylistOption = serviceProviders[optionIndex];
        const service: Service = new Service({
          serviceDefinition: currentOption.serviceDefinition,
          clientNotes: '',
          stylistNotes: '',
          price: currentOption.serviceDefinition.getPrice(),
          startDateTime: undefined,
          durations: {
            startDuration: currentOption.serviceDefinition.getDuration(),
            processDuration: currentOption.serviceDefinition.getProcessDuration(),
            finishDuration: currentOption.serviceDefinition.getFinishDuration()
          },
          lineItemID: undefined,
          clientID:  undefined,
          client: undefined,
          type: 'service',
          name: currentOption.serviceDefinition.getServiceName(),
          quantity: 1,
          isRefund: 0,
          taxRateType: 0,
          stylist: currentOption.stylist.clone()
        });

        this.booking.insertService(sIndex, service);
        optionIndex++;
      }

      sIndex++;
    }
  }

}
