import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { List, Record } from 'immutable';

const Cell = Record({
  date: Date,
  disabled: null,
  selected: null,
  hidden: null
});

export class CalendarCell extends Cell {
  date: Date;
  disabled: boolean;
  selected: boolean;
  hidden: boolean;
}

export interface ICalendarWeek extends List<CalendarCell> { };

export interface IDateRange {
  start: Date;
  end: Date;
}

@Component({
  selector: 'calendar',
  templateUrl: 'calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['calendar.scss']
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() currentDate: Date;
  @Input() firstAvailableDate: Date;

  @Input() disabledDates: List<number>;

  @Output() dateSelected = new EventEmitter<Date>();

  @Output() rangeChanged = new EventEmitter<IDateRange>();

  public weeks: List<ICalendarWeek>;

  public monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November",
    "December"
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.weeks = List.of<ICalendarWeek>();
  }

  public ngOnInit() {
    this.currentDate = this.currentDate || new Date();
    this.disabledDates = this.disabledDates || List.of<number>();
    this.refresh(this.currentDate);
  }

  public ngOnChanges(changes) {
    const eventSourceChange = changes['disabledDates'];
    if (eventSourceChange && eventSourceChange.currentValue) {
      this.refreshMonthAvailability();
    }
    if (changes['firstAvailableDate'] !== undefined && this.firstAvailableDate !== undefined) {
      const preSelectedCell = new CalendarCell(
        {
          date: this.firstAvailableDate,
          selected: true,
          hidden: false,
          disabled: false,

        });
      this.cellSelect(preSelectedCell);
    }

  }

  public refresh(d: Date) {
    const date = new Date(d.getTime());
    const year: number = date.getFullYear();
    const month: number = date.getMonth();
    const day: number = date.getDate();
    let currentMonth = this.getMonthDates(month, year);
    const startDate = currentMonth.get(0);
    const endDate = currentMonth.last();

    // find the paddings
    const previousMonth = this.getMonthDates(month - 1, year);
    const nextMonth = this.getMonthDates(month + 1, year);
    const leftPadding = previousMonth.slice(previousMonth.count() - currentMonth.get(0).getDay());
    const rightPadding = nextMonth.slice(0, 6 - currentMonth.get(currentMonth.count() - 1).getDay());

    // combine the padding with the currentmonth
    currentMonth = currentMonth.unshift(...leftPadding.toJS());
    currentMonth = currentMonth.push(...rightPadding.toJS());

    let weeks: List<ICalendarWeek> = List.of<ICalendarWeek>();
    let rowCounter = 0;
    let week: ICalendarWeek = List.of<CalendarCell>();
    currentMonth.forEach((value: Date) => {
      const cell = new CalendarCell({
        date: value,
        disabled: false,
        selected: false,
        hidden: value.getMonth() !== date.getMonth()
      });

      week = week.push(cell);
      rowCounter++;

      if (rowCounter % 7 === 0) {
        weeks = weeks.push(week);
        week = List.of<CalendarCell>();
      }
    });

    this.currentDate = date;
    this.weeks = weeks;
    this.rangeChanged.emit({
      start: startDate,
      end: endDate
    });
  }

  public getMonthDates(month: number, year: number): List<Date> {
    const date = new Date(year, month, 1, 12, 0, 0, 0);
    const daysInMonth = this.daysInMonth(month, year);
    let dates: List<Date> = List.of<Date>();

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(date.getTime());
      currentDate.setDate(i);
      dates = dates.push(currentDate);
    }

    return dates;
  }

  public daysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  public previous() {
    const date = new Date(this.currentDate.getTime());
    date.setMonth(date.getMonth() - 1);
    this.refresh(date);
  }

  public next() {
    const date = new Date(this.currentDate.getTime());
    const newMonth = date.getMonth() + 1;
    date.setMonth(newMonth, 1);
    this.refresh(date);
  }

  public cellSelect(cell: CalendarCell) {
    if (cell.get('disabled') === false && cell.get('hidden') === false) {
      this.dateSelected.emit(cell.get('date'));
      let selectedDate = cell.get('date');

      // Update calendar with selected date and clear others
      this.weeks = this.weeks.map((week?: ICalendarWeek) => {
        if (week === undefined) {
          return List<CalendarCell>();
        }
        return week.map((cell2?: CalendarCell) => {
          if (cell2 === undefined) {
            return CalendarCell;
          }
          let cellDate = cell2.get('date');
          // Compare full date
          if (cellDate.getDate() === selectedDate.getDate() &&
            cellDate.getMonth() === selectedDate.getMonth() &&
            cellDate.getFullYear() === selectedDate.getFullYear()) {
            return cell2.set('selected', true);
          } else {
            return cell2.set('selected', false);
          }
        }) as ICalendarWeek;
      }) as List<ICalendarWeek>;
    }
  }

  public refreshMonthAvailability() {
    let row = 0;
    this.weeks.forEach((week: ICalendarWeek) => {
      let col = 0;
      week.forEach((cell: CalendarCell) => {
        const date: Date = cell.get('date');
        const hidden: boolean = cell.get('hidden');
        if (!hidden) {
          if (this.disabledDates.contains(date.getDate())) {
            this.weeks = this.weeks.setIn([row, col, 'disabled'], true);
          }
        }
        col++;
      });
      row++;
    });

    // this.changeDetectorRef.markForCheck();
  }

}
