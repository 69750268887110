import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class OnlineBookingGuard implements CanActivate {
  constructor(
    private router: Router, 
    private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const enableOnlineBooking = this.userService.IsBookingActivated();
    if (enableOnlineBooking) {
      return true;
    } else {
      return this.router.createUrlTree(['/booking-disabled']);
    }
  }
}