import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Booking } from '../../shared/models/booking.model';
import { Client } from '../../shared/models/client.model';
import { Utilities } from '../../shared/utilities/utilities';
import { ClientService } from '../../shared/services/client.service';
import { BookingService } from '../../shared/services/booking.service';
import { UserService } from '../../shared/services/user.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { NotificationService, NOTIFICATION_TYPE } from '../../shared/services/notification.service';
import { List } from 'immutable';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';

@Component({
  selector: 'sm-client-bookings',
  templateUrl: 'client-bookings.component.html',
  styleUrls: ['client-bookings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ClientBookingsComponent implements OnInit {

  // @Input() client: Client;

  public client: Client;
 
  public upcomingBookings: List<Booking>;

  public previousBookings: List<Booking>;

  public isLoading: boolean;

  public errorOccurred: boolean;
  cancellationInProgress: number = 0;

  constructor (private clientService: ClientService,
    private changeDetectorRef: ChangeDetectorRef,
    private bookingService: BookingService,
    private dialogsService: DialogsService,
    private userService: UserService,
    private userBookingService: UserBookingService,
    private notificationService: NotificationService,
    private router: Router) {
    this.client = this.userService.getUser();
    this.upcomingBookings = List.of<Booking>();
    this.previousBookings = List.of<Booking>();
    this.isLoading = true;
  }

  public ngOnInit () {

    if (!this.userService.isLoggedIn()) {
      this.router.navigate(['/']);
      return;
    }

    this.loadPage();
  }

  public loadPage () {
    this.isLoading = true;
    this.errorOccurred = false;
    this.clientService.getClientBookings().subscribe((bookings: Array<Booking>) => {
        for (let booking of bookings) {
          if (this.isUpcomingAppointment(booking.getStartDateTime())) {
            this.upcomingBookings = this.upcomingBookings.push(booking);
          } else {
            this.previousBookings = this.previousBookings.push(booking);
          }
        }  
        this.isLoading = false;
        this.errorOccurred = false;
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.isLoading = false;
        this.errorOccurred = true;
        this.changeDetectorRef.markForCheck();
        this.dialogsService.errorAlert().subscribe();
      });    
  }

  public isUpcomingAppointment (date: Date): boolean {
    const today = new Date();
    return (Utilities.compare(date, today) !== -1);
  }

  public bookingDateChange (booking: Booking) {

    // TODO: read stylists settings if this action is allowed (allow date change before T)
    if (this.isUpcomingAppointment(booking.getStartDateTime())) {
      // change status to 3 (booked)
      booking.setStatus(3);
      // change remindeSentvalue to 0
      booking.setReminderSent(0);
      this.userBookingService.setBooking(booking);
      this.router.navigate(['stylists/availabilities']);
    }
  }

  public bookingCancellation (booking: Booking, bookingRowIndex: number) {
    this.cancellationInProgress = booking.getId();

    // TODO: read stylists settings if this action is allowed (allow date change before T)
    if (this.isUpcomingAppointment(booking.getStartDateTime())) {
      this.dialogsService.confirm("Booking Cancellation", "Are you sure you want to cancel this booking?")
        .subscribe(
            (confirm) => {
              
              if (confirm) {

                // send both client and stylist emails
                booking.setSendEmail(3);
                this.bookingService.deleteBooking(booking)
                  .subscribe(() => {
                    this.notificationService.send(booking, NOTIFICATION_TYPE.BOOKING_CANCELLED)
                      .subscribe(() => {
                        this.upcomingBookings = this.upcomingBookings.remove(bookingRowIndex);
                        this.cancellationInProgress = 0;
                        this.changeDetectorRef.markForCheck();
                      },
                      (error) => {
                        this.cancellationInProgress = 0;
                        throw error;                        
                        // this.dialogsService.errorAlert().subscribe();
                      });
                  },
                  (error) => {
                    this.cancellationInProgress = 0;
                    throw error;
                    // this.dialogsService.errorAlert().subscribe();
                  });
              } else {
                this.cancellationInProgress = 0;
                this.changeDetectorRef.markForCheck();
              }
            }
          );
    }
  }
}
