import { Cloneable } from './cloneable';


export  class Pronoun implements Cloneable <Pronoun> {

  private name: string;
  private value: number;


  constructor(name?: string, value?: number) {
    this.name = name;
    this.value = value;
  }

  public clone () : Pronoun {
    return new Pronoun(this.getPronounName(),this.getValue());
  }

  public getValue () : number {
    return this.value;
  }

  public setValue (value: number) : void {
    this.value = value;
  }

  public getPronounName(): string {
    return this.name;
  }

  public setPronounName(name: string): void {
    this.name = name;
  }

}