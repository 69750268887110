
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable }     from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Booking, RecurringOptions } from '../models/booking.model';
import { Service, TimeSlotDurationOptions } from '../models/service.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { UserService } from '../services/user.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { environment } from '../../../environments/environment';
import { Utilities } from '../utilities/utilities';
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class BookingService extends SalonmonsterHttpClient {

  private bookings: Array<Booking>;

  private bookingRequests: Array<Booking>;

  constructor (http: HttpClient,
              private userService: UserService,
              private errorHandlerService: ErrorHandlerService) {
    super(http);
    this.bookings = [];
  }

  public loadBookingByID (bookingID: number): Observable<Booking> {
    return new Observable<Booking>((observer) => {
      const url = `${environment.API_ROOT}/bookings/${bookingID}`;
      return this.get(url)
          .pipe(
            map((res: any) => {
              let body = res;
              const data = body.data;

              if (data && data.length === 1) {
                return Booking.parseBooking(data[0]);
              } else {
                observableThrowError('Booking not found');
              }

            })
          )
          .subscribe((booking: Booking) => {
            observer.next(booking);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });
    });
  }

  public loadBookings (startDate: Date, endDate: Date, stylistID?: number): Observable<Array<Booking>> {
    const start = Utilities.formatDate(startDate, 'Y-MM-DD');
    const end = Utilities.formatDate(endDate, 'Y-MM-DD');
    const sid = (stylistID !== undefined) ? stylistID : this.userService.getUser().getID();
    const url = `${environment.API_ROOT}/stylists/${sid}/clients/bookings?start=${start}&end=${end}`;

    return new Observable<Array<Booking>>((observer) => {
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const bookings: Array<Booking> = [];
            for (let bookingData of data) {
              bookings.push(Booking.parseBooking(bookingData));
            }

            this.bookings = bookings;
            return this.bookings;
          })
         )
        .subscribe((bookings: Array<Booking>) => {
          observer.next(bookings);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
    });
  }

  public saveBooking (booking: Booking): Observable<Booking> {
    let url = `${environment.API_ROOT}/clients/me/bookings`;
    url += (booking.getId() !== undefined) ? '/' + booking.getId() : '';

    return new Observable<Booking>((observer) => {

      if (booking.getId() !== undefined) {
      
        // update
        this.put(url, booking.toJSON())
          .pipe(
            map((res: any) => {
              const body = res;
              const data = body.data;
              return Booking.parseBooking(data);
            })
          )
          .subscribe((booking: Booking) => {
            observer.next(booking);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });

      } else {
        
        //create
        this.post(url, booking.toJSON())
          .pipe(
            map((res: any) => {
              const body = res;
              const data = body.data;
              return Booking.parseBooking(data);
            })
          )
          .subscribe((booking: Booking) => {
            observer.next(booking);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });

      }

    });
    
  }

  public deleteBooking (booking: Booking, action : string = 'single') : Observable <Booking> {
    let url = `${environment.API_ROOT}/clients/me/bookings/${booking.getId()}`;
    return new Observable<Booking>((observer) => {
      this.delete(url, {action: action, sendEmail: booking.getSendEmail()})
        .pipe(
          map((res: any) => {
            return booking.clone();
          })
        )
        .subscribe((booking: Booking) => {
          observer.next(booking);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
    });
  }

  public loadBookingRequests (stylistID: number): Observable <Array<Booking>> {
    const url = `${environment.API_ROOT}/stylists/${stylistID}/requests`;

    return new Observable<Array<Booking>>((observer) => {
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const bookingRequests: Array<Booking> = [];
            for (let bookingData of data) {
              bookingRequests.push(Booking.parseBooking(bookingData));
            }

            this.bookingRequests = bookingRequests;
            return this.bookingRequests;
          })
        )
        .subscribe((bookings: Array<Booking>) => {
            observer.next(bookings);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });
    });

  }

  public approveBookingRequests (stylistID: number, requests: Array<Booking>) : Observable <boolean> {

    if (requests.length === 0) {
      return observableThrowError("empty requests");
    }

    const url = `${environment.API_ROOT}/stylists/${stylistID}/requests`;
    const bookingGroupIDs: Array<number> = [];

    for (let booking of requests) {
      bookingGroupIDs.push(booking.getId());
    }

    return new Observable<boolean> ((observer) => {
      this.put(url, {
         bookingGroupIDs: bookingGroupIDs
        })
        .pipe(
          map((res: any) => {
            return true;
          })
        )
        .subscribe((success: boolean) => {
          observer.next(success);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
    });
  }

  public declineBookingRequests (stylistID: number, requests: Array<Booking>) : Observable <any> {

    if (requests.length === 0) {
      return observableThrowError("empty requests");
    }

    const url = `${environment.API_ROOT}/stylists/${stylistID}/requests`;
    const bookingGroupIDs: Array<number> = [];

    for (let booking of requests) {
      bookingGroupIDs.push(booking.getId());
    }

    return new Observable<boolean> ((observer) => {
      this.delete(url, {
         bookingGroupIDs: bookingGroupIDs
        })
        .pipe(
          map((res: any) => {
            return true;
          })
        )
        .subscribe((success: boolean) => {
          observer.next(success);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
    });

  }

  public loadWaitListBookings (stylistID: number) : Observable<Array<Booking>> {
    const url = `${environment.API_ROOT}/stylists/${stylistID}/waitlist`;
    
    return new Observable<Array<Booking>>((observer) => {

      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const bookings: Array<Booking> = [];
            
            for (let bookingData of data) {
              bookings.push(Booking.parseBooking(bookingData));
            }

            return bookings;
          })
        )
        .subscribe((bookings: Array<Booking>) => {
          observer.next(bookings);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });

    });
  }

  public bookingOverlapChecker (booking: Booking, service: Service) : Observable<Array<Service> > {

    let url = `${environment.API_ROOT}/bookings/${service.getStylist().getID()}/overlaps`;
    const body = {
      startDate: Utilities.formatDate(service.getStartDateTime()),
      startDuration: service.getDurations().startDuration,
      processDuration: service.getDurations().processDuration,
      finishDuration: service.getDurations().finishDuration
    };

    if (booking.getId() !== undefined && booking.getId() !== null) {
      body['bookingGroupID'] = booking.getId();
    }

    if (booking.getRecurringSettings() !== undefined && booking.getRecurringSettings() !== null) {
      body['frequencyCount'] = booking.getRecurringSettings().frequencyCount;
      body['frequencyType'] = booking.getRecurringSettings().frequencyType;
    }
     
    return new Observable<Array<Service>>((observer) => {

      this.post(url, body)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const services: Array<Service> = [];
            
            for (let serviceData of data) {
              services.push(Service.parseService(serviceData));
            }

            return services;
          })
        )
        .subscribe((services: Array<Service>) => {
          observer.next(services);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });

    });

  }

}