import { Injectable } from '@angular/core';
import { Subject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Salon } from '../models/salon.model';
import { Country } from '../models/country.model';
import { Client } from '../models/client.model';
import { environment } from '../../../environments/environment';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { ErrorHandlerService } from '../services/error-handler.service';
// import { map } from 'rxjs/operators';

@Injectable()
export class UserService extends SalonmonsterHttpClient {

  private loggedIn: boolean;
  private isBookingEnabled: boolean = true;
  private isReadOnly: boolean = false;
  private isAccountDeleted: boolean = false;

  private salonSuccessfullyInitialized: boolean;

  private user: Client;

  private salon: Salon;

  private token: string;

  private salonInitMonitor = new Subject<boolean>();

  private salonInitMonitor$ = this.salonInitMonitor.asObservable();

  constructor(http: HttpClient,
    private errorHandlerService: ErrorHandlerService) {
    super(http);
    this.salonSuccessfullyInitialized = false;

    if (localStorage) {
      const userData = localStorage.getItem('clientUser');
      const token = localStorage.getItem('clientToken');

      if (userData && token) {
        this.login(JSON.parse(userData), token);
      }

    } else {
      this.clear();
    }
  }

  public login(userData: Object, token: string): Promise<any> {
    // console.log('booking summary logged in');
    return new Promise((resolve, reject) => {
      this.clear();

      if (localStorage) {
        localStorage.setItem('clientUser', JSON.stringify(userData));
        localStorage.setItem('clientToken', token);
      }
      this.user = Client.parseClientData(userData);
      this.token = token;
      this.loggedIn = true;
      resolve(this.user);
    });
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public logout() {
    this.clearSession();
  }

  public clearSession() {

    if (localStorage) {
      localStorage.clear();
    }

    this.clear();
  }

  public getUser(): Client {
    return this.user;
  }

  public setUser(user: Client) {
 
    this.login(user, this.getToken()); // was user.toJSON() but this was causing loss of clientID
  }

  public getToken(): string {
    return this.token;
  }

  public getSalon(): Salon {
    return this.salon;
  }

  public setSalon(salon: Salon) {
    this.salonSuccessfullyInitialized = true;
    this.salon = salon;
    this.salonInitMonitor.next(true);
  }
  public setIsBookingEnabled(val: boolean): void {
    this.isBookingEnabled = val;
  }
  public setIsReadonly(val: boolean): void {
    this.isReadOnly = val;
  }
  public setIsAccountDeleted(val: boolean): void {
    this.isAccountDeleted = val;
  }
  public getIsBookingEnabled(): boolean {
    return this.isBookingEnabled;
  }
  public getReadOnly(): boolean {
    return this.isReadOnly;
  }
  public getIsAccountDeleted(): boolean {
    return this.isAccountDeleted;
  }
  public IsBookingActivated(): boolean {
  
      return !this.isReadOnly && this.isBookingEnabled && !this.isAccountDeleted;
  }

  public getSalonInitMonitor(): Observable<boolean> {
    return this.salonInitMonitor$;
  }

  public isSalonSuccessfullyInitialized(): boolean {
    return this.salonSuccessfullyInitialized;
  }

  public requestPasswordReset(email: string, salonID: number): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const url = `${environment.API_ROOT}/passwordreset/request`;
      this.post(url, {
        email: email,
        salonID: salonID
      })
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            return data;
          })
        )
        .subscribe((user) => {
          observer.next(true);
          observer.complete();
        },
          (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          });
    });
  }

  public checkPasswordResetTokenValidity(token: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const url = `${environment.API_ROOT}/passwordreset/validity`;
      this.post(url, {
        token: token
      })
        .pipe(
          map((res: any) => {
            const body = res;
            const data: boolean = body.data;
            return data;
          })
        )
        .subscribe((data: boolean) => {
          observer.next(data);
          observer.complete();
        },
          (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          });
    });
  }

  public passwordReset(token: string, newPassword: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const url = `${environment.API_ROOT}/passwordreset`;
      this.post(url, {
        token: token,
        password: newPassword,
      })
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            return data;
          })
        )
        .subscribe((user) => {
          observer.next(true);
          observer.complete();
        },
          (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          });
    });
  }
  public changePassword (password: string, newPassword: string) : Observable<boolean> {
    return new Observable <boolean> (observer => {
      const url = `${environment.API_ROOT}/clients/changepassword`;
      this.post(url, {
        password: password,
        newPassword: newPassword,
        userId: this.getUser().getID(),
      })
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            return data;
          })
        )
        .subscribe((user) => {
          observer.next(true);
          observer.complete();
        },
          (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          });
    });
  }

  private clear() {
    this.loggedIn = false;
    this.user = undefined;
    this.token = undefined;
  }
}