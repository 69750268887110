import { Component } from '@angular/core';

@Component({
  selector: 'salonmonster-tap-retry',
  templateUrl: './sm-tap-retry.component.html'
})
export class SalonMonsterTapRetryComponent {
  
  constructor () {}
}
