<span 
  tappable 
  [ngClass]="{ 
    'smc-cell-selected': cell.get('selected'), 
    'cell-today': isToday, 
    'cell-disabled': cell.get('disabled'),
    'cell-hover': hover, 
    'cell-hidden': cell.get('hidden') }" 
  [disabled]="cell.get('disabled')">
  {{ (cell.get('hidden') == false) ? cell.get('date').getDate() : '' }}
</span>
