<div fxLayout="row" style='height: 100%; background-color: white; margin-top: 100px;'>
  <div fxFlex.md="33.333"></div>

  <div fxFlex.md="33.333" sm-center>
    <mat-icon>
      refresh
    </mat-icon>
    <br />
    <br />
    Oops, there was a problem loading 
    <br/>
    <br/>
    Please tap to retry
  </div>

  <div fxFlex.md="33.333"></div>
</div>