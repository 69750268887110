<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

<salonmonster-tap-retry
  *ngIf="anErrorOccurred"
  (click)="loadCalendar()"
></salonmonster-tap-retry>

<div [hidden]="isLoading || anErrorOccurred">
  <mat-card class="smc-availability-card">
    <mat-card-title sm-center>Please pick a time</mat-card-title>

    <div fxLayout="wrap" fxLayout.xs="column" fxLayout.sm="column">
      <calendar
        fxFlex="50"
        (dateSelected)="onCellSelected($event)"
        (rangeChanged)="rangeChanged($event)"
        [disabledDates]="disabledDates"
        [currentDate]="currentDate"
        [firstAvailableDate]="firstAvailableDate"
        [hidden]="isLoading"
      ></calendar>

      <div fxFlex="50" [hidden]="isLoading">
        <div class="smc-times-container">
          <h3 sm-center>
            {{ selectedDate | salonMonsterDate : "dddd, MMMM D, Y" }}
          </h3>
          <div *ngIf="!dayHasAvailability" sm-center>
            This day has no available openings, please try another day.
          </div>

          <div fxLayout="wrap" *ngIf="dayHasAvailability">
            <mat-list fxFlex="33.333" class="smc-availability-list">
              <h3 mat-subheader sm-center>Morning</h3>
              <mat-list-item *ngFor="let tm of morning">
                <button
                  fxFlex
                  mat-button
                  tappable
                  (click)="onTimeSlotSelect(tm)"
                  class="smc-clickable-text"
                >
                  {{ tm | salonMonsterDate : "h:mm a" }}
                </button>
              </mat-list-item>
            </mat-list>

            <mat-list fxFlex="33.333" class="smc-availability-list">
              <h3 mat-subheader sm-center>Afternoon</h3>
              <mat-list-item *ngFor="let tm of afternoon">
                <button
                  fxFlex
                  mat-button
                  tappable
                  (click)="onTimeSlotSelect(tm)"
                  class="smc-clickable-text"
                >
                  {{ tm | salonMonsterDate : "h:mm a" }}
                </button>
              </mat-list-item>
            </mat-list>

            <mat-list fxFlex="33.333" class="smc-availability-list">
              <h3 mat-subheader sm-center>Evening</h3>
              <mat-list-item *ngFor="let tm of evening">
                <button
                  fxFlex
                  mat-button
                  tappable
                  (click)="onTimeSlotSelect(tm)"
                  class="smc-clickable-text"
                >
                  {{ tm | salonMonsterDate : "h:mm a" }}
                </button>
              </mat-list-item>
            </mat-list>
          </div>

          <div sm-center *ngIf="showWaitList">
            <br /><br />
            Don't see the time you want?<br />
            <button mat-button (click)="addToWaitList()" tappable>
              Add yourself to the waitlist
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
