<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

<mat-card *ngIf="!isLoading">
  <form [formGroup]='passwordChangeForm' (ngSubmit)="onFormSubmit()">
    <h3>Change your password</h3>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Current Password" formControlName="password" [type]="passwordHide ? 'password' : 'text'" />
          <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="password.hasError('required') && submitted">
            Oops, a current password is required.
          </mat-error>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <input matInput placeholder="New Password" formControlName="newPassword" [type]="newPasswordHide ? 'password' : 'text'"  />
          <mat-icon matSuffix (click)="newPasswordHide = !newPasswordHide">{{newPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="newPassword.hasError('required') && submitted">
            Oops, a new password is required.
          </mat-error>
        </mat-form-field>
        <h5>Password Strength</h5>
        <password-strength-meter
           [password]="newPassword.value"
           [numberOfProgressBarItems]="4"
           [enableFeedback]="true"
    ></password-strength-meter>
    <div>
      <button mat-raised-button primaryButton sm-full-width type="submit">Save</button>
    </div>
    <br />
    <h3>Can't remember your password?</h3>
    <button mat-stroked-button secondaryButton sm-full-width (click)="sendPasswordChangeEmail()">Send a reset email</button>
  </form>
</mat-card>