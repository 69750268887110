import { ServiceDefinition } from './servicedefinition.model';
import { Stylist } from './stylist.model';
import { Client } from './client.model';
import { Lineitem, ILineitem } from './lineitem.model';
import { Cloneable } from './cloneable';
import { Utilities } from '../utilities/utilities';

export interface TimeSlotDurationOptions {
  startDuration: number;
  processDuration: number;
  finishDuration: number;
}

export interface ServiceOptions extends ILineitem{
  id?: number;
  bookingGroupID?: number;
  serviceDefinition?: ServiceDefinition;
  clientNotes?: string;
  stylistNotes?: string;
  startDateTime: Date;
  durations: TimeSlotDurationOptions;
  isPhantom?: boolean;
  serviceOverlaps?: Array<Service>
}

export class Service extends Lineitem implements Cloneable <Service> {

  private id: number;

  private serviceDefinition: ServiceDefinition;

  private clientNotes: string;

  private stylistNotes: string;

  private durations: TimeSlotDurationOptions

  private startDateTime: Date;

  private splitStartDateTime: Date;

  private splitEndDateTime: Date;

  private endDateTime: Date;

  private isBookback: boolean;

  private bookingGroupID: number;

  private serviceOverlaps: Array<Service>;

  constructor (options: ServiceOptions) {
    super (options);

    this.id = options.id;
    this.bookingGroupID = options.bookingGroupID;
    this.startDateTime = options.startDateTime;
    this.durations = options.durations;
    this.serviceDefinition = options.serviceDefinition;
    this.serviceOverlaps = options.serviceOverlaps ? options.serviceOverlaps : [];
    
    if (options.clientNotes !== undefined &&
        options.clientNotes !== null) {
      this.clientNotes = options.clientNotes;      
    } else {
      this.clientNotes = '';
    }

    if (options.stylistNotes !== undefined &&
      options.stylistNotes !== null) {
      this.stylistNotes = options.stylistNotes;
    } else {
      this.stylistNotes = '';
    }

    this.updateDateTimes();
  }

  public clone () : Service {
    return new Service({
      lineItemID: this.getLineItemID(),
      clientID: this.getClientID(),
      client: (this.getClient()) ? this.getClient().clone() : undefined,
      stylist: (this.getStylist()) ? this.getStylist().clone() : undefined,
      type: this.getType(),
      name: this.getName(),
      price: this.getPrice(),
      quantity: this.getQuantity(),
      isRefund: (this.isItemRefund()) ? 1 : 0,
      stylistID: this.getStylistID(),
      discount: (this.getDiscount()) ? this.getDiscount().clone() : undefined,
      taxRateType: this.getTaxRateType(),
      customTaxes: this.getCustomTaxes(),
      isPhantom: this.phantom(),
      id: this.getId(),
      bookingGroupID: this.getBookingGroupID(),
      serviceDefinition: (this.getServiceDefinition()) ? this.getServiceDefinition().clone() : undefined,
      clientNotes: this.getClientNotes(),
      stylistNotes: this.getStylistNotes(),
      startDateTime: (this.getStartDateTime()) ? Utilities.cloneDate(this.getStartDateTime()) : undefined,
      durations: Object.assign({}, this.getDurations())
    });
  }

  public getId () : number {
    return this.id;
  }

  public setId(newId: number) {
    this.id = newId;
  }

  public getBookingGroupID () : number {
    return this.bookingGroupID;
  }

  public setBookingGroupID (bookingGroupID: number) {
    this.bookingGroupID = bookingGroupID; 
  }

  public getServiceDefinition () : ServiceDefinition {
    return this.serviceDefinition;
  }

  public setServiceDefinition (serviceDefinition: ServiceDefinition): Service {
    this.serviceDefinition = serviceDefinition;
    this.setDurations({
      startDuration: serviceDefinition.getDuration(),
      processDuration: serviceDefinition.getProcessDuration(),
      finishDuration: serviceDefinition.getFinishDuration()
    });
    this.setPrice(serviceDefinition.getPrice());
    return this;
  }

  public getClientNotes () : string {
    return this.clientNotes;
  }

  public setClientNotes (notes: string) {
    this.clientNotes = notes;
  }

  public getStylistNotes () : string {
    return this.stylistNotes;
  }

  public setStylistNotes (notes: string) {
    this.stylistNotes = notes;
  }

 

  private updateDateTimes () : void {
    if (this.durations.processDuration !== undefined &&
        this.durations.processDuration !== null &&
        this.durations.processDuration > 0) {
      this.isBookback = true;

      this.splitStartDateTime = Utilities.addMinutes(this.startDateTime, this.durations.startDuration);
      this.splitEndDateTime = Utilities.addMinutes(this.splitStartDateTime, this.durations.processDuration);
      this.endDateTime = Utilities.addMinutes(this.splitEndDateTime, this.durations.finishDuration);
    } else {
      this.isBookback = false;
      this.splitStartDateTime = undefined;
      this.splitEndDateTime = undefined;
      this.endDateTime = Utilities.addMinutes(this.startDateTime, this.durations.startDuration);
    }
  }

  public getDurations(): TimeSlotDurationOptions { 
    return this.durations;
  }
  
  public setDurations(durations: TimeSlotDurationOptions): void {
    this.durations = durations;
    this.updateDateTimes();
  }

  public getTotalServiceDuration (): number { 
    let totalDuration = this.durations.startDuration;

    if (this.durations.processDuration) {
      totalDuration += this.durations.processDuration;
    }

    if (this.durations.finishDuration) {
      totalDuration += this.durations.finishDuration;
    }

    return totalDuration;
  }

  public getStartDateTime () : Date {
    return this.startDateTime;
  }

  public setStartDateTime(newDate: Date) : void {
    this.startDateTime = newDate;
    this.updateDateTimes();
  }

  public getSplitStartDateTime() : Date {
    return this.splitStartDateTime;
  }

  public getSplitEndDateTime () : Date {
    return this.splitEndDateTime;
  }

  public getEndDateTime () : Date {
    return this.endDateTime;
  }

  public isBookBack () : boolean {
    return this.isBookback;
  }

  public getServiceOverlaps (): Array<Service> {
    return this.serviceOverlaps;
  }

  public setServiceOverlaps (overlaps: Array<Service>) {
    this.serviceOverlaps = overlaps;
  }

  public hasAnyProvider (): boolean {
    return this.getStylist() === undefined;
  }

  public static parseService (service: Object) : Service {
    const stylist: Stylist = new Stylist({
      id: service['serviceDefinition'].stylist.id,
      firstName: service['serviceDefinition'].stylist.firstName,
      lastName: service['serviceDefinition'].stylist.lastName,
      photoUrl: service['serviceDefinition'].stylist.photoURL,
      letClientsCancel: service['serviceDefinition'].stylist.letClientsCancel,
      isRebook: service['serviceDefinition'].stylist.isRebook,
      confirmApptsManually: service['serviceDefinition'].stylist.confirmApptsManually,
      allowCancellationUntil: service['serviceDefinition'].stylist.allowCancellationUntil
    });

    const serviceDefinition: ServiceDefinition = new ServiceDefinition({
      id: service['serviceDefinition'].id,
      serviceName: service['serviceDefinition'].name,
      duration: service['duration'],
      processDuration: service['processDuration'],
      finishDuration: service['finishDuration'],
      price: service['price'],
      clientBookable: undefined,
      stylist: stylist,
      description: undefined,
      startingAt: undefined,
      parentID: undefined,
      categoryID: undefined,
      commonStylists: new Set<Stylist>,
    });

    return new Service({
      id: service['id'],
      bookingGroupID: service['id'],
      serviceDefinition: serviceDefinition,
      clientNotes: service['clientNotes'],
      stylistNotes: service['stylistNotes'],
      startDateTime: Utilities.parseDate(service['startDateTime']),
      durations: {
        startDuration: service['duration'],
        processDuration: service['processDuration'],
        finishDuration: service['finishDuration']
      },
      price: service['price'],
      lineItemID: undefined,
      clientID:  undefined,
      client: undefined,
      type: service['type'],
      name: service['serviceDefinition'].name,
      quantity: 1,
      isRefund: 0,
      taxRateType: 0,
      stylist: stylist
    });
  }

  public toJSON () : Object {
    const payload = {};

    if (this.phantom() === false) {
      payload['id'] = this.id;
    }

    payload['serviceDefinition'] = {
      id: this.getServiceDefinition().getID(),
      stylistID: this.getServiceDefinition().getStylist().getID()
    };

    payload['price'] = Number(this.getPrice());
    payload['duration'] = this.getDurations().startDuration;
    payload['processDuration'] = this.getDurations().processDuration;
    payload['finishDuration'] = this.getDurations().finishDuration;
    payload['clientNotes'] = this.clientNotes;
    payload['stylistNotes'] = this.stylistNotes;

    return payload;
  }
}