import { NgModule } from '@angular/core';
import { SalonMonsterFooterComponent } from './sm-footer.component'

@NgModule({
  declarations: [
    SalonMonsterFooterComponent
  ],
  imports: [
  ],
  exports: [
    SalonMonsterFooterComponent
  ],
  entryComponents: [],
  providers: []
})
export class SalonMonsterFooterModule {}
