import { Component, OnInit } from '@angular/core';
import { ServiceCategoryService } from '../../shared/services/service-category.service';
import { ServiceCategory } from '../../shared/models/servicecategory.model';
import { Service } from '../../shared/models/service.model';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { ServiceDefinition } from '../../shared/models/servicedefinition.model';
import { UserBookingService } from '../../shared/services/user-booking.service';


@Component({
  selector: 'app-stylist-any-provider',
  templateUrl: './stylist-any-provider.component.html',
  styleUrls: ['./stylist-any-provider.component.css']
})
export class StylistAnyProviderComponent implements OnInit {

  public serviceCategories: Array<ServiceCategory>;
  public isLoading: boolean;
  constructor(
    private serviceCategoryService: ServiceCategoryService,
    private router: Router,
    private userbookingService: UserBookingService
  ) {
    this.serviceCategories = [];
    this.isLoading = true;
  }
  public ngOnInit() {

    this.serviceCategoryService.getSalonServiceCategories()
      .subscribe((categories) => {
        this.serviceCategories = ServiceCategory.groupedData(categories);
        this.isLoading = false;
      });
  }
  public serviceSelect(serviceDefinition: ServiceDefinition) {
    const booking = this.userbookingService.getBooking();

    if (!serviceDefinition) {
      return;
    }

    const service: Service = new Service({
      serviceDefinition: serviceDefinition,
      clientNotes: '',
      stylistNotes: '',
      price: serviceDefinition.getPrice(),
      startDateTime: undefined,
      durations: {
        startDuration: serviceDefinition.getDuration(),
        processDuration: serviceDefinition.getProcessDuration(),
        finishDuration: serviceDefinition.getFinishDuration()
      },
      lineItemID: undefined,
      clientID: undefined,
      client: undefined,
      type: 'service',
      name: serviceDefinition.getServiceName(),
      quantity: 1,
      isRefund: 0,
      taxRateType: 0,
      stylist: undefined,
    });
    booking.addService(service);
    let navigationExtras: NavigationExtras = {};

    navigationExtras = {
      queryParams: { any: 'true' }
    };
    this.router.navigate(['stylists/availabilities'], navigationExtras);
  }

}
