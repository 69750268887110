import { Discount } from './discount.model';
import { Client } from './client.model';
import { Stylist } from './stylist.model';
import { Utilities } from '../utilities/utilities';

export interface ILineitem {
  lineItemID: number;
  clientID: number;
  client: Client;
  stylist: Stylist;
  type: string;
  name: string;
  price: number;
  quantity: number;
  isRefund: number;
  stylistID?: number;
  discount?: Discount;
  taxRateType: number;
  customTaxes?: Array<number>;
  isPhantom?: boolean;
}

export abstract class Lineitem {

  private lineItemID: number;

  private clientID: number;

  private client: Client;

  private type: string;

  private name: string;

  private price: number;

  private quantity: number;

  private isRefund: number;

  private stylistID: number;

  private stylist: Stylist;

  private discount: Discount;

  private taxRateType: number;

  private customTaxes: Array<number>;

  private preTaxTotals: number;

  private isPhantom: boolean;

  constructor (options: ILineitem) {
    this.lineItemID = options.lineItemID;
    this.clientID = options.clientID;
    this.client = options.client;
    this.type = options.type;
    this.name = options.name;
    this.price = options.price;
    this.quantity = options.quantity;
    this.isRefund = options.isRefund;
    this.stylistID = options.stylistID;
    this.stylist = options.stylist;
    this.discount = options.discount;
    this.taxRateType = options.taxRateType;
    this.customTaxes = options.customTaxes;
    this.preTaxTotals = 0;
    this.isPhantom = options.isPhantom || false;
    this.calculatePreTaxTotals();
  }

  protected calculatePreTaxTotals () {
    this.preTaxTotals = Utilities.roundTo2Decimal(this.price * this.quantity);

    if (this.discount !== undefined && this.discount !== null) {

      if (this.discount.getType() === 0) {

        // normal $ amount
        this.preTaxTotals -= Utilities.roundTo2Decimal(this.discount.getAmount());

      } else if (this.discount.getType() === 1) {
        
        // percentage
        const percent = this.discount.getAmount() / 100;
        const discount = Utilities.roundTo2Decimal(this.preTaxTotals * percent);
        this.preTaxTotals -= Utilities.roundTo2Decimal(discount);

      } else {
        throw new Error('Invalid discount type');
      }

    }

    this.preTaxTotals = Utilities.roundTo2Decimal(this.preTaxTotals);
  }

  public getLineItemID () : number {
    return this.lineItemID;
  }

  public setLineItemID (newID: number) {
    this.lineItemID = newID;
  }

  public getClientID () : number {
    return this.clientID;
  }

  public setClientID (clientID: number) {
    this.clientID = clientID;
  }

  public getClient () : Client {
    return this.client;
  }

  public setClient (client: Client) {
    this.client = client;
  }

  public getType () : string {
    return this.type;
  }

  public setType (type: string) {
    this.type = type;
  }

  public getName () : string {
    return this.name;
  }

  public setName (name: string) {
    this.name = name;
  }

  public getPrice () : number {
    return this.price;
  }

  public setPrice (price: number) {
    this.price = price;
    this.calculatePreTaxTotals();
  }

  public getQuantity () : number {
    return this.quantity;
  }

  public setQuantity (quantity: number) {
    this.quantity = quantity;
    this.calculatePreTaxTotals();
  }

  public isItemRefund () : boolean {
    return (this.isRefund === 1);
  }

  public setRefund (refund: number) {
    this.isRefund = refund;
  }

  public getStylistID () : number {
    return this.stylistID;
  }

  public setStylistID (stylistID: number) {
    this.stylistID = stylistID;
  }

  public getStylist () : Stylist {
    return this.stylist;
  }
  public setStylist (stylist: Stylist) : void {
    this.stylist = stylist;
  }

  public getDiscount () : Discount {
    return this.discount;
  }

  public setDiscount (discount: Discount) {
    this.discount = discount;
    this.calculatePreTaxTotals();
  }

  public removeDiscount () {
    this.discount = undefined;
    this.calculatePreTaxTotals();
  }

  public getTaxRateType () : number {
    return this.taxRateType;
  }

  public setTaxRateType (taxRateType: number) {
    this.taxRateType = taxRateType;
  }

  public getCustomTaxes () : Array<number> {
    return this.customTaxes;
  }

  public setCustomTaxes (customTaxes: Array<number>) {
    this.customTaxes = customTaxes;
  }

  public getPreTaxTotals () : number {
    return this.preTaxTotals;
  }

  public setPhantom (isPhantom: boolean) {
    this.isPhantom = isPhantom;
  }

  public phantom () : boolean {
    return this.isPhantom;
  }
  
}