<div  class="smc-calendar-container">
  <div fxLayout="row wrap" layout-align="center center">
    <div fxFlex="nogrow" sm-center><button mat-icon-button tappable (click)="previous()"><mat-icon>arrow_back</mat-icon></button></div>
    <h3 fxFlex sm-center class='smc-calendar-month'>{{ monthNames[currentDate.getMonth()] }} {{ currentDate.getFullYear() }}</h3>
    <div fxFlex="nogrow" sm-center><button mat-icon-button tappable fxFlex (click)="next()"><mat-icon>arrow_forward</mat-icon></button></div>
  </div>
  <table class="table table-bordered table-fixed monthview-datetable">
    <tr>
      <th *ngFor="let header of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']">
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let week of weeks">
      <td *ngFor="let day of week" sm-center calendar-cell class='smc-clickable-text' valign="middle" [cell]="day" (click)="cellSelect(day)"></td>
    </tr>
  </table>
</div>