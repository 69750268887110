import { NgModule } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { ConfirmDialog }   from './confirm-dialog.component';
import { AlertDialogComponent }   from './alert-dialog.component';
import { DialogsService } from './dialogs.service';
import { ClientLoginModule } from './client-login/client-login.module';
import { AddNoteModule } from './add-note/add-note.module';
@NgModule({
  declarations: [
    ConfirmDialog,
    AlertDialogComponent,
  ],
  imports: [
    MatDialogModule,
    ClientLoginModule,
    MatButtonModule
  ],
  exports: [
    ConfirmDialog,
    AlertDialogComponent
  ],
  entryComponents: [
    ConfirmDialog,
    AlertDialogComponent
  ],
  providers: [
    DialogsService
  ]
})
export class DialogsModule {}
