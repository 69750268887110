import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.component.html'
})

export class AlertDialogComponent {

  public title: string;
  
  public message: string;

  constructor (public mdDialogRef: MatDialogRef<AlertDialogComponent>) {

  }

}
