<h2>Please select a service provider</h2>
<mat-card sm-restrict-width *ngFor="let service of booking.getServices()">
  <div>
    <h3 style="margin-top:15px">{{ service.getName() }}</h3>
    <h5>{{ formattedDate }}</h5>
  </div>
  <mat-list>
    <ng-container *ngFor="let stylist of filteredStylists">
      <mat-divider></mat-divider>
      <mat-list-item style="height: 100px;">
        <div>
          <ng-container>
            <ng-container>
              <div class="mat-card-content">
                <mat-checkbox 
                [checked]="selectedStylist === stylist"
                [(ngModel)]="selectedStylist"
                (change)="onCheckboxChange(stylist)"
                [disabled]="isSingleItem()"
                style="margin-right: 1em;"
              ></mat-checkbox>
                <img
                 style="margin-right:20px"
                  mat-list-avatar
                  *ngIf="
                    stylist.photoUrl != undefined && stylist.photoUrl != ''
                  "
                  [alt]="stylist.firstName + ' ' + stylist.lastName"
                  src="https://salonmonster.com/userfiles/profile_pics/{{
                    stylist.photoUrl
                  }}"
                />

                <div>
                  <h3 mat-line style="font-size: 17px; font-weight: 600">
                    {{ stylist.getFirstName() }} {{ stylist.getLastName() }}
                  </h3>
                  <h3 mat-line style="font-size: 17px; font-weight: 600">
                    {{ stylist.getSelectedPrice() | salonMonsterCurrency }}
                  </h3>
                  <h3 mat-line style="font-size: 17px; font-weight: 600">
                    {{ 'About ' + (stylist.getSelectedDuration() + stylist.getSelectedProcessingDuration() + stylist.getSelectedFinishedDuration()) | SalonMonsterFormatDurationPipe  }} 
                  </h3>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
</mat-card>
<br />
