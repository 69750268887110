<salonmonster-load-mask *ngIf="isCheckingTokenValidity"></salonmonster-load-mask>

<div *ngIf="!isCheckingTokenValidity && !errorOccurredWhileCheckingTokenValidity" sm-restrict-width sm-center>

  <form [formGroup]="passwordResetForm" *ngIf="isTokenValid">
    <h4>To reset your password, confirm a new password again.</h4>

    <mat-form-field>
      <input matInput placeholder="Confirm a new password" type='password' formControlName="password" />
    </mat-form-field>

    <div *ngIf='!password.valid && submitAttempt'>
      <p *ngIf="password.hasError('required')" class="smr-error" class='smr-error'>
        &uarr; Password is required to reset your password
      </p>
    </div>

    <br/>
    <button
      *ngIf="!isCheckingTokenValidity && !errorOccurredWhileCheckingTokenValidity && isTokenValid"
      mat-raised-button
      primaryButton
      class='smc-button-large'
      (click)="onSave()"
      >
      Set password
    </button>

  </form>

  <div sm-center *ngIf="!isTokenValid">
    Your reset password token has expired. 
    <br/>
    
    <button
      mat-raised-button
      primaryButton
      class='smc-button-large'
      (click)="requestPasswordReset()"
      >
      Reset password
    </button>
  </div>
</div>

<!-- 
<mat-card *ngIf="resetSent">
  <mat-card-title sm-center>
    <h4>Check your email</h4>
  </mat-card-title>

  <mat-card-content sm-center>
    <p>
      We've sent an email to {{ email.value }}. Click the link in the email to reset your password.
    </p>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="!resetSent">

  <form [formGroup]="passwordResetForm">

    <mat-form-field>
      <input matInput placeholder="Your email" type='text' formControlName="email" />
    </mat-form-field>

    <div *ngIf="!email.valid && submitAttempt">
      <p *ngIf="email.hasError('required')" class='smr-error'>
        Oops, an email is required.
      </p>
      <p *ngIf="!email.hasError('required') && email.hasError('email')" class='smr-error'>
        Oops, your email is invalid.
      </p>
    </div>
  </form>
</mat-card>

<div sm-center *ngIf="!resetSent">
  <button
    mat-raised-button
    primaryButton
    class='smc-button-large'
    (click)="onSave()"
    >
    Send
  </button>
</div> -->
