
<mat-card sm-restrict-width sm-center>
    <mat-card-title>Let's add you to the wait list</mat-card-title>

  <mat-card-content>
    <div>
      <label for="clientNotes">Please let us know when you would like your appointment and any other <br/> important details that would help us accommodate your needs </label>
      <br/>
    <mat-list *ngIf='booking.getServices().length > 0'>
      <h3>Service<span *ngIf='booking.getServices().length > 1'>s</span> requested:</h3>
      <mat-list-item *ngFor="let service of booking.getServices()">
        <span mat-line>
          {{ service.getName() }} with {{ service.getStylist().getFirstName() }} {{ service.getStylist().getLastName() }}
        </span>
        <p mat-line>
          {{ service.getServiceDefinition().getStartingAt() == 1 ? 'Starting at' : '' }} 
          {{ service.getServiceDefinition().getPrice() | salonMonsterCurrency }}
          {{ 
            ', about ' + (service.getDurations().startDuration + service.getDurations().processDuration + service.getDurations().finishDuration | SalonMonsterFormatDurationPipe) 
             }}        </p>
      </mat-list-item>
    </mat-list>      
    <h3>Desired timeframe:</h3>
    e.g Looking for an evening appointment before April 20th
    <br />
      <textarea 
        matInput 
        name="clientNotes" 
        rows="4" cols="50"
        [(ngModel)]="clientNotes"
        class="sm-waitlist-details"></textarea>
    </div>    
  </mat-card-content>
</mat-card>
<div>
  <salonmonster-stripe-payment *ngIf="clientBookingPayments" (onBookingSave)="onBookingSave()" mode="waitlist" [refreshStripe]="refreshStripe" [booking]="booking"></salonmonster-stripe-payment>
</div>
<mat-card sm-restrict-width sm-center *ngIf="!clientBookingPayments || !this.userService.isLoggedIn()">
  <mat-card-actions>
    <button
      mat-raised-button
      class='smc-button-large smc-button-primary'
      (click)="onBookingSave()"
      >
      {{ this.userService.isLoggedIn() ? "Add me to the waiting list" : "Log in to continue"}}
    </button>
  </mat-card-actions>
  <br /><br />
</mat-card>


