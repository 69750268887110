import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { MaterialModule, MdCardModule, matInputModule } from '@angular/material';

import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { SharedModule } from '../shared/shared.module';
import { SalonMonsterLoadMaskModule } from '../shared/components/sm-load-mask/sm-load-mask.module';

@NgModule({
  declarations: [
    ContactPageComponent
  ],
  imports: [
    BrowserModule,
    // MaterialModule,
    // MdCardModule,
    // matInputModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SalonMonsterLoadMaskModule
  ],
  exports: [
  ],
  providers: [

  ],
  bootstrap: []
})
export class ContactModule { }
