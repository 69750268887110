import { Injectable }     from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable }     from 'rxjs';

import { Booking } from '../models/booking.model';
import { UserService } from '../services/user.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { environment } from '../../../environments/environment';
import { Utilities } from '../utilities/utilities';
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';

export enum NOTIFICATION_TYPE {
  'NEW_BOOKING' = 0,
  'BOOKING_EDITED' = 1,
  'BOOKING_CANCELLED' = 2,
  'NEW_WAIT_LIST' = 3,
  'NEW_BOOKING_REQUEST' = 4
}

@Injectable()
export class NotificationService extends SalonmonsterHttpClient {

  constructor (http: HttpClient, 
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService) {
    super(http);
  }

  public send (booking: Booking, notificationType: NOTIFICATION_TYPE): Observable <Booking> {
    return new Observable<Booking>((observer) => {
      let url = `${environment.API_ROOT}/notifications`;
      let type: string = '';
      for (let t in NOTIFICATION_TYPE) {
        if (NOTIFICATION_TYPE[t].toString() === notificationType.toString()) {
          type = t;
          break;
        }
      }
      this.post(url, {bookingGroupID: booking.getId(), notificationType: type })
        .pipe(
          map((res: any) => {
            return booking.clone();
          })
        )
        .subscribe((booking: Booking) => {
          observer.next(booking);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
    });
  }

}