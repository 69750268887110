import { Service } from './service.model';
import { Stylist } from './stylist.model';
import { ServiceDefinition } from './servicedefinition.model';
import { Client } from './client.model';
import { Country } from './country.model';
import { Cloneable } from './cloneable';
import { Utilities } from '../utilities/utilities';

export interface IBookingOptions {
  id?: number;
  salonID: number;
  startDateTime: Date;
  endDateTime?: Date;
  client: Client;
  status?: number;
  services?: Service[];
  recurring?: RecurringOptions;
  reminderSent?: number;
  sendEmail?: number;
  creationDate: Date;
  photos?: string[];
}

export interface RecurringOptions {
  id?: number;
  frequencyType?: string;
  frequencyCount?: number;
  change?: string;
}

export class Booking implements Cloneable<Booking> {

  private id: number;

  private salonID: number;

  private startDateTime: Date;

  private endDateTime: Date;

  private client: Client;

  private status: number;

  private services: Service[];

  private recurring: RecurringOptions;

  private reminderSent: number;

  private sendEmail: number;

  private creationDate: Date;

  private availableStylistIDs: Number[];

  private photos: string[];

  constructor(options: IBookingOptions) {
    this.id = options.id;
    this.salonID = options.salonID;
    this.startDateTime = options.startDateTime;
    this.endDateTime = options.endDateTime;
    this.client = options.client;
    this.services = options.services || [];
    this.status = options.status || 3;
    this.recurring = options.recurring;
    this.reminderSent = options.reminderSent || 0;
    this.sendEmail = options.sendEmail || 0;
    this.creationDate = options.creationDate;
    this.photos = options.photos ? options.photos : [];
  }

  public clone(): Booking {

    // copy all services
    const clonedServices = [];
    for (let service of this.services) {
      clonedServices.push(service.clone());
    }

    return new Booking({
      id: this.getId(),
      salonID: this.getSalonID(),
      startDateTime: (this.getStartDateTime()) ? Utilities.cloneDate(this.getStartDateTime()) : undefined,
      endDateTime: (this.getEndDateTime()) ? Utilities.cloneDate(this.getEndDateTime()) : undefined,
      client: (this.getClient()) ? this.getClient().clone() : undefined,
      status: this.getStatus(),
      services: clonedServices,
      recurring: (this.getRecurringSettings()) ? Object.assign({}, this.getRecurringSettings()) : undefined,
      reminderSent: this.getReminderSent(),
      sendEmail: this.getSendEmail(),
      creationDate: this.getCreationDate(),
      photos: this.getPhotos()
    });
  }

  public static parseBooking(bookingData: Object): Booking {
    const startDateTime: Date = Utilities.parseDate(bookingData['bookingStartDateTime']);
    const endDateTime: Date = Utilities.parseDate(bookingData['bookingEndDateTime']);
    const status: number = parseInt(bookingData['status'], 10);
    const clientData = bookingData['client'];

    let country: Country;
    if (clientData.countryID) {
      country = new Country(clientData.countryID, clientData.countryName);
    }

    const client = new Client({
      id: clientData.id,
      firstName: clientData.firstName,
      lastName: clientData.lastName,
      email: clientData.email,
      phone1: clientData.phone1,
      phone2: clientData.phone2,
      workPh: clientData.workPh,
      workExt: clientData.workExt,
      address1: clientData.address1,
      address2: clientData.address2,
      city: clientData.city,
      province: clientData.province,
      postal: clientData.postal,
      country: country,
      reminderType: clientData.reminderType,
      badEmail: (clientData.badEmail === 1) ? true : false,
      blockedOnline: (clientData.blockedOnline === 1) ? true : false,
      unsubscribed: (clientData.unsubscribed === 1) ? true : false,
      notes: clientData.notes,
      birthDate: (Utilities.parseDate(clientData.birthDate)) ? Utilities.parseDate(clientData.birthDate) : undefined
    });

    const services: Service[] = [];
    for (let i = 0; i < bookingData['services'].length; i++) {
      const serviceData = bookingData['services'][i];
      const service = Service.parseService(serviceData);
      service.setClient(client);
      services.push(service);
    }

    let recurring: RecurringOptions;
    if (bookingData['recurring'] !== undefined) {
      recurring = {
        id: bookingData['recurring'].id,
        frequencyType: bookingData['recurring'].frequencyType,
        frequencyCount: bookingData['recurring'].frequencyCount
      }
    }

    const bookingOptions: IBookingOptions = {
      id: bookingData['id'],
      salonID: bookingData['salonID'],
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      client: client,
      status: status,
      services: services,
      recurring: recurring,
      reminderSent: bookingData['reminderSent'],
      creationDate: Utilities.parseDate(bookingData['creationDate']),
      photos: bookingData['photos'] ? bookingData['photos'] : []
    };

    return new Booking(bookingOptions);
  }

  public getId(): number {
    return this.id;
  }

  public setId(id: number) {
    this.id = id;
  }

  public getSalonID(): number {
    return this.salonID;
  }

  public setSalonID(salonID: number) {
    this.salonID = salonID;
  }

  public getStartDateTime(): Date {
    return this.startDateTime;
  }
  public setAvailableStylistIDs(availableStylistIDs: Number[]) {
    this.availableStylistIDs = availableStylistIDs;
  }
  public getAvailableStylistIDs(): Number[] {
    return this.availableStylistIDs;
  }

  public setStartDateTime(date: Date) {
    this.startDateTime = date;
    this.updateServicesDateTimes();
  }

  public getEndDateTime(): Date {
    if (this.services.length === 0) {
      return this.startDateTime;
    }

    return this.services[this.services.length - 1].getEndDateTime();
  }

  public getClient(): Client {
    return this.client;
  }

  public setClient(client: Client) {
    this.client = client;
  }

  public getStatus(): number {
    return this.status;
  }

  public setStatus(status: number) {
    this.status = status;
  }

  public getReminderSent(): number {
    return this.reminderSent;
  }

  public setReminderSent(reminderSent: number) {
    this.reminderSent = reminderSent;
  }

  public getServices(): Service[] {
    return this.services;
  }

  public findServiceById(serviceId: number) {

    for (let service of this.services) {
      if (service.getId() === serviceId) {
        return service;
      }
    }

    return null;
  }

  private generateUniqueServiceID(): number {

    let token = 1;

    while (true) {

      let unique = true;

      // loop through all lineitems to see if this token has been already used
      for (let service of this.services) {
        if (service.getId() === token) {
          unique = false;
          break
        }
      }

      if (unique) {
        return token;
      } else {
        // generate another token
        token++;
      }

    }
  }

  public addService(newService: Service): Booking {

    // create a temporary ID for this lineitem
    newService.setId(this.generateUniqueServiceID());

    // important, forcce new lineitem to be Phsantom
    newService.setPhantom(true);

    this.services.push(newService);
    this.updateServicesDateTimes();
    return this;
  }

  public insertService(index: number, newService: Service): Booking {

    // create a temporary ID for this lineitem
    newService.setId(this.generateUniqueServiceID());

    // important, forcce new lineitem to be Phsantom
    newService.setPhantom(true);

    this.services[index] = newService;
    this.updateServicesDateTimes();
    return this;
  }

  public removeService(serviceID: number): Booking {

    let index = 0;
    let serviceToBeDeleted;
    for (let service of this.services) {

      if (service.getId() === serviceID) {
        serviceToBeDeleted = service;
        break;
      }

      index++;
    }

    if (serviceToBeDeleted !== undefined) {
      this.services.splice(index, 1);
      this.updateServicesDateTimes();
      return this;
    } else {

      // service to be deleted not found
      throw new Error('invalid ServiceID');

    }

  }

  public removeServices() {
    this.services = [];
    this.syncServiceTimes();
  }

  public syncServiceTimes(): Booking {
    this.updateServicesDateTimes();
    return this;
  }

  private updateServicesDateTimes() {
    let dateTime = this.startDateTime;
    for (let service of this.services) {
      service.setStartDateTime(dateTime);
      dateTime = service.getEndDateTime();
    }
  }

  public getRecurringSettings(): RecurringOptions {
    return this.recurring;
  }

  public isRecurring() {
    return this.recurring !== undefined;
  }

  public isAlreadyLinkedToARecurringAppointment() {
    return this.recurring !== undefined && this.recurring.id !== undefined;
  }

  public setRecurring(options: RecurringOptions) {
    if (this.recurring.id !== undefined &&
      this.recurring.id !== null) {
      throw new Error(`Can't set recurring options. Please use setFrequencyCount() or setFrequencyType()`);
    }

    this.recurring = options;
  }

  public setRecurringFrequencyCount(count: number, change: string = 'single') {
    if (this.recurring === undefined) {
      this.recurring = {
        frequencyCount: 1,
        frequencyType: 'days'
      }
    }

    if (this.isAlreadyLinkedToARecurringAppointment()) {
      this.recurring.change = change;
    }

    this.recurring.frequencyCount = count;
  }

  public setRecurringFrequencyType(type: string, change: string = 'single') {
    if (this.recurring === undefined) {
      this.recurring = {
        frequencyCount: 1,
        frequencyType: 'days'
      }
    }

    if (this.isAlreadyLinkedToARecurringAppointment()) {
      this.recurring.change = change;
    }

    this.recurring.frequencyType = type;
  }

  public setRecurringChangeOptionType(change: string = 'single') {
    if (this.recurring === undefined) {
      this.recurring = {
        frequencyCount: 1,
        frequencyType: 'days'
      }
    }

    this.recurring.change = change;
  }

  public turnOffRecurring() {
    if (this.isAlreadyLinkedToARecurringAppointment()) {
      this.recurring.change = 'turnoff';
    } else {
      this.recurring = undefined;
    }
  }

  public getNumOfServicesWithAnyProvider(): number {
    let count = 0;
    for (let service of this.getServices()) {
      if (service.getStylist() === undefined) {
        count++;
      }
    }

    return count;
  }

  public getServicesWithAnyProvider(): Array<Service> {
    const services: Array<Service> = [];
    for (let service of this.getServices()) {
      if (service.hasAnyProvider()) {
        services.push(service);
      }
    }

    return services;
  }

  public setSendEmail(type: number) {
    this.sendEmail = type;
  }

  public getSendEmail(): number {
    return this.sendEmail;
  }

  public getCreationDate(): Date {
    return this.creationDate;
  }

  public getPhotos(): string[] {
    return this.photos;
  }

  public setPhotos(photos: Array<string>) {
    this.photos = [...photos];
  }

  public addPhotos(photo: string) {
    this.photos.push(photo);
  }

  public removePhoto(index: number) {
    this.photos.splice(index, 1);
  }

  public toJSON(): Object {
    const payload = {};
    payload['bookingStartDateTime'] = Utilities.formatDate(this.startDateTime);
    payload['salonID'] = this.salonID;
    payload['status'] = this.status;
    payload['clientID'] = (this.client) ? this.client.getID() : undefined;
    payload['services'] = [];

    for (let i = 0; i < this.services.length; i++) {
      payload['services'].push(this.services[i].toJSON());
    }

    if (this.isRecurring()) {
      payload['recurring'] = {
        frequencyType: this.recurring.frequencyType,
        frequencyCount: this.recurring.frequencyCount
      };

      if (this.recurring.id !== undefined) {
        payload['recurring'].id = this.recurring.id;
      }

      if (this.recurring.change !== undefined) {
        payload['recurring'].change = this.recurring.change;
      }
    }

    payload['sendEmail'] = this.sendEmail;
    payload['photos'] = this.photos;

    // console.log(this.photos);

    return payload;
  }
}