import { Injectable }     from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map }     from 'rxjs';

import { Country } from '../models/country.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { environment } from '../../../environments/environment';
// import 'rxjs/Rx';
// import { map } from 'rxjs/operators';

@Injectable()
export class CountryService extends SalonmonsterHttpClient {

  private countries: Array<Country>;

  constructor (http: HttpClient,
      private errorHandlerService: ErrorHandlerService) {
    super(http);
    this.countries = [];
  }

  public getCountries (): Observable<Array<Country>> {
    return new Observable<Array<Country>> ((observer) => {
      const url = `${environment.API_ROOT}/countries?fields=id,name`;

      if (this.countries.length === 0) {
        this.get(url)
          .pipe(
            map((res: any) => {
              let body = res;

              const data = body.data;
              const countries: Array<Country> = [];
              for (let countryData of data) {
                const id = parseInt(countryData.id, 10);
                const name = countryData.name;
                countries.push(new Country(id, name));
              }

              this.countries = countries;
              return this.countries;
            })
          )
          .subscribe((countries: Array<Country>) => {
            observer.next(countries);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });

      } else {

        // return cached countries
        observer.next(this.countries);
        observer.complete();
      }

    });
  }

}