import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { Record } from 'immutable';
import { CalendarCell } from './calendar.component';
import { Utilities } from '../../../../shared/utilities/utilities';

@Component({
  selector: '[calendar-cell]',
  templateUrl: 'calendar-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      span{
        display:block;
        height: 2em;
        width: 2em;
        padding-top:5px;
        margin: 2px auto;
        box-sizing: border-box;      
        border: 2px solid transparent;  
      }
      span.cell-today{
          border: 2px solid #000;
          border-radius: 50%;
      }
      span.cell-today.cell-disabled{
          /*border: 2px solid #ccc;*/
          border:none;
      }    
      span.cell-today.cell-hidden{
          border: 2px solid transparent;
      }        
      span.cell-disabled{
        color: #ccc;
      }
      span.cell-today.cell-disabled{
          /*border: 2px solid #ccc;*/
          border:none;
          color: #ccc;
      }  
      span.cell-today.cell-disabled.cell-hover{
          /*border: 2px solid #ccc;*/
          border:none;
      }               
      span.cell-hover:not(.cell-hidden){
          border-color: #000;
          color: #000 !important;
          border-radius: 50%;
          cursor: pointer;
      }
      span.cell-disabled.cell-hover{
          /*border-color: transparent;*/
          border:none;
          color: #ccc !important;          
          cursor: default;
      }      
      span.cell-hidden{
        cursor: text !important;
      }
      span.selected {
        background: #000;
      }
    `
  ],
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()'
  }
})
export class CalendarCellComponent implements OnInit {
 
  @Input() cell: CalendarCell;

  public isToday: boolean;

  public hover: boolean;
   
  constructor (private renderer: Renderer2, private el: ElementRef) {
  }

  public ngOnInit () {
    this.isToday = Utilities.formatDate(new Date(), 'Y-MM-DD') === Utilities.formatDate(this.cell.get('date'), 'Y-MM-DD');
    
    // if (isToday) {
      // this.renderer.setElementClass(this.el.nativeElement, 'cell-today', true); 
      // this.cellClasses.push('cell-today');
    // }

    // if (this.cell.get('disabled')) {
      // this.renderer.setElementClass(this.el.nativeElement, 'cell-disabled', true); 
      // this.cellClasses.push('cell-disabled');
    // }

    // if (this.cell.get('selected')) {
    //   this.renderer.setElementClass(this.el.nativeElement, 'cell-selected', true); 
    //     this.cellClasses.push('cell-selected');
    // }
  }

  public onMouseEnter () {
    this.hover = true;
  }

  public onMouseLeave () {
    this.hover = false;
  }

}
