import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// import { MaterialModule, MdCardModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from '../shared/shared.module';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HomePageComponent
  ],
  imports: [
    BrowserModule,
    // MaterialModule,
    // MdCardModule,
    MatCardModule,
    RouterModule,
    SharedModule,
    // NgbModule
  ],
  exports: [
    HomePageComponent
  ],
  providers: [

  ],
  bootstrap: [
    HomePageComponent
  ]
})
export class HomeModule { }
