import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// import { MaterialModule, MdCardModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { HelpPageComponent } from './help-page/help-page.component';

@NgModule({
  declarations: [
    HelpPageComponent
  ],
  imports: [
    BrowserModule,
    // MaterialModule,
    // MdCardModule,
    MatCardModule,
    RouterModule
  ],
  exports: [
  ],
  providers: [

  ],
  bootstrap: []
})
export class HelpModule { }
