import { Country } from './country.model';
import { Cloneable } from './cloneable';

export interface SalonOptions {
  id: number;
  subDomain: string;
  name: string;
  calStartTime: string;
  calEndTime: string;
  appVersion: number;
  website: string;
  address1: string;
  address2: string;
  phone: string;
  fax: string;
  city: string;
  province: string;
  postal: string;
  cancellationPolicy: string;
  email: string;
  readOnly: number;
  timeZone: string;
  logoUrl: string;
  planID: number;
  accountStatus: number;
  creationDate: Date;
  sameSocialMediaLinksForAllStaff: number;
  sameCancellationPolicyForAllStaff: number;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  pinterestLink: string;
  tumblrLink: string;
  yelpLink: string;
  trialLength: number;
  country: Country;
  referralCodeUsed?: string;
  analytics: string;
  currency: string;
  reqPass: number;
  reqAddress: number;
  hideAnyProvider: number;
  showHome: number;
  homeHtml: string;
  enableOnlineBooking: boolean;
  clientBookingPayments: number;
  noShowPercentage: number;
}

export class Salon implements Cloneable <Salon>{

  private id: number;

  private subDomain: string;

  private name: string;

  private calStartTime: string;

  private calEndTime: string;

  private appVersion: number;

  private website: string;

  private address1: string;

  private address2: string;

  private phone: string;

  private fax: string;

  private city: string;

  private province: string;

  private postal: string;
  
  private cancellationPolicy: string;
  
  private email: string;
  
  private readOnly: number;
  
  private timeZone: string;
  
  private planID: number;

  private logoUrl: string;
  
  private accountStatus: number;
  
  private creationDate: Date;
  
  private sameSocialMediaLinksForAllStaff: number;
  private sameCancellationPolicyForAllStaff: number;
  
  private facebookLink: string;

  private twitterLink: string;

  private instagramLink: string;

  private pinterestLink: string;

  private tumblrLink: string;

  private yelpLink: string;

  private trialLength: number;
  
  private country: Country;
  
  private referralCodeUsed: string;

  private analytics: string;

  private currency: string;

  private reqPass: number;
  
  private reqAddress: number;

  private hideAnyProvider: number;

  private showHome: number;

  private homeHtml: string;

  private enableOnlineBooking: boolean;

  private clientBookingPayments: number;
  private noShowPercentage:number;
  
  constructor (options?: SalonOptions) {
    this.id = options.id;
    this.subDomain = options.subDomain;
    this.name = options.name;
    this.calStartTime = options.calStartTime;
    this.calEndTime = options.calEndTime;
    this.appVersion = options.appVersion;
    this.website = options.website;
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.phone = options.phone;
    this.fax = options.fax;
    this.city = options.city;
    this.province = options.province;
    this.postal = options.postal;
    this.cancellationPolicy = options.cancellationPolicy;
    this.email = options.email;
    this.readOnly = options.readOnly;
    this.timeZone = options.timeZone;
    this.planID = options.planID;
    this.logoUrl = options.logoUrl;
    this.accountStatus = options.accountStatus;
    this.creationDate = options.creationDate;
    this.sameSocialMediaLinksForAllStaff = options.sameSocialMediaLinksForAllStaff;
    this.sameCancellationPolicyForAllStaff = options.sameCancellationPolicyForAllStaff;
    this.facebookLink = options.facebookLink;
    this.twitterLink = options.twitterLink;
    this.instagramLink = options.instagramLink;
    this.pinterestLink = options.pinterestLink;
    this.tumblrLink = options.tumblrLink;
    this.yelpLink = options.yelpLink;
    this.trialLength = options.trialLength;
    this.country = options.country;
    this.referralCodeUsed = options.referralCodeUsed;
    this.analytics = options.analytics;
    this.currency = options.currency;
    this.reqPass = options.reqPass || 0;
    this.reqAddress = options.reqAddress || 0;
    this.hideAnyProvider = options.hideAnyProvider || 0;
    this.showHome = options.showHome || 0;
    this.homeHtml = options.homeHtml || '';
    this.clientBookingPayments = options.clientBookingPayments;
    this.noShowPercentage = options.noShowPercentage;
    this.enableOnlineBooking = options.enableOnlineBooking;
  }

  public clone () : Salon {
    return new Salon({
      id: this.getId(),
      subDomain: this.getSubDomain(),
      name: this.getName(),
      calStartTime: this.getCalStartTime(),
      calEndTime: this.getCalEndTime(),
      appVersion: this.getAppVersion(),
      website: this.getWebsite(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      phone: this.getPhone(),
      fax: this.getFax(),
      city: this.getCity(),
      province: this.getProvince(),
      postal: this.getPostal(),
      cancellationPolicy: this.getCancellationPolicy(),
      email: this.getEmail(),
      readOnly: (this.isReadOnly()) ? 1 : 0,
      timeZone: this.getTimeZone(),
      planID: this.getPlanID(),
      logoUrl: this.getLogoUrl(),
      accountStatus: this.getAccountStatus(),
      creationDate: this.getCreationDate(),
      sameSocialMediaLinksForAllStaff: this.getSameSocialMediaLinksForAllStaff(),
      sameCancellationPolicyForAllStaff: this.getSameCancellationPolicyForAllStaff(),
      facebookLink: this.getFacebookLink(),
      twitterLink: this.getTwitterLink(),
      instagramLink: this.getInstagramLink(),
      pinterestLink: this.getPinterestLink(),
      tumblrLink: this.getTumblrLink(),
      yelpLink: this.getYelpLink(),
      trialLength: this.getTrialLength(),
      country: this.getCountry(),
      referralCodeUsed: this.getReferralCodeUsed(),
      analytics: this.getAnalytics(),
      currency: this.getCurrency(),
      reqPass: this.reqPass,
      reqAddress: this.reqAddress,
      hideAnyProvider: this.hideAnyProvider,
      showHome: this.showHome,
      homeHtml: this.homeHtml,
      enableOnlineBooking: this.getEnableOnlineBooking(),
      clientBookingPayments: this.getClientBookingPayments(),
      noShowPercentage: this.getNoShowPercentage()
    });
  }

  public getId () : number {
    return this.id;
  }

  public getSubDomain () : string {
    return this.subDomain;
  }

  public setSubDomain (subDomain: string) {
    this.subDomain = subDomain;
  }
  
  public getName () : string {
    return this.name;
  }

  public setName (salonName: string) {
    this.name = salonName;
  }

  public getCalStartTime () : string {
    return this.calStartTime;
  }

  public getCalEndTime () : string {
    return this.calEndTime;
  }

  public getAppVersion () : number {
    return this.appVersion;
  }

  public getWebsite () : string {
    return this.website;
  }

  public setWebsite (website: string) {
    this.website = website;
  }

  public getAddress1 () : string {
    return this.address1;
  }

  public setAddress1 (address: string) {
    this.address1 = address;
  }

  public getAddress2 () : string {
    return this.address2;
  }

  public setAddress2 (address: string) {
    this.address2 = address;
  }

  public getPhone () : string {
    return this.phone;
  }

  public setPhone (phone: string) {
    this.phone = phone;
  }

  public getFax () : string {
    return this.fax;
  }

  public setFax (fax: string) {
    this.fax = fax;
  }

  public getCity () : string {
    return this.city;
  }

  public setCity (city: string) {
    this.city = city;
  }

  public getProvince () : string {
    return this.province;
  }

  public setProvince (province: string) {
    this.province = province;
  }

  public getPostal () : string {
    return this.postal;
  }

  public setPostal (postal: string ) {
    this.postal = postal;
  }

  public getCancellationPolicy () : string {
    return this.cancellationPolicy;
  }

  public setCancellationPolicy (cancellationPolicy: string) {
    this.cancellationPolicy = cancellationPolicy;
  }

  public getEmail () : string {
    return this.email;
  }

  public setEmail (email: string) {
    this.email = email;
  }

  public isReadOnly () : boolean {
    return this.readOnly === 1;
  }

  public getTimeZone () : string {
    return this.timeZone;
  }

  public setTimeZone (timezone: string) {
    this.timeZone = timezone;
  }

  public getPlanID () : number {
    return this.planID;
  }

  public getLogoUrl () : string {
    return this.logoUrl;
  }

  public getAccountStatus () : number {
    return this.accountStatus;
  }

  public getCreationDate () : Date {
    return this.creationDate;
  }

  public getSameSocialMediaLinksForAllStaff () : number {
    return this.sameSocialMediaLinksForAllStaff;
  }
  public getSameCancellationPolicyForAllStaff () : number {
    return this.sameCancellationPolicyForAllStaff;
  }

  public getFacebookLink () : string {
    return this.facebookLink;
  }

  public getTwitterLink () : string {
    return this.twitterLink;
  }

  public getInstagramLink () : string {
    return this.instagramLink;
  }

  public getPinterestLink () : string {
    return this.pinterestLink;
  }

  public getTumblrLink () : string {
    return this.tumblrLink;
  }

  public getYelpLink () : string {
    return this.yelpLink;
  }


  public getTrialLength () : number {
    return this.trialLength;
  }

  public getCountry () : Country {
    return this.country;
  }

  public setCountry (country: Country) {
    this.country = country;
  }

  public getReferralCodeUsed () : string {
    return this.referralCodeUsed;
  }

  public getAnalytics () : string {
    return this.analytics;
  }

  public setAnalytics (analytics: string) {
    this.analytics = analytics;
  }

  public getCurrency () : string {
    return this.currency;
  }

  public getReqPass () : number {
    return this.reqPass;
  }

  public setReqPass (val: number) {
    this.reqPass = val;
  }

  public getReqAddress () : number {
    return this.reqAddress;
  }

  public setReqAddress (val: number) {
    this.reqAddress = val;
  }

  public setCurrency (currency: string) {
    this.currency = currency;
  }

  public getHideAnyProvider () : number {
    return this.hideAnyProvider;
  }

  public getClientBookingPayments () : number {
    return this.clientBookingPayments;
  }

  public getNoShowPercentage () : number {
    return this.noShowPercentage;
  }

  public getShowHome () : number {
    return this.showHome;
  }

  public getHomeHtml () : string {
    return this.homeHtml;
  }

  public getEnableOnlineBooking () : boolean {
    return this.enableOnlineBooking;
  }

  public static parseSalon (salonData: Object): Salon {
    // console.log("from parse salon: ", salonData['clientBookingPayments']);
    return new Salon({
      id: salonData['id'],
      subDomain: salonData['subDomain'],
      name: salonData['salonName'],
      calStartTime: salonData['calStartTime'],
      calEndTime: salonData['calEndTime'],
      appVersion: salonData['appVersion'],
      website: salonData['website'],
      address1: salonData['address1'],
      address2: salonData['address2'],
      phone: salonData['phone'],
      fax: salonData['fax'],
      city: salonData['city'],
      province: salonData['province'],
      postal: salonData['postal'] ,
      cancellationPolicy: salonData['cancellationPolicy'],
      email: salonData['email'],
      readOnly: salonData['readOnly'],
      timeZone: salonData['timeZone'],
      planID: salonData['planID'],
      logoUrl: salonData['logoUrl'],
      accountStatus: salonData['accountStatus'],
      creationDate: salonData['creationDate'],
      sameSocialMediaLinksForAllStaff: salonData['sameSocialMediaLinksForAllStaff'],
      sameCancellationPolicyForAllStaff: salonData['sameCancellationPolicyForAllStaff'],
      facebookLink: salonData['facebookLink'],
      twitterLink: salonData['twitterLink'],
      instagramLink: salonData['instagramLink'],
      pinterestLink: salonData['pinterestLink'],
      tumblrLink: salonData['tumblrLink'],
      yelpLink: salonData['yelpLink'],
      trialLength: salonData['trialLength'],
      referralCodeUsed: salonData['referralCodeUsed'],
      country: new Country(
        salonData['countryID'],
        salonData['countryName']
      ),
      analytics: salonData['analytics'],
      currency: salonData['currency'],
      reqPass: salonData['reqPass'],
      reqAddress: salonData['reqAddress'],
      hideAnyProvider: salonData['hideAnyProvider'],
      showHome: salonData['showHome'],
      homeHtml: salonData['homeHtml'],
      enableOnlineBooking: salonData['enableOnlineBooking'],
      clientBookingPayments: salonData['clientBookingPayments'],
      noShowPercentage: salonData['noShowPercentage']
    });
  }

  public toJSON () : Object {
    return {
      id: this.getId(),
      subDomain: this.getSubDomain(),
      salonName: this.getName(),
      calStartTime: this.getCalStartTime(),
      calEndTime: this.getCalEndTime(),
      appVersion: this.getAppVersion(),
      website: this.getWebsite(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      phone: this.getPhone(),
      fax: this.getFax(),
      city: this.getCity(),
      province: this.getProvince(),
      postal: this.getPostal(),
      cancellationPolicy: this.getCancellationPolicy(),
      email: this.getEmail(),
      readOnly: (this.isReadOnly()) ? 1 : 0,
      timeZone: this.getTimeZone(),
      planID: this.getPlanID(),
      logoUrl: this.getLogoUrl(),
      accountStatus: this.getAccountStatus(),
      creationDate: this.getCreationDate(),
      sameSocialMediaLinksForAllStaff: this.getSameSocialMediaLinksForAllStaff(),
      sameCancellationPolicyForAllStaff: this.getSameCancellationPolicyForAllStaff(),
      facebookLink: this.getFacebookLink(),
      twitterLink: this.getTwitterLink(),
      instagramLink: this.getInstagramLink(),
      pinterestLink: this.getPinterestLink(),
      tumblrLink: this.getTumblrLink(),
      yelpLink: this.getYelpLink(),      
      trialLength: this.getTrialLength(),
      countryID: (this.getCountry()) ? this.getCountry().getID() : undefined,
      countryName: (this.getCountry()) ? this.getCountry().getCountryName(): '',
      referralCodeUsed: this.getReferralCodeUsed(),
      analytics: this.getAnalytics(),
      currency: this.getCurrency()
    };
  }
 
}