import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { StripePaymentComponent } from './stripe-payment.component';

import { SharedModule } from '../../shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    StripePaymentComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    FlexLayoutModule,
    SharedModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule
  ],
  exports: [
    StripePaymentComponent
  ],
  entryComponents: [],
  providers: []
})
export class StripePaymentModule {}
