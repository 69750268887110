import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms'

import { Salon } from '../../shared/models/salon.model';
import { Utilities } from '../../shared/utilities/utilities';
import { UserService } from '../../shared/services/user.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';

@Component({
  selector: 'sm-password-reset-request',
  templateUrl: 'password-reset-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetRequestComponent implements OnInit {

  public email: FormControl;

  public passwordResetForm: FormGroup;

  public submitAttempt: boolean;

  public resetSent: boolean;

  public isLoading: boolean;

  public anErrorOccurred: boolean;

  constructor (private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dialogsService: DialogsService,
    private router: Router,
    private formBuilder: FormBuilder) {
      this.email = new FormControl('', Validators.required);
    this.passwordResetForm = this.formBuilder.group({
      email: this.email
    });

  }

  public ngOnInit () {}

  public onSave () {
    this.submitAttempt = true;
    
    if (this.passwordResetForm.valid === false) {
      return;
    }

    this.isLoading = true;
    this.anErrorOccurred = false;
    this.changeDetectorRef.markForCheck();
    this.userService.requestPasswordReset(this.email.value, this.userService.getSalon().getId())
      .subscribe(() => {
        this.isLoading = false;
        this.resetSent = true;        
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.isLoading = false;
        this.anErrorOccurred = true;
        this.changeDetectorRef.markForCheck();
        const errorObj = JSON.parse(err._body);        // this.dialogsService.errorAlert().subscribe();
        
        
      });

  }

}
