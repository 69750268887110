import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent {
  public noteText: string;
  constructor(private dialogRef: MatDialogRef<AddNoteComponent>) {}
  public onSaveNote(): void {
    this.dialogRef.close(this.noteText);
  }
  public onClose(): void {
    this.dialogRef.close(); 
  }
}
