<mat-card sm-restrict-width id="client_container" class="help_page">
  <h2>Here are our <em>Frequently Asked Questions</em> to try and help solve you problems.</h2>
  If you need futher assistance <a href="mailto:help@salonmonster">contact us</a>
  <br /><br />
  <ul>
      <li><a href="" (click)="scroll(how_do_i_cancel_an_appointment)">How do I cancel an appointment?</a></li>
      <li><a href="" (click)="scroll(is_my_data_stored_securely)">Is my data stored securely and confidentially?</a></li>
      <li><a href="" (click)="scroll(can_i_change_an_appointment)">Can I change an appointment after it has been made?</a></li>
      <li><a href="" (click)="scroll(will_i_be_charged_for_this)">Will I be charged anything for booking this appointment?</a></li>     
      <li><a href="" (click)="scroll(why_do_you_need_my)">Why do you need my email and phone number?</a></li>    
      <li><a href="" (click)="scroll(i_forgot_my_password)">I forgot my password.</a></li>
  </ul>
</mat-card>

<br />
<div #how_do_i_cancel_an_appointment class="faq_box">
  <mat-card>
      <h3> How do I cancel an appointment</h3>
      <b>To cancel an appointment:</b><br />
      <ul>
        <li>Click on "Your bookings"</li>
        <li>Locate the upcoming appointment that you wish to cancel</li>
        <li>Click on the cancel link below the appointment you want to cancel, this will take you to the cancel confirmation</li>
        <li>Click the "Yes - Cancel this appointment" button</li>
        <li>You will receive an email confirmation shortly after cancelling an appointment</li>
      </ul>
  </mat-card>
</div> 
  <br/>
<div #is_my_data_stored_securely>
  <mat-card class="faq_box">
      <h3> Is my data stored securely and confidentially?</h3>
     Your information is only seen by your stylist and salon. We won't share or sell your information to third parties. Make sure to set a password to secure your account.<br />
  </mat-card>
</div>
  <br/>
<div #can_i_change_an_appointment>
  <mat-card #can_i_change_an_appointment class="faq_box">
      <h3>Can I change an appointment after it has been made?</h3>
      This depends on your service provider's preferences. If they do allow rescheuling you can:
      <ul>
        <li>Click on "Your bookings"</li>
        <li>Locate the upcoming appointment that you wish to adjust</li>
        <li>Click on the reschedule link below the appointment you want to adjust.</li>
        <li>Pick a new date and time and complete the booking process</li>
        <li>You will receive an email confirmation shortly after rescheduling an appointment</li>
      </ul>
  </mat-card>
</div>
  <br/>
<div #will_i_be_charged_for_this >
  <mat-card class="faq_box">
      <h3>Will I be charged anything for booking this appointment?</h3>
      Fees are set by the salon.  If you have been asked to enter a card on file you may be charged if you do not show up for your appointment.<br />
  </mat-card>
</div>
  <br/>
<div  #why_do_you_need_my>
  <mat-card class="faq_box">
      <h3>Why do you need my email and phone number?</h3>
      <b>We ask for your email address</b> so that we can send you email confirmation of your bookings as well as appointment reminders.  Your stylist will also sometimes use your email to contact you.<br /><br />
      <b>We ask for your phone number</b> so that the salon or your stylist can call you to discuss a booking further or to call you in situations where it is important to ensure a message is conveyed quickly.<br /><br />
      We <a (click)="scroll(is_my_data_stored_securely)">won't share your contact details</a> with third parties.
  </mat-card>
</div>
  <br/>
  <div #i_forgot_my_password>
    <mat-card class="faq_box">
        <h3>I forgot my password.</h3>
        <em>New Users:</em> If you have recently created an account you probably don't have one set yet and can login without filling out the password field..<br /><br />
        <em>To get your password sent to you via email:</em><br />
        Click on the Login button at the top right of the screen.<br />
        On the login page, click on the "<a href="" (click)="forgotPassword()">Forgot your password?</a>"" link.<br />
        Enter the email address you signed up for the account with into the field provided and click the "Send reset email" button.
    </mat-card>
  </div>
  <br/>

