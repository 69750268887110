import { ServiceDefinition } from './servicedefinition.model';
import { Cloneable } from './cloneable';
import { Stylist } from './stylist.model';

export interface ServiceCategoryOptions {
  id: number;
  categoryName: string;
  serviceDefinitions: Array<ServiceDefinition>
}

export class ServiceCategory implements Cloneable<ServiceCategory> {

  private id: number;

  private categoryName: string;

  private serviceDefinitions: Array<ServiceDefinition>;

  constructor(options: ServiceCategoryOptions) {
    this.id = options.id;
    this.categoryName = options.categoryName;
    this.serviceDefinitions = options.serviceDefinitions;
  }

  public static parseData(data: Object): ServiceCategory {

    const serviceDefinitions = [];
    for (let serviceDefinition of data['serviceDetails']) {
      serviceDefinitions.push(ServiceDefinition.parseServiceDefinition(serviceDefinition));
    }

    return new ServiceCategory({
      id: data['id'],
      categoryName: data['categoryName'],
      serviceDefinitions: serviceDefinitions
    });
  }


  public clone(): ServiceCategory {
    const serviceDefinitions = [];
    for (let serviceDefinition of this.serviceDefinitions) {
      serviceDefinitions.push(serviceDefinition.clone());
    }

    return new ServiceCategory({
      id: this.getId(),
      categoryName: this.getCategoryName(),
      serviceDefinitions: serviceDefinitions
    });
  }

  public getId(): number {
    return this.id;
  }

  public setId(id: number) {
    this.id = id;
  }

  public getCategoryName(): string {
    return this.categoryName;
  }

  public setCategoryName(name: string) {
    this.categoryName = name;
  }

  public getServiceDefinitions(): Array<ServiceDefinition> {
    return this.serviceDefinitions;
  }

  public getBookableServiceDefinitions(): Array<ServiceDefinition> {
    const newServiceDefinitions: Array<ServiceDefinition> = [];

    for (let sd of this.serviceDefinitions) {
      if (sd.isClientBookable()) {
        newServiceDefinitions.push(sd);
      }
    }

    return newServiceDefinitions;
  }

  public setServiceDefinitions(serviceDefinitions: Array<ServiceDefinition>) {
    this.serviceDefinitions = serviceDefinitions;
  }

  public toJSON(): Object {
    return {
      id: this.getId(),
      categoryName: this.getCategoryName()
    }
  }
  public static groupedData(data: Array<ServiceCategory>): Array<ServiceCategory> {
    const groupedData = data.reduce((result: { [key: string]: ServiceCategoryOptions }, currentItem) => {
      const category = currentItem.categoryName;

      if (!result[category]) {
        const temp: ServiceDefinition[] = [];
        currentItem.serviceDefinitions.forEach(service => this._processService(temp, service));

        result[category] = {
          id: null,
          categoryName: category,
          serviceDefinitions: [...temp],
        };
      } else {
        const temp = [];
        currentItem.serviceDefinitions.forEach(newService => this._processService(result[category].serviceDefinitions, newService));
        result[category].serviceDefinitions = result[category].serviceDefinitions.concat(temp);
      }

      return result;
    }, {});

    return Object.values(groupedData).map(categoryOptions => new ServiceCategory(categoryOptions));
  }

  private static _processService(targetArray, service: ServiceDefinition) {
    const duplicateService = targetArray.find(s =>
      s.getServiceName() === service.getServiceName()
    );
    service.getStylist().setSelectedPrice(service.getPrice());
    service.getStylist().setSelectedDuration(service.getDuration());
    service.getStylist().setSelectedFinishedDuration(service.getFinishDuration());
    service.getStylist().setSelectedProcessingDuration(service.getProcessDuration());
    service.getStylist().setSelectedServiceID(service.getID());


    if (duplicateService) {
      const commonIds = duplicateService.getCommonStylists();

      if (
        duplicateService.getProcessDuration() > service.getProcessDuration()
      ) {
        duplicateService.setProcessDuration(service.getProcessDuration());
      }
      if (duplicateService.getPrice() > service.getPrice()) {
        duplicateService.setPrice(service.getPrice());
      }


      if (!commonIds && Number.isInteger(service.getStylist()?.getID())) {
        const commonStylist = new Set<Stylist>([service.getStylist()]);

        duplicateService.setCommonStylists(commonStylist);
      } else if (commonIds) {
        duplicateService.addToCommonStylists(service.getStylist());
      }
    } else {
      const commonStylist = new Set<Stylist>([service.getStylist()]);
      service.setCommonStylists(commonStylist);
      targetArray.push(service);
    }
  }
}