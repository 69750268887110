import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Client } from '../../shared/models/client.model';
import { Country } from '../../shared/models/country.model';
import { ClientService } from '../../shared/services/client.service';
import { UserService } from '../../shared/services/user.service';
import { CountryService } from '../../shared/services/country.service';
import { CustomValidator } from '../../shared/utilities/custom-validator';
import { ClientEmailChecker } from '../../shared/utilities/client-email-checker';
import { Utilities } from '../../shared/utilities/utilities';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { Pronoun } from '../../shared/models/pronoun.model';
import { List } from 'immutable';

@Component({
  selector: 'sm-client-details',
  templateUrl: 'client-details.component.html',
  styleUrls: ['client-details.component.scss']
})

export class ClientDetailsComponent implements OnInit {

  // @Input() client: Client;

  // @Output() clientSaved =  new EventEmitter<Client>();

  public client: Client;

  public isLoading: boolean;

  public errorOccurred: boolean;

  public submitted: boolean;

  public clientForm: FormGroup;
  public clientID: number;

  public firstName: FormControl;

  public lastName: FormControl;

  public email: FormControl;

  public phone1: FormControl;

  public phone2: FormControl;

  public workPh: FormControl;

  public address1: FormControl;

  public address2: FormControl;

  public city: FormControl;

  public province: FormControl;
  public pronouns: FormControl;
  public customerPronouns: FormControl;

  public postal: FormControl;

  public country: FormControl;

  public notes: FormControl;

  public pronounOptions: List<Pronoun>;

  public countries: Array<Country>;

  public saveButtonText: string;

  constructor(private clientService: ClientService,
    private countryService: CountryService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dialogsService: DialogsService,
    private router: Router,
    private clientEmailChecker: ClientEmailChecker) {
    this.client = this.userService.getUser();
    this.saveButtonText = 'Save';
    this.submitted = false;
    this.isLoading = true;
    this.countries = [];
    this.pronounOptions = List([
      new Pronoun('Please select', 0),
      new Pronoun('She/Her', 1),
      new Pronoun('He/Him', 2),
      new Pronoun('They/Them', 3),
      new Pronoun('Custom', 4)
    ]);
    // form controls
    this.firstName = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(30)]));
    this.lastName = new FormControl('', Validators.maxLength(35));
    this.email = new FormControl('', Validators.compose([Validators.required, Validators.maxLength(100), CustomValidator.validateEmail]), this.clientEmailChecker.clientEmailAvailabilityChecker());
    this.phone1 = new FormControl('', Validators.compose([Validators.maxLength(20), CustomValidator.validatePhone]));
    this.phone2 = new FormControl('', Validators.compose([Validators.maxLength(20), CustomValidator.validatePhone]));
    this.workPh = new FormControl('', Validators.maxLength(20));
    this.address1 = new FormControl('', Validators.maxLength(40));
    this.address2 = new FormControl('', Validators.maxLength(40));
    this.city = new FormControl('', Validators.maxLength(20));
    this.province = new FormControl('', Validators.maxLength(25));
    this.postal = new FormControl('', Validators.maxLength(25));
    this.country = new FormControl(null);
    this.notes = new FormControl('');
    this.pronouns = new FormControl(this.client.getPronoun() ? this.client.getPronoun() : '0');
    this.customerPronouns = new FormControl(
      this.client.getcustomPronouns(),
      Validators.maxLength(35)
    );

    this.clientForm = formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone1: this.phone1,
      phone2: this.phone2,
      workPh: this.workPh,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      province: this.province,
      postal: this.postal,
      country: this.country,
      pronouns: this.pronouns,
      customerPronouns: this.customerPronouns,
    }, { validator: CustomValidator.requireOnePhoneNumber });
  }

  public ngOnInit() {

    if (!this.userService.isLoggedIn()) {
      this.router.navigate(['/']);
      return;
    }

    this.loadPage();
  }

  public loadPage() {
    // load client info
    this.isLoading = true;
    this.errorOccurred = false;
    this.clientService.getClientInfo()
      .subscribe(
        (client: Client) => {

          this.client = client;
          this.userService.setUser(client);
          // localStorage.setItem('emailCheckerClientID', (this.client.getID()) ? this.client.getID() + '' : 'null');

          this.clientService.setEmailCheckerClientID(this.client.getID() ? this.client.getID() : undefined);
          this.firstName.setValue(this.client.getFirstName());
          this.lastName.setValue(this.client.getLastName());
          this.email.setValue(this.client.getEmail());
          this.phone1.setValue(this.client.getPhone1());
          this.phone2.setValue(this.client.getPhone2());
          this.workPh.setValue(this.client.getWorkph());
          this.address1.setValue(this.client.getAddress1());
          this.address2.setValue(this.client.getAddress2());
          this.city.setValue(this.client.getCity());
          this.province.setValue(this.client.getProvince());
          this.postal.setValue(this.client.getPostal());
          this.country.setValue((this.client.getCountry()) ? this.client.getCountry().getID() : null);
          this.pronouns.setValue(this.client.getPronoun());
          this.customerPronouns.setValue(this.client.getcustomPronouns());
          this.countryService.getCountries()
            .subscribe(
              countries => {
                this.countries = countries;
                this.isLoading = false;
                this.errorOccurred = false;
              },
              error => {
                this.isLoading = false;
                this.errorOccurred = true;
                this.dialogsService.errorAlert().subscribe();
              }
            );

        },
        (error) => {
          this.isLoading = false;
          this.errorOccurred = true;
          throw error;
          // this.dialogsService.errorAlert().subscribe();
        });
  }
  public viewChangePassword() {
    this.router.navigate(['/client/change-password']);
  }

  public onClientSave() {

    this.submitted = true;

    if (this.clientForm.valid === false) {
      return;
    }

    // update client data
    let newCountry: Country;

    for (const country of this.countries) {
      if (country.getID() === this.country.value) {
        newCountry = country.clone();
        break;
      }
    }

    const newClient = new Client({
      id: this.client.getID(),
      stylistID: this.client.getStylistID(),
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      phone1: this.phone1.value,
      phone2: this.phone2.value,
      workPh: this.workPh.value,
      address1: this.address1.value,
      address2: this.address2.value,
      city: this.city.value,
      province: this.province.value,
      postal: this.postal.value,
      country: newCountry,
      pronouns: this.pronouns.value,
      customPronouns: this.customerPronouns.value,
      notes: this.client.getNotes()
    });

    this.isLoading = true;
    this.clientService.updateClient(newClient)
      .subscribe(
        client => {
          this.userService.setUser(client);
          // this.clientSaved.emit(client);
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.dialogsService.errorAlert().subscribe();
        }
      );
  }

}
