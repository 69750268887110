<br />
  <div sm-center>
  <mat-card sm-restrict-width *ngIf='booking.getServices().length > 0'>
  <div sm-center>
    <mat-card-title>
     <mat-icon style='font-size:48px;'>done</mat-icon>
     <br /><br />
     <span *ngIf="booking.getStatus() === 11">Your waitlist request has been submitted</span>
     <span *ngIf="!requireApproval && booking.getStatus() != 11">Your booking has been completed</span>      
     <span *ngIf="requireApproval && booking.getStatus() != 11">Your booking has been requested</span>

    </mat-card-title>
  </div>
  <span *ngIf="requireApproval || booking.getStatus() === 11">We will review your request and send you an email confirmation shortly.</span>
  <h3 class="smc-booking-time" *ngif="booking.getStatus() === 11">
    {{ confirmationTitle }}
  </h3>
    <mat-list *ngif="booking.getStatus() === 11">
      <mat-list-item *ngFor="let service of booking.getServices()">
        <h3 mat-line>
          {{ service.getName() }} with {{ service.getStylist().getFirstName() }} {{ service.getStylist().getLastName() }}
        </h3>
        <p mat-line>
          {{ service.getServiceDefinition().getStartingAt() == 1 ? 'Starting at' : '' }} 
          {{ service.getServiceDefinition().getPrice() | salonMonsterCurrency }}
          {{ service.getStylist().getHideDurations !== 1 ? 
            ', about ' + (service.getDurations().startDuration + service.getDurations().processDuration + service.getDurations().finishDuration | SalonMonsterFormatDurationPipe) 
            : '' }} </p>
      </mat-list-item>
    </mat-list>
  </mat-card>
  <br/>

<!--   <div>
    Notes for your service provider
  </div>
 -->
  <br/>
<!--   <button
    mat-raised-button secondaryButton
    >
    Add it to your calendar
  </button> -->
  <br/>
  <button
    mat-raised-button
    (click)="newBookings()"
    class="smc-button-secondary"
    >
    Book another appointment
  </button>
  <br /><br />
</div>