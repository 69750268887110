<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

<salonmonster-tap-retry
  *ngIf="errorOccurred"
  (click)="loadPage()"
></salonmonster-tap-retry>

<div *ngIf="!isLoading && !errorOccurred">
  <sm-booking-details
    *ngIf="booking.getServices().length > 0"
    [booking]="booking"
    [title]="title"
    [fromSummary]="false"
    [subTitle]="subTitle"
    [hideNotesField]="true"
    (serviceDelete)="removeService($event)"
    [showDeleteButton]="true"
  >
  </sm-booking-details>

  <br />
  <div sm-center>
    <div *ngIf="!showStylistPicker">
      <button
        mat-raised-button
        *ngIf="booking.getServices().length > 0"
        (click)="onContinue()"
        class="smc-button-large smc-button-primary"
      >
        Pick a time
      </button>
      <span *ngIf="!isAnyService" class="smc-or">or</span>
      <button
        mat-raised-button
        *ngIf="!isAnyService"
        (click)="addAnotherService()"
        class="smc-button-large smc-button-secondary"
      >
        + Add Another Service
      </button>
    </div>
  </div>

  <!--   <mat-grid-list *ngIf="showStylistPicker" cols="3">
    <mat-grid-tile
        *ngFor="let stylist of stylists"
        [routerLink]="['/stylists', stylist.getID(), 'services']"
        [colspan]="1"
        [rowspan]="1">
        <mat-card >
          <img mat-card-avatar src='https://salonmonster.com/userfiles/profile_pics/{{stylist.getPhotoUrl()}}' />
          <mat-card-title>
           {{stylist.getFirstName()}} {{stylist.getLastName()}}
           </mat-card-title>
           <mat-card-subtitle>
            {{stylist.getBio()}}
            </mat-card-subtitle>
        </mat-card>    
    </mat-grid-tile>
  </mat-grid-list>
 -->

  <div *ngIf="showStylistPicker" fxLayout="wrap row" fxLayout.xs="column">
    <div
      *ngIf="showAnyProvider"
      fxFlex="25"
      fxFlex.sm="50"
      fxFlex.md="33.333"
      (click)="onStylistClick(null)"
    >
      <mat-card class="smc-stylist-any-provider" tappable>
        <mat-card-title class="smc-clickable-text">
          First Available
        </mat-card-title>
        <span class="smc-stylist-subtitle">(any provider)</span>
      </mat-card>
    </div>

    <div
      fxFlex="25"
      fxFlex.sm="50"
      fxFlex.md="33.333"
      *ngFor="let stylist of stylists"
      (click)="onStylistClick(stylist)"
    >
      <mat-card class="smc-stylist-card" tappable>
        <img
          mat-card-image
          *ngIf="
            stylist.getPhotoUrl() != undefined && stylist.getPhotoUrl() != ''
          "
          [alt]="stylist.getFirstName() + ' ' + stylist.getLastName()"
          src="https://salonmonster.com/userfiles/profile_pics/{{
            stylist.getPhotoUrl()
          }}"
        />
        <mat-card-title class="smc-clickable-text">
          {{ stylist.getFirstName() }} {{ stylist.getLastName() }}
        </mat-card-title>
        &nbsp;<span class="smc-stylist-title">{{ stylist.getTitle() }} </span>
        <mat-card-subtitle>
          {{ stylist.getBio() }}
        </mat-card-subtitle>
      </mat-card>
    </div>
  </div>
</div>
