<form [formGroup]='clientForm' (ngSubmit)="onClientSave()">
  <table width="100%">
    <!-- <div style="border: 1px black solid; width:100%"> -->
      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>First name</mat-label>
            <input matInput placeholder="First name" type="text" name='firstName' formControlName="firstName" clearInput />
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td *ngIf="!firstName.valid && submitted">
          <p *ngIf="firstName.hasError('required')" class='smr-error'>
            Oops, a first name is required.
          </p>

          <p *ngIf="firstName.hasError('maxlength')" class='smr-error'>
            A first name can't exceed 30 letters.
          </p>
        </td>
      </tr>
      
      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>Last name</mat-label>
            <input matInput placeholder="Last name" type="text" name='lastName' formControlName="lastName" clearInput />
          </mat-form-field>
        </td>
      </tr>
      
      <tr>
        <td  *ngIf="!lastName.valid && submitted">
          <p *ngIf="lastName.hasError('maxlength')" class='smr-error'>
            A last name can't exceed 35 letter.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" type="email" name='email' formControlName="email" clearInput />

            <mat-hint *ngIf='email.pending' align="end">
              Checking email availability...
            </mat-hint>

          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td  *ngIf="!email.valid">
          <p *ngIf="email.hasError('required') && submitted" class='smr-error'>
            Oops, an email is required.
          </p>

          <p *ngIf="email.hasError('validateEmail') && submitted" class='smr-error'>
            Oops, that email is invalid.
          </p>

          <p *ngIf="email.hasError('maxlength')" class='smr-error'>
            An email can't exceed 100 characters.
          </p>

          <p *ngIf="email.hasError('clientEmailAlreadyExists')" class='smr-error' (click)="onViewLogIn()" style="cursor:pointer;display:inline-block;">
            Email already in use. Please <span style="text-decoration:underline">log in</span> instead.
          </p>
        </td>
      </tr>
    <!-- </div> -->

    <tr>
      <td>
        <mat-form-field sm-full-width appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" type="password" name='password' formControlName="password" clearInput />
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td  *ngIf="reqPass && !password.valid && submitted">
        <p *ngIf="password.hasError('required')" class='smr-error'>
          Oops, an password is required.
        </p>

        <p *ngIf="password.hasError('maxlength')" class='smr-error'>
          A password can't exceed 25 characters.
        </p>
      </td>
    </tr>

    <tr>
      <td>
        <mat-form-field sm-full-width appearance="outline">
          <mat-label>Mobile phone</mat-label>
          <input matInput placeholder="Mobile Phone" type="tel" name='phone2' formControlName="phone2" clearInput />
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td *ngIf="!phone2.valid && submitted">

        <p *ngIf="phone2.hasError('required')" class='smr-error'>
          Oops, a mobile phone is required.
        </p>

        <p *ngIf="phone2.hasError('maxlength')" class='smr-error'>
          Oops, this can't be longer than 20 characters.
        </p>
        <p *ngIf="phone2.hasError('validatePhone')" class='smr-error'>
          Oops, this phone is invalid.
        </p>
      </td>
    </tr>

    <div *ngIf="reqAddress">

      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>Address 1</mat-label>
            <input matInput placeholder="Address 1" type="text" name='address1' formControlName="address1" />
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td *ngIf="!address1.valid && submitted">
          <p *ngIf="address1.hasError('maxlength')" class='smr-error'>
            Oops, this can't be longer than 40 characters.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>Address 2</mat-label>
            <input matInput placeholder="Address 2" type="text" name='address2' formControlName="address2" />
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td *ngIf="!address2.valid && submitted">
          <p *ngIf="address2.hasError('maxlength')" class='smr-error'>
            Oops, this can't be longer than 40 characters.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>City</mat-label>
            <input matInput placeholder="City" type="text" name='city' formControlName="city" />
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td *ngIf="!city.valid && submitted">
          <p *ngIf="city.hasError('maxlength')" class='smr-error'>
            Oops, this can't be longer than 20 characters.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>State / Province</mat-label>
            <input matInput placeholder="Province" type="text" name='province' formControlName="province" />
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td *ngIf="!province.valid && submitted">
          <p *ngIf="province.hasError('maxlength')" class='smr-error'>
            Oops, this can't be longer than 25 characters.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <mat-form-field sm-full-width appearance="outline">
            <mat-label>ZIP / Postal code</mat-label>
            <input matInput placeholder="Postal" type="text" name='postal' formControlName="postal" />
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td *ngIf="!postal.valid && submitted">
          <p *ngIf="postal.hasError('maxlength')" class='smr-error'>
            Oops, this can't be longer than 25 characters.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <mat-select placeholder="Select a Country" name="country" formControlName="country">
            <mat-label>Country</mat-label>
            <mat-option *ngFor="let country of countries" [value]="country.getID()">
              {{country.getCountryName()}}
            </mat-option>
          </mat-select>
        </td>
      </tr>
    </div>
  </table>
  <br />
  <div>
    <button mat-raised-button primaryButton sm-full-width type="submit" >Sign Up</button>
  </div>
</form>