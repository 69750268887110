import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Booking } from '../../models/booking.model';
import { Service, TimeSlotDurationOptions } from '../../models/service.model';
import { StylistService } from '../../services/stylist.service';
import { UserService } from '../../services/user.service';
import { Stylist } from '../../models/stylist.model';

@Component({
  selector: 'sm-booking-summary-list',
  templateUrl: './booking-summary-list.component.html',
  styleUrls: ['./booking-summary-list.component.css']
})
export class BookingSummaryListComponent implements OnInit {
  @Input() booking: Booking;

  @Output() serviceDelete = new EventEmitter<Service>();

  public formattedDate: string;
  public selectedStylist: Stylist;
  public filteredStylists: Stylist[];


  constructor(
    private stylistService: StylistService,
    private userService: UserService,
  ) { }
  ngOnInit() {
    const originalDate = new Date(this.booking.getStartDateTime());

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    this._filterStylists();
    this.formattedDate = originalDate.toLocaleDateString('en-US', options);
    if(this.isSingleItem() == true) 
    {
      this.selectedStylist = this.filteredStylists[0];
    }
    
  }

  public removeService(service: Service) {
    this.serviceDelete.emit(service);
  }
  public onCheckboxChange(stylist: Stylist) {
    const TimeSlotDurationOptions: TimeSlotDurationOptions = 
    {
      startDuration: stylist.getSelectedDuration(),
      processDuration: stylist.getSelectedProcessingDuration(),
      finishDuration: stylist.getSelectedFinishedDuration(),
    }
    this.selectedStylist = stylist;
    this.booking.getServices()[0]?.getServiceDefinition().setStylist(stylist);
    this.booking.getServices()[0]?.setStylist(stylist);

    this.booking.getServices()[0]?.getServiceDefinition().setID(stylist.getSelectedServiceID());
    this.booking.getServices()[0]?.setDurations(TimeSlotDurationOptions);
    this.booking.getServices()[0]?.setPrice(stylist.getSelectedPrice());
  }
  public isSingleItem(): boolean {
    return this.filteredStylists.length === 1;
  }
  private _filterStylists() {
    this.filteredStylists = this.booking.getServices()
      .reduce((acc : Stylist [], service) => {
        acc = acc.concat(Array.from(service.getServiceDefinition().getCommonStylists()));
        return acc;
      }, [])
      .filter(stylist => this.booking.getAvailableStylistIDs().includes(stylist.getID()));
  }
}
