<mat-card style=" " class="mat-card-selected-summary" sm-restrict-width >
    <div sm-center>
      <mat-card-title>{{ title }}</mat-card-title>
      <h3 *ngIf="subTitle">{{ subTitle }}</h3>
    </div>
    <mat-list>
      <mat-list-item *ngFor="let service of booking.getServices()" >
        <img mat-list-avatar *ngIf="service.getStylist()?.getPhotoUrl() != undefined && service.getStylist()?.getPhotoUrl() != ''" [alt]="service.getStylist()?.getFirstName() + ' ' + service.getStylist()?.getLastName()" src='https://salonmonster.com/userfiles/profile_pics/{{service.getStylist()?.getPhotoUrl()}}' />
        <h3 mat-line>
            {{ service.getName() }} with <span *ngIf="service.getStylist()"> {{ service.getStylist().getFirstName() }} {{ service.getStylist().getLastName() }}</span>
          </h3>
          <p mat-line>
            {{ service.getServiceDefinition().getStartingAt() == 1 ? 'Starting at' : '' }} 
            {{ service.getServiceDefinition().getPrice() | salonMonsterCurrency }}
            {{ 
              ', about ' + (service.getDurations().startDuration + service.getDurations().processDuration + service.getDurations().finishDuration | SalonMonsterFormatDurationPipe) 
               }}          </p>
        <!-- <img mat-list-avatar *ngIf="service.getStylist()?.getPhotoUrl() != undefined && service.getStylist()?.getPhotoUrl() != ''" [alt]="service.getStylist()?.getFirstName() + ' ' + service.getStylist()?.getLastName()" src='https://salonmonster.com/userfiles/profile_pics/{{service.getStylist()?.getPhotoUrl()}}' />     
       <h3 mat-line>
          {{ service.getName() }} with <span *ngIf="service.getStylist()"> {{ service.getStylist().getFirstName() }} {{ service.getStylist().getLastName() }}</span>
        </h3>
        <p mat-line>
          {{ service.getServiceDefinition().getStartingAt() == 1 ? 'starting at' : ''}} {{ service.getServiceDefinition().getPrice() | salonMonsterCurrency }}, about {{ (service.getDurations().startDuration + service.getDurations().processDuration + service.getDurations().finishDuration) }} minutes
        </p>
  
        <button mat-icon-button (click)=removeService(service) *ngIf="showDeleteButton == true">
          <mat-icon>close</mat-icon>
        </button> -->
      </mat-list-item>
    </mat-list>
  </mat-card>