<h2>Please select a service :</h2>
<mat-accordion style="width: 100%;">
    <ng-container *ngFor="let serviceCategory of serviceCategories">
      <ng-container *ngIf="serviceCategory.getBookableServiceDefinitions().length > 0">
        <mat-expansion-panel class="custom-panel" #panel [expanded]="serviceCategories.length === 1">
            <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ serviceCategory.categoryName }}
                </mat-panel-title>
                <mat-icon class="arrow-icon">{{ panel?.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
              </mat-expansion-panel-header>
  
          <ng-container *ngFor="let serviceDefinition of serviceCategory.serviceDefinitions">
            <mat-list-item class="smc-service-list-item" (click)="serviceSelect(serviceDefinition)" style="border-bottom: 1px solid #ccc;">              
              <h5 class='smc-text-margin smc-clickable-text' tappable mat-line>
                {{serviceDefinition.serviceName}}
              </h5>
              <div class="smc-text-margin safe" mat-line *ngIf="serviceDefinition.getDescription() !== 'undefined'" [innerHtml]="serviceDefinition.getDescription() | safe: 'html'"></div>
              <p mat-line class='smc-text-margin smc-service-details'>
                {{ serviceDefinition.getStartingAt() == 1 ? 'Starting at' : ''}}
                 {{ serviceDefinition.getPrice() | salonMonsterCurrency }}
                 {{
                  ', about ' + (serviceDefinition.getDuration() + serviceDefinition.getProcessDuration() + serviceDefinition.getFinishDuration() | SalonMonsterFormatDurationPipe) 
                   }}
              </p>
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
  
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  
  </mat-accordion>
  