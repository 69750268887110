import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private showFooterSubject = new BehaviorSubject<boolean>(true);
  showFooter$: Observable<boolean> = this.showFooterSubject.asObservable();

  toggleFooterVisibility(show: boolean): void {
    this.showFooterSubject.next(show);
  }
}