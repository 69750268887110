import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Utilities } from '../../shared/utilities/utilities';
import { UserService } from '../../shared/services/user.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { SalonService } from '../../shared/services/salon.service';
import {Pipe, PipeTransform} from '@angular/core';

@Component({
  // selector: 'sm-home',
  templateUrl: 'home-page.component.html',
  styleUrls: ['home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HomePageComponent implements OnInit {

  public homeHtml: SafeHtml;
  public logoUrl: string;

    images = [1, 2, 3].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);


  constructor (private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private userBookingService: UserBookingService,
    private router: Router) {

    // src: https://stackoverflow.com/questions/36101756/angular2-routing-with-hashtag-to-page-anchor
    // router.events.subscribe(s => {
    //   if (s instanceof NavigationEnd) {
    //     const tree = router.parseUrl(router.url);
    //     if (tree.fragment) {
    //       const element = document.querySelector("#" + tree.fragment);
    //       if (element) { element.scrollIntoView(element); }
    //     }
    //   }
    // });
  }


  public ngOnInit () {
    if(this.userService.getSalon().getShowHome() === 1){
      this.homeHtml = this.userService.getSalon().getHomeHtml();    
    } else {
      this.router.navigate(['/bookings']);
    }
    this.logoUrl = "https://salonmonster.com/userfiles/logos/" + this.userService.getSalon().getLogoUrl();
  }
}
