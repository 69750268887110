import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms'

import { Salon } from '../../shared/models/salon.model';
import { Utilities } from '../../shared/utilities/utilities';
import { UserService } from '../../shared/services/user.service';
import { SalonService } from '../../shared/services/salon.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sm-contact',
  templateUrl: 'contact-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPageComponent implements OnInit {

  public salon: Salon;

  public companyFullAddress: string;

  public emailFirstPart: string;

  public emailSecondPart: string;

  public isLoading: boolean;

  public anErrorOccurred: boolean;

  public messageSent: boolean;

  public submitAttempt: boolean;

  public googleMapsApi: string;  

  public contactForm: FormGroup;

  public clientEmailField: FormControl;

  public contactMessage: FormControl;

  constructor (private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private userBookingService: UserBookingService,
    private salonService: SalonService,
    private dialogsService: DialogsService,
    private router: Router,
    private formBuilder: FormBuilder) {
    this.salon = this.userService.getSalon();

    if (!this.salon) {
      this.router.navigate(['/']);
      return;
    }

    if (this.salon.getCity()) {
      this.companyFullAddress = `${this.salon.getAddress1()} ${this.salon.getAddress2()} ${this.salon.getCity()} ${this.salon.getProvince()} ${this.salon.getPostal()} ${this.salon.getCountry() ? this.salon.getCountry().getCountryName() : ''}`;
      this.companyFullAddress = this.companyFullAddress.replace(' ', '+');
    }

    if (this.salon.getEmail()) {
      this.emailFirstPart = this.salon.getEmail().substr(0, this.salon.getEmail().indexOf('@'));
      this.emailSecondPart = this.salon.getEmail().substr(this.salon.getEmail().indexOf('@') + 1); 
    }

    this.googleMapsApi = environment.GOOGLE_MAPS_API;

    this.clientEmailField = new FormControl('', [Validators.required, Validators.email]);
    this.contactMessage = new FormControl('', [Validators.required]);
    this.contactForm = this.formBuilder.group({
      clientEmailField: this.clientEmailField,
      contactMessage: this.contactMessage
    });
  }

  public ngOnInit () {}

  public onSendMessage () {
    this.submitAttempt = true;

    if (!this.contactForm.valid) {
      return;
    }

    this.isLoading = true;
    this.anErrorOccurred = false;
    this.salonService.contactSalon(this.contactMessage.value, this.clientEmailField.value)
      .subscribe((success) => {
        this.isLoading = false;
        this.messageSent = true;
        this.changeDetectorRef.markForCheck();
      },
      (err) => {
        this.isLoading = false;
        this.anErrorOccurred = true;
        this.changeDetectorRef.markForCheck();
        this.dialogsService.errorAlert().subscribe();
      });
  }

}
