import { Injectable }     from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable }     from 'rxjs';

import { Stylist } from '../models/stylist.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { environment } from '../../../environments/environment';
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class StylistService extends SalonmonsterHttpClient {

  private stylists: Array<Stylist>;

  constructor (http: HttpClient,
    private errorHandlerService: ErrorHandlerService) {
    super(http);
    this.stylists = [];
  }

  public getStylists (salonID: number): Observable<Array<Stylist>> {
    const url = `${environment.API_ROOT}/salons/${salonID}/stylists?calendar=1&includeServices=true&active=1`;

    return new Observable<Array<Stylist>>((observer) => {
      if (this.stylists.length === 0) {
        return this.get(url)
          .pipe(
            map((res: any) => {
              let body = res;

              const data = body.data;
              const stylists: Array<Stylist> = [];
              for (let stylistData of data) {
                stylists.push(Stylist.parseStylistData(stylistData));
              }

              this.stylists = stylists;
              return this.stylists;
            })
          )
          .subscribe((stylists) => {
            observer.next(stylists);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });

      } else {
        observer.next(this.stylists);
        observer.complete();
      }
    });

  }

}