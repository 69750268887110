<mat-card *ngIf="messageSent">
  Your message has been sent.
</mat-card>

<mat-card *ngIf='anErrorOccurred'>
  Your messaged failed to send.
</mat-card>

<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>

<div sm-restrict-width sm-center *ngIf="!messageSent && !isLoading">
  <mat-card-title sm-center>
    <h3>{{ salon.getName() }} is located at:</h3>
  </mat-card-title>

  <mat-card-content sm-center>
    <br />
      {{ salon.getAddress1() }}
    <br />
    {{ salon.getAddress2() ? salon.getAddress2() : '' }} 
    <br/>
    {{ salon.getCity() ? salon.getCity() : '' }} {{ salon.getProvince() ? salon.getProvince() : '' }} {{ salon.getPostal() ? salon.getPostal() : '' }}
    <br/>
    {{ salon.getCountry() ? salon.getCountry().getCountryName() : '' }}
    <br /><br />
  </mat-card-content>
  
  <a href="https://maps.google.com/maps?q={{companyFullAddress}}">
    <img mat-card-image src="http://maps.google.com/maps/api/staticmap?center={{companyFullAddress}}&zoom=14&size=350x350&maptype=roadmap&markers=color:blue%7C{{companyFullAddress}}&sensor=false&key={{googleMapsApi}}" />
  </a>

  <br />
  <br />

  <p *ngIf="salon.getPhone()">
    Contact us by phone at <em> {{ salon.getPhone() }} </em>
  </p>

  <p *ngIf="emailFirstPart && emailSecondPart">
    Email us at <em>{{ emailFirstPart }}<img src="./assets/images/atserver.png" style="padding:2px;vertical-align:middle;">{{ emailSecondPart }}</em>
  </p>

  <p>
    or send us a message below
  </p>

  <form [formGroup]="contactForm">
    <mat-form-field appearance="outline">
      <mat-label>Your email *</mat-label>
      <input matInput type='text' formControlName="clientEmailField" />
      <mat-error *ngIf="clientEmailField.hasError('required')">
        Oops, an email is required.
      </mat-error>
      <mat-error *ngIf="!clientEmailField.hasError('required') && clientEmailField.hasError('email')">
        Oops, your email is invalid.
      </mat-error>
    </mat-form-field>

    <!--div *ngIf="!clientEmailField.valid && submitAttempt">
      <p *ngIf="clientEmailField.hasError('required')" class='smr-error'>
        Oops, an email is required.
      </p>
      <p *ngIf="!clientEmailField.hasError('required') && clientEmailField.hasError('email')" class='smr-error'>
        Oops, your email is invalid.
      </p>
    </div-->

    <br/><br/>

    <mat-form-field appearance="outline">
      <mat-label>Your Message *</mat-label>
      <textarea matInput rows="3" cols="50" placeholder="Your message..." formControlName="contactMessage"
      ></textarea>
      <mat-error *ngIf="contactMessage.hasError('required')" class='smr-error'>
        Oops, a message is required.
      </mat-error>
    </mat-form-field>

    <!--div *ngIf="!contactMessage.valid && submitAttempt">
      <p *ngIf="contactMessage.hasError('required')" class='smr-error'>
        Oops, a message is required.
      </p>
    </div-->

    <br/>
    <br/>

    <div sm-center>
      <button
        mat-raised-button
        primaryButton      
        (click)="onSendMessage()"
        >
          Send it
      </button>
    </div>
  </form>
</div>

