<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>
<mat-tab-group [(selectedIndex)]="tabIndex"  *ngIf="!isLoading" class="sm-login-tabs">
  <mat-tab label="Log in">
    <div class='login-container'>
      <mat-card *ngIf="loginError" class='smr-error-card'>
        <mat-card-content>
          Oops, your email or password is incorrect
          <br/>
        </mat-card-content>
      </mat-card>
      <form [formGroup]='clientForm' (ngSubmit)="onClientLogin($event)">
        <h2>Log in</h2>
        <mat-form-field sm-full-width appearance="outline">
          <mat-label>Your email</mat-label>
          <input matInput placeholder="Your email" type="text" name='email' formControlName="email" />
        </mat-form-field>
        
        <br/>

        <div *ngIf='!email.valid && submitted' class='sm-validation-error'>
          <p *ngIf="email.hasError('required')">
           &uarr; Please enter an email
          </p>
          <p *ngIf="email.hasError('validateEmail')">
           &uarr; Please enter a valid email
          </p>
        </div>

        <mat-form-field sm-full-width appearance="outline">
          <mat-label>Your password</mat-label>
          <input matInput placeholder="Your password" type="password" name='password' formControlName="password" />
          <mat-hint>If you have one set</mat-hint>
        </mat-form-field>
        <br />
        <!-- <div *ngIf='!password.valid && submitted' class='sm-validation-error'>
          <p *ngIf="password.hasError('required')">
           &uarr; Please enter your password
          </p>
        </div> -->

        <br/><br/>

        <div style='text-align: center;'>
          <button mat-raised-button primaryButton sm-full-width type="submit" (click)="onClientLogin($event)">
            Log in
          </button>
          <br />
          <br />            
<!--          <ng-container *ngIf="allowFBLogin"> -->
            <!-- <span style="display:block;margin: .7em;">or</span> -->
<!--            <button mat-raised-button type="button" style="background: #4267b2; color: white;" sm-full-width (click)="onFacebookLogin()">
              Log in with Facebook
            </button>
          </ng-container>
          <br />
          <br />   -->
          <button
            mat-button
            sm-full-width
            *ngIf="allowSignUp"
            class='smc-full-width smc-button-secondary'
            (click)="showSignup()"
            >
            New here? Sign up.
          </button>
          <br />
          <br />                  
          <button
            mat-button
            class='smc-full-width'
            (click)="forgotPassword()"
            >
            Forgot your password?
          </button>
          <br />
          <br />             
          <button
            mat-button
            class='smc-full-width'
            (click)="staffLogin()"
            >
            Staff login
          </button>        
          <br/>
          <br/>
          <br/>
        </div>
      </form>
      
      <!-- Facebook Login button -->
  <!--     <div class="fb-login-button" scope="public_profile,email" onlogin="checkLoginState()" data-max-rows="1" data-size="large" data-show-faces="true" data-auto-logout-link="false">
        <button>Log in with Facebook</button>
      </div> -->

    </div>
  </mat-tab>
  <mat-tab label="Sign up" *ngIf="allowSignUp">
    <div class='login-container'>
      <h2>Sign up</h2>
      <client-sign-up (viewLogIn)="showLogin()" (signUp)="onSignUp($event)"></client-sign-up>
    </div>
  </mat-tab>
</mat-tab-group>