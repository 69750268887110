import { UserService } from '../services/user.service';
import * as Sentry from "@sentry/angular";
export class ErrorHandlerService {

  constructor(
    // private userService: UserService
  ) { }

  public handleError(error: any): any {
    Sentry.captureException(error.message);

    if (!error) {
      return error;
    }


    if (error['status'] === 401) {
      // this.userService.logout();
    }

    return error;
  }
}