import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params} from '@angular/router';
import { Location } from '@angular/common';

import { mergeMap, filter, map, switchMap } from 'rxjs/operators';

import { Client } from '../../shared/models/client.model';
import { Utilities } from '../../shared/utilities/utilities';
import { ClientService } from '../../shared/services/client.service';
import { UserService } from '../../shared/services/user.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';

@Component({
  selector: 'sm-client-quicklogin',
  templateUrl: 'client-quicklogin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientQuickloginComponent implements OnInit {
  
  public isLoading: boolean;

  public errorOccurred: boolean;

  constructor (private clientService: ClientService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogsService: DialogsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {
    this.isLoading = true;
  }

  public ngOnInit () {
    this.userService.logout();
    this.processQuickLogin();
  }

  public processQuickLogin () {
    this.isLoading = true;
    this.errorOccurred = false;
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          const quickLoginKey: string = params['quickLoginKey'];
          const salonID: number = this.userService.getSalon().getId();
          return this.clientService.quickLogin(quickLoginKey, salonID);
        })
      )
      .subscribe((response) => {
        // this.isLoading = false;
        // this.errorOccurred = false;

        this.userService.login(response.user, response.token);
        this.changeDetectorRef.markForCheck();
        this.router.navigate(['client/bookings']);
      },
      (err) => {
        this.isLoading = false;
        this.errorOccurred = true;
        this.router.navigate(['/']);
      });

  }


}
