import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Booking } from '../../shared/models/booking.model';
import { UserService } from '../../shared/services/user.service';
import { SalonService } from '../../shared/services/salon.service';
import { UserBookingService } from '../../shared/services/user-booking.service';
import { IDateRange } from '../shared/components/calendar/calendar.component';
import { Utilities } from '../../shared/utilities/utilities';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { List, fromJS } from 'immutable';
import { throwError } from 'rxjs';
import { Stylist } from '../../shared/models/stylist.model';

@Component({
  templateUrl: 'stylist-availability.component.html',
  styleUrls: ['./stylist-availability.component.scss']
})
export class StylistAvailabilityComponent implements OnInit {

  public booking: Booking;

  public morning: Array<Date>;

  public afternoon: Array<Date>;

  public evening: Array<Date>;

  public isLoading: boolean;

  public anErrorOccurred: boolean;

  public disabledDates: List<number>;
  public currentDate: Date;
  public firstAvailableDate: Date;
  public availabilities: Object;

  public selectedDate: Date;

  public dayHasAvailability: boolean;
  public year: number;

  public month: number;

  public showWaitList: boolean;
  public isAnyService: boolean = false;
  public stylistIDbySlots;


  constructor(private salonService: SalonService,
    private userService: UserService,
    private userBookingService: UserBookingService,
    private dialogsService: DialogsService,
    private router: Router,
    private route: ActivatedRoute) {
    this.morning = [];
    this.afternoon = [];
    this.evening = [];
    this.isLoading = true;
    this.booking = this.userBookingService.getBooking();
    this.availabilities = {};
    this.dayHasAvailability = false;
  }

  public ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.isAnyService = params['any'];
    });
    if (this.booking.getServices().length === 0) {
      this.router.navigate(['/']);
      return;
    }

    this.showWaitList = false;
    for (var service of this.booking.getServices()) {

      if (service.getStylist()?.getLetClientsWaitList() === 1) {
        this.showWaitList = true;
      }
    }
    this.currentDate = new Date();
  }




  public rangeChanged(range: IDateRange) {
    this.year = range.start.getFullYear();
    this.month = range.start.getMonth() + 1;
    this.loadCalendar();
  }

  public loadCalendar() {
    this.availabilities = {};
    this.morning = [];
    this.afternoon = [];
    this.evening = [];
    this.isLoading = true;
    this.anErrorOccurred = false;
    if (this.isAnyService) {
      const selectedStylistIDs = this.booking.getServices().map((service) => {
        return Array.from(service.getServiceDefinition().getCommonStylists());
      }).reduce((acc, current) => acc.concat(current), []);
      this.salonService.getStylistsAvailabilities(selectedStylistIDs.map(x => x.getID()), this.year + '', this.month + '')
        .subscribe((res) => {
          this.stylistIDbySlots = res;
          const availabilitiesByServices = {};




          for (const service of this.booking.getServices()) {



            for (const day in res) {
              if (!(day in availabilitiesByServices)) {
                availabilitiesByServices[day] = {};
              }

              let stylistStartTimes = {};
              service.getServiceDefinition().getCommonStylists().forEach(stylist => {
                stylistStartTimes[stylist.getID()] = null;
              });
              this._getStartTime(res[day], stylistStartTimes);
              for (const timeslot in res[day]) {
                let isAnyStylistAvailable = false;
                service.getServiceDefinition().getCommonStylists().forEach(stylist => {
                  const stylistStartTime = stylistStartTimes[stylist.getID()];
                  if (!stylistStartTime) {
                    return;
                  }

                  const stylistStartTimeMinutes = parseInt(stylistStartTime.split(':')[0]) * 60 + parseInt(stylistStartTime.split(':')[1]);
                  const currentItemTime = new Date(`2000-01-01T${timeslot}`);
                  const currentItemTimeMinutes = currentItemTime.getHours() * 60 + currentItemTime.getMinutes();


                  const duration = stylist.getSelectedDuration() + stylist.getSelectedFinishedDuration() + stylist.getSelectedProcessingDuration();

                  let consecutiveAvailability = true;

                  if ((currentItemTimeMinutes - stylistStartTimeMinutes) % stylist.getBookingInterval() === 0) {
                    for (let i = 0; i < duration; i += 15) {
                      const intervalTime = new Date(currentItemTime.getTime() + i * 60 * 1000);
                      const hours = ('0' + intervalTime.getHours()).slice(-2);
                      const minutes = ('0' + intervalTime.getMinutes()).slice(-2);
                      const intervalTimeString = `${hours}:${minutes}`;

                      if (!res[day][intervalTimeString] || !res[day][intervalTimeString].includes(stylist.getID())) {
                        consecutiveAvailability = false;
                        break;
                      }
                    }
                  } else {
                    consecutiveAvailability = false;
                  }

                  if (consecutiveAvailability) {
                    isAnyStylistAvailable = true;
                  }
                });

                const isStylistAvailable = isAnyStylistAvailable;
                if (!(timeslot in availabilitiesByServices[day])) {
                  availabilitiesByServices[day][timeslot] = [];
                }

                availabilitiesByServices[day][timeslot].push(isStylistAvailable);
              }
            }
          }
          for (const timeSlot in availabilitiesByServices) {
            for (const item in availabilitiesByServices[timeSlot]) {
              const availabilityArray = availabilitiesByServices[timeSlot][item];
              const allTrue = availabilityArray.every(value => value === true);
              availabilitiesByServices[timeSlot][item] = allTrue;
            }
            const timeSlotData = availabilitiesByServices[timeSlot];
            const allFalse = Object.values(timeSlotData).every(value => value === false);
            availabilitiesByServices[timeSlot] = allFalse ? null : timeSlotData;
          }
          this._handleCalendar(availabilitiesByServices);
        }, (error) => {
          this.isLoading = false;
          this.anErrorOccurred = true;
          throw error;
        }
        )
    }
    else {
      this.salonService.getAvailabilities(this.booking, this.year + '', this.month + '')
        .subscribe(
          (availabilities) => {
            this._handleCalendar(availabilities);
          },
          (error) => {
            this.isLoading = false;
            this.anErrorOccurred = true;
            // this.dialogsService.errorAlert().subscribe();
            throw error;
          }
        );
    }
  }

  private _getStartTime(timeSlot, startTimeObject) {
    startTimeObject = Object.entries(timeSlot).forEach(([item, stylistIDs]) => {
      if (Array.isArray(stylistIDs)) {
        stylistIDs.forEach((stylistID) => {
          if (!startTimeObject[stylistID]) {
            startTimeObject[stylistID] = item;
          }
        });
      }
    });
  }

  public onCellSelected(date: Date) {
    const day: string = date.getDate() + '';
    this.morning = [];
    this.afternoon = [];
    this.evening = [];
    this.selectedDate = date;

    if (this.availabilities[day]) {
      const dayAvailabilities = this.availabilities[day];
      for (let timestamp in dayAvailabilities) {

        if (!dayAvailabilities[timestamp]) {
          continue;
        }

        let startTime: Date = Utilities.parseDate(`${Utilities.formatDate(date, 'Y-MM-DD')} ` + timestamp + ':00');

        // let startTime: Date = new Date(parseInt(timestamp, 10) * 1000);

        // let startTime: Date = Utilities.unixToDate(parseInt(timestamp, 10));
        // startTime = Utilities.convertTZ(startTime, this.userService.getSalon().getTimeZone());

        let hour = startTime.getHours();
        if (hour < 12) {
          this.morning.push(startTime);
        } else if (hour >= 12 && hour < 18) {
          this.afternoon.push(startTime);
        } else {
          this.evening.push(startTime);
        }
        this.morning.sort();
        this.afternoon.sort();
        this.evening.sort();
        this.dayHasAvailability = this.morning.length > 0 || this.afternoon.length > 0 || this.evening.length > 0;
      }
    } else {
      this.dayHasAvailability = false;
    }

  }

  public onTimeSlotSelect(date: Date) {
    if (this.isAnyService) {
      const dayOfMonth = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      if (dayOfMonth in this.stylistIDbySlots) {
        const dayData = this.stylistIDbySlots[dayOfMonth];
        const hoursString = hours < 10 ? '0' + hours : hours.toString();
        const timeSlotKey = `${hoursString}:${minutes < 10 ? '0' : ''}${minutes}`;
        const allStylistIDs = dayData[timeSlotKey];
        const stylistIDs = allStylistIDs.filter(stylistID => {
          const stylist = this.booking.getServices()[0].getServiceDefinition().getCommonStylists();
          const foundStylist = Array.from(stylist).find(stylist => stylist.getID() === stylistID);
          const duration = foundStylist.getSelectedDuration() + foundStylist.getSelectedFinishedDuration() + foundStylist.getSelectedProcessingDuration();
          return this._isStylistAvailableForDuration(stylistID, duration, timeSlotKey, dayData, foundStylist.getBookingInterval());
        });

        this.booking.setAvailableStylistIDs(stylistIDs);
      }
      else {
        this.anErrorOccurred = false;
      }
    }
    else {
      this.booking.setAvailableStylistIDs(null);

    }
    this.booking.setStartDateTime(date);
    this.router.navigate(['/bookings/summary']);
  }

  public addToWaitList() {
    this.router.navigate(['bookings/waitlist']);
    this.booking.setStatus(11);
  }
  private _handleCalendar(availabilities) {
    const dates: Array<number> = [];
    let firstAvailableDay: number = 0;

    // Get the current date
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    for (let day in availabilities) {
      // get the date of the month we are currently looping through  
      const currentDateParsed = new Date(`${this.year}-${this.month}-${day} 00:00:00`);
      const dayInt = parseInt(day, 10);

      // Check if the date is in the past or has no availability
      if (currentDateParsed < currentDate || availabilities[day] == null) {
        dates.push(dayInt);
      } else {
        // set first available day
        if (firstAvailableDay == 0) {
          firstAvailableDay = dayInt;
          //this.firstAvailableDate = Utilities.parseDate(`${this.year}-${this.month}-${firstAvailableDay} 00:00:00`);
          //  console.log(this.firstAvailableDate + '!@#');
        }
      }
    }

    this.disabledDates = fromJS(dates);
    this.availabilities = availabilities;
    this.isLoading = false;
    this.anErrorOccurred = false;
    if (firstAvailableDay) {
      this.firstAvailableDate = Utilities.parseDate(`${this.year}-${this.month}-${firstAvailableDay} 00:00:00`);
      this.onCellSelected(this.firstAvailableDate);
    }
  }

  private _isStylistAvailableForDuration(stylistID: string, duration: number, timeSlotKey: string, res: any, bookingInterval: number): boolean {
    const itemTime = new Date(`2000-01-01T${timeSlotKey}`);
    let consecutiveAvailability = true;

    let stylistStartTimes = {};
    stylistStartTimes[stylistID] = null;

    Object.entries(res).forEach(([item, stylistIDs]) => {
      if (Array.isArray(stylistIDs)) {
        stylistIDs.forEach((stylistID) => {
          if (!stylistStartTimes[stylistID]) {
            stylistStartTimes[stylistID] = item;
          }
        });
      }
    });
    const stylistStartTimeMinutes = parseInt(stylistStartTimes[stylistID].split(':')[0]) * 60 + parseInt(stylistStartTimes[stylistID].split(':')[1]);
    const selectedTime = new Date(`2000-01-01T${timeSlotKey}`);
    const selectedTimeMinutes = selectedTime.getHours() * 60 + selectedTime.getMinutes();
    if ((selectedTimeMinutes - stylistStartTimeMinutes) % bookingInterval !== 0) {
      return false;
    }

    for (let i = 0; i < duration; i += 15) {
      const intervalTime = new Date(itemTime.getTime() + i * 60 * 1000);
      const hours = ('0' + intervalTime.getHours()).slice(-2);
      const minutes = ('0' + intervalTime.getMinutes()).slice(-2);
      const intervalTimeString = `${hours}:${minutes}`;

      // Check if the time slot exists in the response
      if (!res[intervalTimeString]) {
        consecutiveAvailability = false;
        break;
      }

      // Check if the interval time exists in the response
      if (!res[intervalTimeString]) {
        consecutiveAvailability = false;
        break;
      }

      // Check if the stylistID exists in the array for the interval time
      if (!res[intervalTimeString].includes(stylistID)) {
        consecutiveAvailability = false;
        break;
      }
    }

    return consecutiveAvailability;
  }

}
