import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { UserService } from '../../shared/services/user.service';
import { BookingStatus, UserBookingService } from '../../shared/services/user-booking.service';
import { BookingService } from '../../shared/services/booking.service';
import { NotificationService, NOTIFICATION_TYPE } from '../../shared/services/notification.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';
import { Booking } from '../../shared/models/booking.model';
import { Client } from '../../shared/models/client.model';
import { StripeService } from '../../shared/services/stripe.service';
import { StylistService } from '../../shared/services/stylist.service';
@Component({
  templateUrl: 'wait-list.component.html',
  styleUrls: ['wait-list.component.scss']
})
export class WaitListComponent implements OnInit {

  public booking: Booking;

  public clientNotes: string;
  public loadStripeComponent = false;
  public clientHasStripeAccount = false;
  public refreshStripe = 0;
  //todo: pull chargeNoShow from salon service (true if  clientBookingPayments > 0)
  public clientBookingPayments: number;
  public noShowPercentage: number;

  public enableBookButton: boolean;
  
  constructor(public userService: UserService,
    public userBookingService: UserBookingService,
    private bookingService: BookingService,
    private router: Router,
    private location: Location,
    private notificationService: NotificationService,
    private stripeService: StripeService,
    private stylistService: StylistService,
    private dialogsService: DialogsService) {
    this.booking = this.userBookingService.getBooking().clone();
    this.clientNotes = '';
  }

  public ngOnInit () {
    if (this.booking.getServices().length === 0 ||
      this.booking.getId() !== undefined) {
      this.router.navigate(['/']);
    }

    this.clientBookingPayments = this.userService.getSalon().getClientBookingPayments();
    // console.log("this booking payements: ", this.clientBookingPayments);

  }

  public onBookingSave () {
    let allowSignUp = true;
    let allowFBLogin = true;

    if (this.userService.isLoggedIn()) {
      this.save(this.userService.getUser());
    } else {
      this.stylistService.getStylists(this.userService.getSalon().getId()).subscribe(
        (stylists) => {
          stylists.forEach( (individualStylist) => {
            if(individualStylist.getLetClientsRegister() == 0)
            {
              allowSignUp = false;
            }else if(individualStylist.getLetClientsUseFBLogin() == 0){
              allowFBLogin = false;
            }
          }
          );     
          this.dialogsService.login(allowSignUp,allowFBLogin).subscribe((client: Client) => {
            if (client) {
              if(this.clientBookingPayments > 0){
                // salon requires payment on booking so we will return to the page and collect payment info:
                // this.checkUserStripeAccount();
                this.refreshStripe++;
                // console.log("client info after login: ", client);
              } else {          
                this.save(client);
              }
            }
          });
        }); 

    }
  }

  private save (client: Client) {
    this.booking.setStartDateTime(new Date());
    this.booking.setStatus(BookingStatus.WaitList);
    this.booking.setSendEmail(1);

    for (let service of this.booking.getServices()) {
      service.setClientNotes(this.clientNotes);
    }

    this.bookingService.saveBooking(this.booking)
      .subscribe(
        (booking: Booking) => {
          this.notificationService.send(booking, NOTIFICATION_TYPE.NEW_WAIT_LIST)
            .subscribe(() => {
              this.router.navigate(['bookings/confirmation']);              
            },
            (error) => {
              throw error;
              // this.dialogsService.errorAlert().subscribe();
            });
        },
        (error) => {
          // this.dialogsService.errorAlert().subscribe();
          throw error;
        });
  }

  public onBookingCancel () {
    this.location.back();
  }
  
}
