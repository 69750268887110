import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SalonMonsterTapRetryComponent } from './sm-tap-retry.component';

import { SharedModule } from '../../shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SalonMonsterTapRetryComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    FlexLayoutModule,
    SharedModule
  ],
  exports: [
    SalonMonsterTapRetryComponent
  ],
  entryComponents: [],
  providers: []
})
export class SalonMonsterTapRetryModule {}
