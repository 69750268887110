import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { Booking } from '../../models/booking.model';
import { Utilities } from '../../utilities/utilities';

@Component({
  selector: 'sm-booking-item',
  templateUrl: 'booking-item.component.html',
  styleUrls: ['booking-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BookingItemComponent {

  @Input() booking: Booking;

  @Input() showButtons: boolean;

  @Input() cancellationInProgress: boolean;

  @Output() bookingDateChange = new EventEmitter<Booking>();

  @Output() bookingCancellation = new EventEmitter<Booking>();

  

  constructor () {
  }

  public onRebook () {
    if(this.allowRebooking()){
    this.bookingDateChange.emit(this.booking);
    }
  }

  public onCancel () {
    if (this.allowCancellation()) {
      this.bookingCancellation.emit(this.booking);      
    }
  }

  public allowCancellation (): boolean {
    const hourDiff = Utilities.timeDiffInMinutes(new Date(), this.booking.getStartDateTime()) / 60;
    let allowCancellationUntil = 0;

    // find the most restrictive setting from all the stylist in this booking
    for (let service of this.booking.getServices()) {
      const stylist = service.getStylist();
      if (stylist && stylist.getAllowCancellationUntil() > allowCancellationUntil) {
        // if one of the stylists doesnt allow cancellation, return early
        if (stylist.getLetClientsCancel() === 0)   {
          return false;
        }

        allowCancellationUntil = stylist.getAllowCancellationUntil();
      }
    }

    return hourDiff >= allowCancellationUntil;
  }

 
   public allowRebooking (): boolean {

    const hourDiff = Utilities.timeDiffInMinutes(new Date(), this.booking.getStartDateTime()) / 60;
    let allowCancellationUntil = 0;
  
    // find the most restrictive setting from all the stylist in this booking
   for (let service of this.booking.getServices()) {
      const stylist = service.getStylist();
      // if one of the stylists doesnt allow rebooking, return early
      if (stylist.getIsRebook() === 0)   {
        return false;
      }      
     if (stylist && stylist.getAllowCancellationUntil() > allowCancellationUntil) {
        
        //return true;
  
        allowCancellationUntil = stylist.getAllowCancellationUntil();
      }
    }
    
    return hourDiff >= allowCancellationUntil;
  }
}





