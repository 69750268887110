import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { SalonService } from '../services/salon.service';

@Injectable()
export class SalonAuthGuard implements CanActivate {
  
  constructor (private salonService: SalonService) {

  }

  public canActivate () : Observable<boolean> {
    return this.salonService.loadSalonBySubDomain();
  }

}