
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SalonMonsterFormatDurationPipe'
})
export class SalonMonsterFormatDurationPipe implements PipeTransform {

  transform(totalMinutes: number): string {
    if (!totalMinutes && totalMinutes !== 0) return '';

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    let result = '';

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    if (minutes > 0) {
      if (hours > 0) result += ' ';
      result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return result || '0 minutes';
  }
}