import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BookingSummaryListComponent } from './booking-summary-list.component';
import {SharedModule} from '../../shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    BookingSummaryListComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    SharedModule,
    MatCheckboxModule
  ],
  exports: [
    BookingSummaryListComponent
  ],
  entryComponents: [],
  providers: []
})
export class BookingSummaryListModule {}
