import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { Client } from '../models/client.model';

export class Utilities {

  public static parseDate (date: string) : Date {
    const parsedDate = moment(date, 'Y-MM-DD HH:mm:ss');
    return (parsedDate.isValid()) ? parsedDate.toDate() : null;
  }

  public static convertTZ (date: Date, timeZone: string = 'America/Los_Angeles') : Date {
    let parsedDate = momentTZ(date).tz(timeZone);

    if (parsedDate.isValid()) {
      const dateString: string = parsedDate.format('Y-MM-DD HH:mm:ss');
      return this.parseDate(dateString);
    } else {
      return null;
    }
  }

  public static unixToDate (unix: number) : Date {
    return moment.unix(unix).toDate();
  }

  public static cloneDate (date: Date) : Date {
    return moment(date).toDate();
  }

  public static addDays (date: Date, days: number) : Date {
    return moment(date).add(days, 'days').toDate();
  }

  public static subtractDays (date: Date, days: number) : Date {
    return moment(date).subtract(days, 'days').toDate();
  }

  public static addMinutes (date: Date, mins: number) : Date {
    return moment(date).add(mins, 'minutes').toDate();
  }

  public static formatDate (date: Date, format: string = 'Y-MM-DD HH:mm:ss') : string {
    return moment(date).format(format);
  }

  public static getDaysInMonth (date: Date): number {
    return moment(date).daysInMonth();
  }

  public static isDateValid (date: Date) : boolean {
    return moment(date).isValid();
  }

  public static compare (dateTimeA: Date, dateTimeB: Date): number {
    const momentA = moment(dateTimeA);
    const momentB = moment(dateTimeB);
    if (momentA > momentB) return 1;
    else if (momentA < momentB) return -1;
    else return 0;
  }

  public static roundTo2Decimal (num: number) : number {
    return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  public static timeDiffInMinutes (date1: Date, date2: Date) : number {
    const d1 = moment(date1);
    const d2 = moment(date2);
    const ms = d2.diff(d1);
    return moment.duration(ms).asMinutes();
  }

  public static getDefaultErrorMessageConfig (): string {
    return `Oops! An error occured, if this problem persists please contact us at support.salonmonster.com</a>.`;
  }

  public static clientHasContactDetails (client: Client): boolean {

    if (
        (client.getEmail() !== '' && client.getEmail() !== undefined && client.getEmail() !== null) ||
        (client.getPhone1() !== '' && client.getPhone1() !== undefined && client.getPhone1() !== null) ||
        (client.getPhone2() !== '' && client.getPhone2() !== undefined && client.getPhone2() !== null)
      ) {
      return true;
    } else {
      return false;
    }

  }

  public static clientHasLocationData (client: Client): boolean {

    if (
        (client.getAddress1() !== '' && client.getAddress1() !== undefined && client.getAddress1() !== null) ||
        (client.getAddress2() !== '' && client.getAddress2() !== undefined && client.getAddress2() !== null) ||
        (client.getCity() !== '' && client.getCity() !== undefined && client.getCity() !== null) ||
        (client.getProvince() !== '' && client.getProvince() !== undefined && client.getProvince() !== null) ||
        (client.getPostal() !== '' && client.getPostal() !== undefined && client.getPostal() !== null) ||
        (client.getCountry() !== undefined && client.getCountry().getCountryName() !== '' && client.getCountry().getCountryName() !== undefined && client.getCountry().getCountryName() !== null)
      ) {
      return true;
    } else {
      return false;
    }

  }

  public static getBookingStatuses () : {[id: string]: {name:string, class: string}} {
    return {
      '2': {
        name: 'Requested',
        class: 'sm-booking-status-requested'
      },
      '3': { 
        name: 'Booked',
        class: 'sm-booking-status-unconfirmed'
      },
      
      '8': { 
        name: 'Paid',
        class:'sm-booking-status-paid'
      },

      '9': { 
        name: 'Reminded by Phone',
        class:'sm-booking-status-reminded'
      },
      '10': { 
        name: 'Reminded by Email',
        class:'sm-booking-status-reminded'
      },
      '11': { 
        name: 'Reminded by Text',
        class:'sm-booking-status-reminded'
      },
      '12': { 
        name: 'Reminded by Email & Text',
        class:'sm-booking-status-reminded'
      },

      '4': { 
        name: 'Arrived',
        class:'sm-booking-status-arrived'
      }, 
      '5': { 
        name: 'No Show',
        class:'sm-booking-status-no-show'
      }
    

    }
  }

  public static getTimeZones (): Array<{value: string, text: string}> {
    return [
      { value: "America/Los_Angeles", text: "Pacific Time (US & Canada)" },
      { value: "America/Denver", text: "Mountain Time (US & Canada)" },
      { value: "America/Chicago", text: "Central Time (US & Canada)" },
      { value: "America/New_York", text: "Eastern Time (US & Canada)" },
      { value: "Etc/GMT+10", text: "Hawaii" },
      { value: "America/Anchorage", text: "Alaska" },
      { value: "America/Dawson_Creek", text: "Arizona" },
      { value: "Pacific/Midway", text: "Midway Island, Samoa" },
      { value: "America/Adak", text: "Hawaii-Aleutian" },
      { value: "Etc/GMT+10", text: "Hawaii" },
      { value: "Pacific/Marquesas", text: "Marquesas Islands" },
      { value: "Pacific/Gambier", text: "Gambier Islands" },
      { value: "America/Anchorage", text: "Alaska" },
      { value: "America/Ensenada", text: "Tijuana, Baja California" },
      { value: "Etc/GMT+8", text: "Pitcairn Islands" },
      { value: "America/Los_Angeles", text: "Pacific Time (US & Canada)" },
      { value: "America/Denver", text: "Mountain Time (US & Canada)" },
      { value: "America/Chihuahua", text: "Chihuahua, La Paz, Mazatlan" },
      { value: "America/Dawson_Creek", text: "Arizona" },
      { value: "America/Belize", text: "Saskatchewan, Central America" },
      { value: "America/Cancun", text: "Guadalajara, Mexico City, Monterrey" },
      { value: "Chile/EasterIsland", text: "Easter Island" },
      { value: "America/Chicago", text: "Central Time (US & Canada)" },
      { value: "America/New_York", text: "Eastern Time (US & Canada)" },
      { value: "America/Havana", text: "Cuba" },
      { value: "America/Bogota", text: "Bogota, Lima, Quito, Rio Branco" },
      { value: "America/Caracas", text: "Caracas" },
      { value: "America/Santiago", text: "Santiago" },
      { value: "America/La_Paz", text: "La Paz" },
      { value: "Atlantic/Stanley", text: "Faukland Islands" },
      { value: "America/Campo_Grande", text: "Brazil" },
      { value: "America/Goose_Bay", text: "Atlantic Time (Goose Bay)" },
      { value: "America/Glace_Bay", text: "Atlantic Time (Canada)" },
      { value: "America/St_Johns", text: "Newfoundland" },
      { value: "America/Araguaina", text: "UTC-3" },
      { value: "America/Montevideo", text: "Montevideo" },
      { value: "America/Miquelon", text: "Miquelon, St. Pierre" },
      { value: "America/Godthab", text: "Greenland" },
      { value: "America/Argentina/Buenos_Aires", text: "Buenos Aires" },
      { value: "America/Sao_Paulo", text: "Brasilia" },
      { value: "America/Noronha", text: "Mid-Atlantic" },
      { value: "Atlantic/Cape_Verde", text: "Cape Verde Is." },
      { value: "Atlantic/Azores", text: "Azores" },
      { value: "Europe/Belfast", text: "Greenwich Mean Time : Belfast" },
      { value: "Europe/Dublin", text: "Greenwich Mean Time : Dublin" },
      { value: "Europe/Lisbon", text: "Greenwich Mean Time : Lisbon" },
      { value: "Europe/London", text: "Greenwich Mean Time : London" },
      { value: "Africa/Abidjan", text: "Monrovia, Reykjavik" },
      { value: "Europe/Amsterdam", text: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
      { value: "Europe/Belgrade", text: "Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
      { value: "Europe/Brussels", text: "Brussels, Copenhagen, Madrid, Paris" },
      { value: "Africa/Algiers", text: "West Central Africa" },
      { value: "Africa/Windhoek", text: "Windhoek" },
      { value: "Asia/Beirut", text: "Beirut" },
      { value: "Africa/Cairo", text: "Cairo" },
      { value: "Asia/Gaza", text: "Gaza" },
      { value: "Africa/Blantyre", text: "Harare, Pretoria" },
      { value: "Asia/Jerusalem", text: "Jerusalem" },
      { value: "Europe/Minsk", text: "Minsk" },
      { value: "Asia/Damascus", text: "Syria" },
      { value: "Europe/Moscow", text: "Moscow, St. Petersburg, Volgograd" },
      { value: "Africa/Addis_Ababa", text: "Nairobi" },
      { value: "Asia/Tehran", text: "Tehran" },
      { value: "Asia/Dubai", text: "Abu Dhabi, Muscat" },
      { value: "Asia/Yerevan", text: "Yerevan" },
      { value: "Asia/Kabul", text: "Kabul" },
      { value: "Asia/Yekaterinburg", text: "Ekaterinburg" },
      { value: "Asia/Tashkent", text: "Tashkent" },
      { value: "Asia/Kolkata", text: "Chennai, Kolkata, Mumbai, New Delhi" },
      { value: "Asia/Katmandu", text: "Kathmandu" },
      { value: "Asia/Dhaka", text: "Astana, Dhaka" },
      { value: "Asia/Novosibirsk", text: "Novosibirsk" },
      { value: "Asia/Rangoon", text: "Yangon (Rangoon)" },
      { value: "Asia/Bangkok", text: "Bangkok, Hanoi, Jakarta" },
      { value: "Asia/Krasnoyarsk", text: "Krasnoyarsk" },
      { value: "Asia/Hong_Kong", text: "Beijing, Chongqing, Hong Kong, Urumqi" },
      { value: "Asia/Irkutsk", text: "Irkutsk, Ulaan Bataar" },
      { value: "Australia/Perth", text: "Perth" },
      { value: "Australia/Eucla", text: "Eucla" },
      { value: "Asia/Tokyo", text: "Osaka, Sapporo, Tokyo" },
      { value: "Asia/Seoul", text: "Seoul" },
      { value: "Asia/Yakutsk", text: "Yakutsk" },
      { value: "Australia/Adelaide", text: "Adelaide" },
      { value: "Australia/Darwin", text: "Darwin" },
      { value: "Australia/Brisbane", text: "Brisbane" },
      { value: "Australia/Hobart", text: "Hobart" },
      { value: "Asia/Vladivostok", text: "Vladivostok" },
      { value: "Australia/Lord_Howe", text: "Lord Howe Island" },
      { value: "Etc/GMT-11", text: "Solomon Is., New Caledonia" },
      { value: "Asia/Magadan", text: "Magadan" },
      { value: "Pacific/Norfolk", text: "Norfolk Island" },
      { value: "Asia/Anadyr", text: "Anadyr, Kamchatka" },
      { value: "Pacific/Auckland", text: "Auckland, Wellington" },
      { value: "Etc/GMT-12", text: "Fiji, Kamchatka, Marshall Is." },
      { value: "Pacific/Chatham", text: "Chatham Islands" },
      { value: "Pacific/Tongatapu", text: "Nuku alofa" },
      { value: "Pacific/Kiritimati", text: "Kiritimati" }
    ];
  }

  public static decodeHTML (str) {
    var map = {"gt":">" /* , … */};
    return str.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, function($0, $1) {
        if ($1[0] === "#") {
            if(typeof $1[1] !== 'undefined'){
              return String.fromCharCode($1[1].toLowerCase() === "x" ? parseInt($1.substr(2), 16)  : parseInt($1.substr(1), 10));
            } else {
              parseInt($1.substr(1), 10);
            }
        } else {
            return map.hasOwnProperty($1) ? map[$1] : $0;
        }
    });
  }

  public static isBase64 (str: string) : boolean {
    return str.indexOf('data:image') > -1;
  }

}