<mat-card>
  <mat-card-header style="margin:0 -8px;">
    <mat-card-title><h3>{{ booking.getStartDateTime() | salonMonsterDate: 'dddd, MMMM D, Y' }}</h3></mat-card-title>
    <mat-card-subtitle *ngIf="showButtons && booking.getStatus() == 2">Approval Pending</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div *ngFor="let service of booking.getServices()">
        <b>{{ service.getStartDateTime() | salonMonsterDate: 'h:mm a'}}</b> {{service.getServiceDefinition().getServiceName()}} with {{ service.getStylist().getFirstName()}} {{service.getStylist().getLastName()}}
    </div>
    <br/>
    <div 
      *ngIf="booking.getServices().length > 0">
        <em *ngIf="booking.getServices()[0].getClientNotes().length > 0">Client Note</em> {{ booking.getServices()[0].getClientNotes()}}  
    </div>
  </mat-card-content>

  <mat-card-actions *ngIf="showButtons">
    <button mat-button color="primary" *ngIf="allowRebooking()" (click)="onRebook()">Reschedule</button>
    <button mat-button color="primary" *ngIf="allowCancellation()" [disabled]="cancellationInProgress == booking.id" (click)="onCancel()">
      Delete <mat-progress-spinner *ngIf="cancellationInProgress == booking.id"[diameter]="20" mode="indeterminate"></mat-progress-spinner>
    </button>
  </mat-card-actions>
</mat-card>
