import { User, UserOptions } from './user.model';
import { ServiceCategory } from './servicecategory.model';
import { Country } from './country.model';
import { ServiceDefinition } from './servicedefinition.model';
import { Cloneable } from './cloneable';

export interface StylistOptions extends UserOptions {
  administrator?: boolean,
  stylistKey?: string;
  confirmApptsManually?: number;
  calendar?: number;
  viewAllCalendars?: number;
  viewAllClients?: number;
  settingsControl?: number;
  photoUrl?: string;
  hideDurations?: number;
  canPush?: number;
  cc?: number;
  allowBookingsUntil?: string;
  allowCancellationUntil?: number;
  bookingHorizon?: string;
  sendAppointmentReminderBefore?: number;
  bookingInterval?: number;
  letClientsRegister?: number;
  letClientsUseFBLogin?: number;  
  letClientsCancel?: number;
  letClientsWaitList?: number;
  isRebook?: number;
  bookingIntro?: string;
  waitingList?: number;
  notifyForAllStaffActivity?: number;
  newBookingNotification?: number;
  editBookingNotification?: number;
  deleteBookingNotification?: number;
  waitListRequestNotification?: number;
  bio?: string;
  cancellationPolicy?: string;
  services?: ServiceCategory [];
  selectedDuration?: number;
  selectedPrice?: number;
  selectedServiceID?: number;
  selectedFinishedDurtion?: number;
  selectedProcessingDuration?: number;

}

export interface ServiceDetailsOptions {
  id: number;
  text: string;
}

export class Stylist extends User implements Cloneable <Stylist>{

  private administrator: boolean;

  private isRebook: number;

  private stylistKey: string;
  
  private confirmApptsManually: number;
  
  private calendar: number;
  
  private viewAllCalendars: number;
  
  private viewAllClients: number;
  
  private settingsControl: number;
  
  private photoUrl: string;
  
  private hideDurations: number;
  
  private canPush: number;

  private cc: number;

  private bio: string;

  private serviceCategories: ServiceCategory [];

  private areServiceDetailsLoaded: boolean;

  private cachedServiceDetailsOptionsEl: string;

  private allowBookingsUntil: string;

  private allowCancellationUntil: number;
  
  private bookingHorizon: string;
  
  private sendAppointmentReminderBefore: number;
  
  private bookingInterval: number;
  
  private letClientsRegister: number;

  private letClientsUseFBLogin: number;
  
  private letClientsCancel: number;

  private letClientsWaitList: number;
  
  private bookingIntro: string;
  private waitingList: number;
  private cancellationPolicy: string;

  private notifyForAllStaffActivity: number;

  private newBookingNotification: number;

  private editBookingNotification: number;

  private deleteBookingNotification: number;

  private waitListRequestNotification: number;
  private selectedDuration: number;
  private selectedPrice: number;
  private selectedFinishedDurtion: number;
  private selectedServiceID: number;
  private selectedProcessingDuration: number;


  constructor (options?: StylistOptions) {
    super(options);
    this.administrator = options.administrator || false;
    this.serviceCategories = options.services || [];
    this.areServiceDetailsLoaded = false;
    this.cachedServiceDetailsOptionsEl = '';
    this.isRebook = options.isRebook || 0;
    this.stylistKey = options.stylistKey;
    this.confirmApptsManually = options.confirmApptsManually;
    this.calendar = options.calendar;
    this.viewAllCalendars = options.viewAllCalendars;
    this.viewAllClients = options.viewAllClients;
    this.settingsControl = options.settingsControl;
    this.photoUrl = options.photoUrl;
    this.hideDurations = options.hideDurations;
    this.canPush = options.canPush;
    this.cc = options.cc;
    this.bio = options.bio;
    this.allowBookingsUntil = options.allowBookingsUntil;
    this.allowCancellationUntil = options.allowCancellationUntil;
    this.bookingHorizon = options.bookingHorizon;
    this.sendAppointmentReminderBefore = options.sendAppointmentReminderBefore;
    this.bookingInterval = options.bookingInterval;
    this.letClientsRegister = options.letClientsRegister;
    this.letClientsUseFBLogin = options.letClientsUseFBLogin;
    this.letClientsCancel = options.letClientsCancel;
    this.letClientsWaitList = options.letClientsWaitList;
    this.bookingIntro = options.bookingIntro;
    this.waitingList = options.waitingList || 0;
    this.cancellationPolicy = options.cancellationPolicy;
    this.notifyForAllStaffActivity = options.notifyForAllStaffActivity;
    this.newBookingNotification = options.newBookingNotification;
    this.editBookingNotification = options.editBookingNotification;
    this.deleteBookingNotification = options.deleteBookingNotification;
    this.waitListRequestNotification = options.waitListRequestNotification;
    this.selectedDuration = options?.selectedDuration || 0;
    this.selectedPrice = options?.selectedPrice || 0;
    this.selectedFinishedDurtion = options?.selectedFinishedDurtion || 0;
    this.selectedServiceID = options?.selectedServiceID || 0;
    this.selectedProcessingDuration = options?.selectedProcessingDuration || 0;
  }

  public clone () : Stylist {
    return new Stylist({
        id: this.getID(),
        firstName: this.getFirstName(),
        lastName: this.getLastName(),
        title: this.getTitle(),
        email: this.getEmail(),
        phone1: this.getPhone1(),
        phone2: this.getPhone2(),
        workPh: this.getWorkph(),
        workExt: this.getWorkExt(),
        address1: this.getAddress1(),
        address2: this.getAddress2(),
        city: this.getCity(),
        province: this.getProvince(),
        postal: this.getPostal(),
        country: (this.getCountry()) ? this.getCountry().clone() : undefined,
        administrator: this.isAdministrator(),
        isRebook: this.getIsRebook(),
        stylistKey: this.getStylistKey(),
        confirmApptsManually: this.getConfirmApptsManually(),
        calendar: this.getCalendar(),
        viewAllCalendars: this.getViewAllCalendars(),
        viewAllClients: this.getViewAllClients(),
        settingsControl: this.getSettingsControl(),
        photoUrl: this.getPhotoUrl(),
        hideDurations: this.getHideDurations(),
        canPush: this.getCanPush(),
        cc: this.getCc(),
        bio: this.getBio(),
        allowBookingsUntil: this.getAllowBookingsUntil(),
        allowCancellationUntil: this.getAllowCancellationUntil(),
        bookingHorizon: this.getBookingHorizon(),
        sendAppointmentReminderBefore: this.getSendAppointmentReminderBefore(),
        bookingInterval: this.getBookingInterval(),
        letClientsRegister: this.getLetClientsRegister(),
        letClientsUseFBLogin: this.getLetClientsUseFBLogin(),
        letClientsCancel: this.getLetClientsCancel(),
        letClientsWaitList: this.getLetClientsWaitList(),
        bookingIntro: this.getBookingIntro(),
        waitingList: this.getWaitingList(),
        cancellationPolicy: this.getCancellationPolicy(),
        notifyForAllStaffActivity: this.getNotifyForAllStaffActivity(),
        newBookingNotification: this.getNewBookingNotification(),
        editBookingNotification: this.getEditBookingNotification(),
        deleteBookingNotification: this.getDeleteBookingNotification(),
        waitListRequestNotification: this.getWaitListRequestNotification()
      });
  }

  
  public static parseStylistData (stylistData) : Stylist {
    let country: Country;
    if (stylistData.countryID) {
      country = new Country(stylistData.countryID, stylistData.countryName);
    }

    const stylist = new Stylist({
      id: stylistData.id,
      firstName: stylistData.firstName,
      lastName: stylistData.lastName,
      title: stylistData.title,
      email: stylistData.email,
      phone1: stylistData.phone1,
      phone2: stylistData.phone2,
      address1: stylistData.address1,
      address2: stylistData.address2,
      city: stylistData.city,
      province: stylistData.province,
      postal: stylistData.postal,
      country: country,
      stylistKey: stylistData.stylistKey,
      calendar: stylistData.calendar,
      viewAllCalendars: stylistData.viewAllCalendars,
      viewAllClients: stylistData.viewAllClients,
      settingsControl: stylistData.settingsControl,
      photoUrl: stylistData.photoUrl,
      hideDurations: stylistData.hideDurations,
      canPush: stylistData.canPush,
      cc: stylistData.cc,
      bio: stylistData.bio,
      confirmApptsManually: stylistData.confirmApptsManually,
      administrator: stylistData.administrator,
      isRebook: stylistData.isRebook,
      allowBookingsUntil: stylistData.allowBookingsUntil,
      allowCancellationUntil: stylistData.allowCancellationUntil,
      bookingHorizon: stylistData.bookingHorizon,
      sendAppointmentReminderBefore: stylistData.sendAppointmentReminderBefore,
      bookingInterval: stylistData.bookingInterval,
      letClientsRegister: stylistData.letClientsRegister,
      letClientsUseFBLogin: stylistData.letClientsUseFBLogin,
      letClientsCancel: stylistData.letClientsCancel,
      letClientsWaitList: stylistData.waitingList,
      bookingIntro: stylistData.bookingIntro,      
      waitingList: stylistData.waitingList,
      cancellationPolicy: stylistData.cancellationPolicy,
      notifyForAllStaffActivity: stylistData.notifyForAllStaffActivity,
      newBookingNotification: stylistData.newBookingNotification,
      editBookingNotification: stylistData.editBookingNotification,
      deleteBookingNotification: stylistData.deleteBookingNotification,
      waitListRequestNotification: stylistData.waitListRequestNotification,
      services: []
    });

    const serviceCategories: Array<ServiceCategory> = [];
    if (stylistData.services) {
      for (let i = 0; i < stylistData.services.length; i++) {
        const data = stylistData.services[i];
        const serviceDefinitions = [];
        for (let serviceDetail of data['serviceDetails']) {
          const serviceDefinition = ServiceDefinition.parseServiceDefinition(serviceDetail);
          serviceDefinition.setStylist(stylist);
          serviceDefinitions.push(serviceDefinition);
        }

        serviceCategories.push(new ServiceCategory({
          id: data['id'],
          categoryName: data['categoryName'],
          serviceDefinitions: serviceDefinitions
        }));
      }
    }
   
    stylist.setServiceCategories(serviceCategories)
    return stylist;
  }

  public areServicesLoaded () : boolean {
    return this.areServiceDetailsLoaded;
  }

  public getCachedServiceDetailsOptionsEl () {
    return this.cachedServiceDetailsOptionsEl;
  }

  public getServiceCategories () : ServiceCategory[] {
    return this.serviceCategories;
  }

  public isAdministrator () : boolean {
    return this.administrator;
  }

  public setadministrator (administrator: boolean) : void {
    this.administrator = administrator;
  }

  public getIsRebook () : number {
    return this.isRebook;
  }

  public setIsRebook (value: number) {
    this.isRebook = value;
  }

  public getStylistKey () : string {
    return this.stylistKey;
  }
  
  public getConfirmApptsManually () : number {
    return this.confirmApptsManually;
  }

  public setConfirmApptsManually (value: number) {
    this.confirmApptsManually = value;
  }
  
  public getCalendar ()  : number {
    return this.calendar;
  }
  
  public getViewAllCalendars () : number {
    return this.viewAllCalendars;
  }
  
  public getViewAllClients () : number {
    return this.viewAllClients;
  }
  
  public getSettingsControl () : number {
    return this.settingsControl;
  }
  
  public getPhotoUrl () : string {
    return this.photoUrl;
  }
  
  public getHideDurations () : number {
    return this.hideDurations;
  }
  
  public getCanPush () : number {
    return this.canPush;
  }

  public getCc () : number {
    return this.cc;
  }

  public setCc (cc: number) {
    this.cc = cc;
  }

  public getBio () : string {
    return this.bio;
  }

  public getCancellationPolicy (): string {
    return this.cancellationPolicy;
  }

  public setBio (bio: string) {
    this.bio = bio;
  }

  public getAllowBookingsUntil () : string {
    return this.allowBookingsUntil;
  }

  public getAllowCancellationUntil () : number {
    return this.allowCancellationUntil;
  }

  public getBookingHorizon () : string {
    return this.bookingHorizon
  }

  public getSendAppointmentReminderBefore () : number {
    return this.sendAppointmentReminderBefore
  }

  public getBookingInterval () : number {
    return this.bookingInterval;
  }

  public getLetClientsRegister () : number {
    return this.letClientsRegister;
  }

  public getLetClientsUseFBLogin () : number {
    return this.letClientsUseFBLogin;
  }

  public getLetClientsWaitList () : number {
    return this.letClientsWaitList;
  }

  
  public getLetClientsCancel () : number {
    return this.letClientsCancel;
  }
  
  public getBookingIntro () : string {
    return this.bookingIntro;
  }

  public getWaitingList () : number {
    return this.waitingList;
  }
  

  public getNotifyForAllStaffActivity () : number {
    return this.notifyForAllStaffActivity;
  }

  public getNewBookingNotification () : number {
    return this.newBookingNotification;
  }

  public getEditBookingNotification (): number {
    return this.editBookingNotification;
  }

  public getDeleteBookingNotification (): number {
    return this.deleteBookingNotification;
  }

  public getWaitListRequestNotification (): number {
    return this.waitListRequestNotification;
  }

  public setAllowBookingsUntil (value: string) {
    this.allowBookingsUntil = value;
  }

  public setAllowCancellationUntil (val: number) {
    this.allowCancellationUntil = val;
  }

  public setBookingHorizon (value: string) {
    this.bookingHorizon = value;
  }

  public setSendAppointmentReminderBefore (value: number) {
    this.sendAppointmentReminderBefore = value;
  }
  
  public setBookingInterval (value: number) {
    this.bookingInterval = value;
  }

  public setLetClientsRegister (value: number) {
    this.letClientsRegister = value;
  }

  public setLetClientsUseFBLogin (value: number) {
    this.letClientsUseFBLogin = value;
  }

  public setLetClientsCancel (value: number) {
    this.letClientsCancel = value;
  }

  public setBookingIntro (value: string) {
    this.bookingIntro = value;
  }

  public setWaitingList (value: number) {
    this.waitingList = value;
  }


  public setNotifyForAllStaffActivity (value: number) {
    this.notifyForAllStaffActivity = value;
  }

  public setNewBookingNotification (value: number) {
    this.newBookingNotification = value;
  }

  public setEditBookingNotification (value: number) {
    this.editBookingNotification = value;
  }

  public setDeleteBookingNotification (value: number) {
    this.deleteBookingNotification = value;
  }

  public setWaitListRequestNotification (value: number) {
    this.waitListRequestNotification = value;
  }

  public setServiceCategories (serviceCategories: Array<ServiceCategory>) {
    this.serviceCategories = serviceCategories;
  }
  public getSelectedDuration(): number {
    return this.selectedDuration;
  }

  public setSelectedDuration(value: number): void {
    this.selectedDuration = value;
  }

  public getSelectedPrice(): number {
    return this.selectedPrice;
  }

  public setSelectedPrice(value: number): void {
    this.selectedPrice = value;
  }
  public getSelectedFinishedDuration(): number {
    return this.selectedFinishedDurtion;
  }
  
  public setSelectedFinishedDuration(value: number): void {
    this.selectedFinishedDurtion = value;
  }
  
  public getSelectedServiceID(): number {
    return this.selectedServiceID;
  }
  
  public setSelectedServiceID(value: number): void {
    this.selectedServiceID = value;
  }
  public getSelectedProcessingDuration(): number {
    return this.selectedProcessingDuration;
  }
  public setSelectedProcessingDuration(value: number): void  {
    this.selectedProcessingDuration = value;
  }


  public toJSON (): Object {

    return {
      id: this.getID(),
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      title: this.getTitle(),
      email: this.getEmail(),
      phone1: this.getPhone1(),
      phone2: this.getPhone2(),
      workPh: this.getWorkph(),
      workExt: this.getWorkExt(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      city: this.getCity(),
      province: this.getProvince(),
      postal: this.getPostal(),
      country: (this.getCountry()) ? this.getCountry().getCountryName() : undefined,
      administrator: (this.isAdministrator()) ? 1 : 0,
      isRebook: this.getIsRebook(),
      stylistKey: this.getStylistKey(),
      confirmApptsManually: this.getConfirmApptsManually(),
      calendar: this.getCalendar(),
      viewAllCalendars: this.getViewAllCalendars(),
      viewAllClients: this.getViewAllClients(),
      settingsControl: this.getSettingsControl(),
      photoUrl: this.getPhotoUrl(),
      hideDurations: this.getHideDurations(),
      canPush: this.getCanPush(),
      cc: this.getCc(),
      allowBookingsUntil: this.getAllowBookingsUntil(),
      bookingHorizon: this.getBookingHorizon(),
      sendAppointmentReminderBefore: this.getSendAppointmentReminderBefore(),
      bookingInterval: this.getBookingInterval(),
      letClientsRegister: this.getLetClientsRegister(),
      letClientsUseFBLogin: this.getLetClientsUseFBLogin(),
      letClientsCancel: this.getLetClientsCancel(),
      letClientsWaitList: this.getLetClientsWaitList(),
      bookingIntro: this.getBookingIntro(),
      waitingList: this.getWaitingList(),
      notifyForAllStaffActivity: this.getNotifyForAllStaffActivity(),
      newBookingNotification: this.getNewBookingNotification(),
      editBookingNotification: this.getEditBookingNotification(),
      deleteBookingNotification: this.getDeleteBookingNotification(),
      waitListRequestNotification: this.getWaitListRequestNotification(),
      selectedDuration: this.getSelectedDuration(),
      selectedPrice: this.getSelectedPrice(),
      selectedFinishedDuration: this.getSelectedFinishedDuration(),
      selectedServiceID: this.getSelectedServiceID(),
      selectedProcessingDuration: this.getSelectedProcessingDuration()
    }
  }
 
}