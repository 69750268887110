import { Injectable }     from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable }     from 'rxjs';

import { ServiceCategory } from '../models/servicecategory.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { environment } from '../../../environments/environment';
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class ServiceCategoryService extends SalonmonsterHttpClient {

  private serviceCategories: Array<ServiceCategory>;

  constructor (http: HttpClient,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService) {
    super(http);
    this.serviceCategories = [];
  }

  public getServiceCategories (stylistID: number, forceLoad?: boolean): Observable<Array<ServiceCategory>> {
    return new Observable<Array<ServiceCategory>>((observer) => {
      const url = `${environment.API_ROOT}/stylists/${stylistID}/servicecategories`;
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const serviceCategories: Array<ServiceCategory> = [];

            for (let i = 0; i < data.length; i++) {
              serviceCategories.push(ServiceCategory.parseData(data[i]));
            }

            this.serviceCategories = serviceCategories;
            return this.serviceCategories;
          })
        )
        .subscribe((serviceCategories) => {
          observer.next(serviceCategories);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
    
    });
  }

  public getSalonServiceCategories () : Observable<Array<ServiceCategory>> {
    return new Observable<Array<ServiceCategory>> ((observer) => {
      const url = `${environment.API_ROOT}/salons/${this.userService.getSalon().getId()}/servicecategories?requestAll=true`;
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const serviceCategories: Array<ServiceCategory> = [];

            for (let i = 0; i < data.length; i++) {
              serviceCategories.push(ServiceCategory.parseData(data[i]));
            }

            this.serviceCategories = serviceCategories;
            return this.serviceCategories;
          })
        )
        .subscribe((serviceCategories) => {
          observer.next(serviceCategories);
          observer.complete();
        },
        (error) => {
          observer.error(this.errorHandlerService.handleError(error));
          observer.complete();
        });
      
    });
  }

  public saveServiceCategory (serviceCategory: ServiceCategory) : Observable<ServiceCategory> {

    return new Observable<ServiceCategory>((observer) => {
      let url = `${environment.API_ROOT}/servicecategories`;
    
      if (serviceCategory.getId()) {
        url += '/' +serviceCategory.getId();
        this.put(url, serviceCategory.toJSON())
          .pipe(
            map((res: any) => {
              const body = res;
              const data = body.data;
              return ServiceCategory.parseData(data);
            })
          )
          .subscribe((serviceCategory) => {
            observer.next(serviceCategory);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });
      
      } else {
        this.post(url, serviceCategory.toJSON())
          .pipe(
            map((res: any) => {
              const body = res;
              const data = body.data;
              return ServiceCategory.parseData(data);
            })
          )
          .subscribe((serviceCategory) => {
            observer.next(serviceCategory);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          });
      }

    });

  }

}