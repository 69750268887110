import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { Booking } from '../../models/booking.model';
import { Service } from '../../models/service.model';
import { Utilities } from '../../utilities/utilities';
@Component({
  selector: 'sm-booking-selected-summary-list',
  templateUrl: './booking-selected-summary-list.component.html',
  styleUrls: ['./booking-selected-summary-list.component.scss']
})
export class BookingSelectedSummaryListComponent {
  @Input() booking: Booking;

  @Input() title: string;

  @Input() subTitle: string;

  @Input() showDeleteButton: boolean;

  @Output() serviceDelete = new EventEmitter<Service>();

  constructor () {}

  public removeService (service: Service) {
    this.serviceDelete.emit(service);
  }

}
