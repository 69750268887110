import { NgModule } from '@angular/core';

import { SalonMonsterDatePipe } from './pipes/salonmonster-date.pipe';
import { SalonMonsterCurrencyPipe } from './pipes/salonmonster-currency.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SalonMonsterPaleDirective } from './directives/salonmonster-pale.directive';
import { SalonMonsterTappableDirective } from './directives/salonmonster-tappable.directive';
import { SalonMonsterTextCenterDirective } from './directives/salonmonster-text-center.directive';
import { SalonMonsterPrimaryDirective } from './directives/salonmonster-primary.directive';
import { SalonMonsterSecondaryDirective } from './directives/salonmonster-secondary.directive';
import { SalonMonsterRestrictWidthDirective } from './directives/salonmonster-restrict-width.directive';
import { SalonMonsterFullWidthDirective } from './directives/salonmonster-full-width.directive';
import { SalonMonsterFormatDurationPipe } from './pipes/salonmonster-format-duration-pipe';
@NgModule({
  declarations: [
    SalonMonsterDatePipe,
    SalonMonsterCurrencyPipe,
    SalonMonsterPaleDirective,
    SafePipe,
    SalonMonsterTappableDirective,
    SalonMonsterTextCenterDirective,
    SalonMonsterPrimaryDirective,
    SalonMonsterSecondaryDirective,
    SalonMonsterRestrictWidthDirective,
    SalonMonsterFullWidthDirective,
    SalonMonsterFormatDurationPipe
  ],
  imports: [

  ],
  exports: [
    SalonMonsterDatePipe,
    SalonMonsterCurrencyPipe,
    SafePipe,
    SalonMonsterPaleDirective,
    SalonMonsterTappableDirective,
    SalonMonsterTextCenterDirective,
    SalonMonsterPrimaryDirective,
    SalonMonsterSecondaryDirective,
    SalonMonsterRestrictWidthDirective,
    SalonMonsterFullWidthDirective,
    SalonMonsterFormatDurationPipe
  ],
  entryComponents: [],
  providers: []
})
export class SharedModule {}
