import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SalonMonsterLoadMaskModule } from '../shared/components/sm-load-mask/sm-load-mask.module';
import { SalonMonsterTapRetryModule } from '../shared/components/sm-tap-retry/sm-tap-retry.module';
import { ClientDetailsComponent }  from './client-profile/client-details.component';
import { ClientBookingsComponent }  from './client-profile/client-bookings.component';
import { ClientCardRequestComponent } from './client-cardrequest.component.ts/client-cardrequest.component';
import { ClientChangePasswordComponent } from './client-change-password/client-change-password.component';

import { ClientQuickloginComponent }  from './client-quicklogin/client-quicklogin.component';

import { BookingItemModule } from '../shared/components/booking-item/booking-item.module';
import { DialogsModule } from '../shared/services/dialogs/dialogs.module';
import { SharedModule } from '../shared/shared.module';

import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import {StripePaymentModule} from '../shared/components/stripe-payment/stripe-payment.module';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';


@NgModule({
  declarations: [
    ClientDetailsComponent,
    ClientBookingsComponent,
    ClientQuickloginComponent,
    ClientCardRequestComponent,
    ClientChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    // MaterialModule,
    // MdCardModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    BookingItemModule,
    SalonMonsterLoadMaskModule,
    SalonMonsterTapRetryModule,
    DialogsModule,
    SharedModule,
    StripePaymentModule,
    PasswordStrengthMeterModule.forRoot(),
  ],
  exports: [
  ],
  providers: [

  ],
  bootstrap: []
})
export class ClientModule { }
