import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './shared/services/user.service';
import { UserBookingService } from './shared/services/user-booking.service';
import { SalonService } from './shared/services/salon.service';
import { StylistService } from './shared/services/stylist.service';
import { Booking } from './shared/models/booking.model';
import { Client } from './shared/models/client.model';
import { DialogsService } from './shared/services/dialogs/dialogs.service';
import { MatSidenav } from "@angular/material/sidenav";
import { environment } from '../environments/environment';
import { FooterService } from './shared/services/footer.service';
import { ChangeDetectorRef } from '@angular/core';

declare var window: any;
declare var FB: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  @ViewChild('sideNav', { static: false }) sideNav: MatSidenav;

  public booking: Booking;

  public isLoading: boolean;
  public showHome: number = 0;
  public isBookingEnabled: boolean = false;
  public showFooter: boolean = true;

  public allowSignUp: boolean = true;

  public constructor(public userService: UserService,
    public userBookingService: UserBookingService,
    public router: Router,
    private salonService: SalonService,
    private stylistService: StylistService,
    private dialogsService: DialogsService,
    private footerService: FooterService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.booking = this.userBookingService.getBooking();
    this.isLoading = true;
    this.userBookingService.getBookingChangeRef().subscribe((booking: Booking) => {
      if (booking) {
        this.booking = booking;
      }
    });
    this.footerService.showFooter$.subscribe((show) => {
      this.showFooter = show;
    });

    this.userService.getSalonInitMonitor().subscribe((init: boolean) => {
      if (init) {
        this.isLoading = false;
        let salon = this.userService.getSalon();
        this.isBookingEnabled = this.userService.getSalon().getEnableOnlineBooking();
        this.userService.setIsBookingEnabled(salon.getEnableOnlineBooking())
        this.userService.setIsReadonly(salon.isReadOnly());
        this.userService.setIsAccountDeleted(salon.getAccountStatus() == 4);
        this.changeDetectorRef.detectChanges();
        if (salon.getShowHome()) {
          this.showHome = salon.getShowHome();
          // TODO: get allowSignUp value
        }
      }
    });


    // facebook async
    // This function initializes the FB variable 
    window.fbAsyncInit = function () {
      FB.init({
        appId: environment.FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v17.0'
      });

      FB.AppEvents.logPageView();

    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }

  ngAfterViewInit() {

  }
  public checkLoginState() {
    // console.log('facebook login state');
  }

  public statusChangeCallback(response) {

  }

  public newBooking() {
    if (this.booking && this.booking.getServices().length > 0) {
      this.dialogsService.confirm("New Booking", "Are you sure you want to start a new booking?")
        .subscribe(
          (confirm) => {
            if (confirm) {
              this.resetPage();
              this.router.navigate(['/bookings']);
            }
          }
        );
    } else {
      this.resetPage();
      this.router.navigate(['/bookings']);
    }
  }

  public viewClientBookings() {
    if (this.sideNav) {
      this.sideNav.close();
    }

    this.router.navigate(['/client/bookings']);
  }

  public viewClientProfile() {
    if (this.sideNav) {
      this.sideNav.close();
    }

    this.router.navigate(['/client/profile']);
  }

  public viewHelpPage() {

    if (this.sideNav) {
      this.sideNav.close();
    }

    this.router.navigate(['/help']);
  }

  public viewHomePage() {
    if (this.sideNav) {
      this.sideNav.close();
    }
    this.router.navigate(['/']);
  }

  public viewContactPage() {

    if (this.sideNav) {
      this.sideNav.close();
    }

    this.router.navigate(['/contact']);
  }

  public resetPage() {
    this.userBookingService.resetBooking();
    this.router.navigate(['/']);
  }

  public onLogin() {
    if (this.userService.isLoggedIn()) {
      this.router.navigate(['/bookings']);
    } else {
      let allowSignUp = true;
      let allowFBLogin = true;
      // get data for all stylists
      this.stylistService.getStylists(this.userService.getSalon().getId()).subscribe(
        (stylists) => {
          // loop through stylists and check if any have allowSignup == 0
          stylists.forEach((individualStylist) => {
            // console.log('individual stylist',individualStylist);
            if (individualStylist.getLetClientsRegister() == 0) {
              //set to false if any stylist doesn't allowSignup
              allowSignUp = false;
            } else if (individualStylist.getLetClientsUseFBLogin() == 0) {
              //set to false if any stylist doesn't FBLogin
              allowFBLogin = false;
            }
          }
          );
          this.dialogsService.login(allowSignUp, allowFBLogin).subscribe((client: Client) => {
            if (client) {
              this.router.navigate(['/bookings']);
            }
          });
        });
    }
  }
  public isStylistsPage(): boolean {
    return this.router.url === "/stylists/any";
  }

  public onLogOut() {
    this.userService.logout();
    this.router.navigate(['/']);
  }

}
