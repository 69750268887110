import { Country } from './country.model';

export interface UserOptions {
  id?: number;
  firstName?: string;
  lastName?: string;
  title?:string;
  email?: string;
  password?: string;
  phone1?: string;
  phone2?: string;
  workPh?: string;
  workExt?: string;
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  postal?: string;
  country?: Country;
}

export abstract class User {

  private id: number;

  private firstName: string;

  private lastName: string;
  private title: string;

  private email: string;

  private password: string;

  private phone1: string;

  private phone2: string;

  private workPh: string;

  private workExt: string;

  private address1: string;

  private address2: string;

  private city: string;

  private province: string;

  private postal: string;

  private country: Country;

  constructor (options?: UserOptions) {
    options = options || {};
    this.id = options.id;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.title = options.title || '';
    this.email = options.email || '';
    this.password = options.password || '';
    this.phone1 = options.phone1 || '';
    this.phone2 = options.phone2 || '';
    this.workPh = options.workPh || '';
    this.workExt = options.workExt || '';
    this.address1 = options.address1 || '';
    this.address2 = options.address2 || '';
    this.city = options.city || '';
    this.province = options.province || '';
    this.postal = options.postal || '';
    this.country = options.country || new Country();
  }

  public getID () : number {
    return this.id;
  }

  public setID (newID: number) : void {
    this.id = newID;
  }

  public getFirstName () : string {
    return this.firstName;
  }

  public setFirstName (firstName: string) : void {
    this.firstName = firstName;
  }

  public getLastName () : string {
    return this.lastName;
  }

  public setLastName (lastName: string) : void {
    this.lastName = lastName;
  }

  public getTitle () : string {
    return this.title;
  }

  public setTitle (title: string) : void {
    this.title = title;
  }

  public getEmail () : string {
    return this.email;
  }

  public setEmail (email: string) : void {
    this.email = email;
  }

  public getPassword (): string {
    return this.password;
  }

  public setPassword (password: string) {
    this.password = password;
  }

  public getPhone1 () : string {
    return this.phone1;
  }

  public setPhone1 (phone1: string) : void {
    this.phone1 = phone1;
  }

  public getPhone2 () : string {
    return this.phone2;
  }

  public setPhone2 (phone2: string) : void {
    this.phone2 = phone2;
  }

  public getWorkph(): string {
    return this.workPh;
  }

  public setWorkPh(workPh: string): void {
    this.workPh = workPh;
  }

  public getWorkExt(): string {
    return this.workExt;
  }

  public setWorkExt(workExt: string): void { 
    this.workExt = workExt;
  }

  public getAddress1 () : string {
    return this.address1;
  }

  public setAddress1 (address1: string) : void {
    this.address1 = address1;
  }

  public getAddress2 () : string {
    return this.address2;
  }

  public setAddress2 (address2: string) : void {
    this.address2 = address2;
  }

  public getCity(): string {
    return this.city;
  }

  public setCity (city: string) : void {
    this.city = city;
  }

  public getProvince (): string {
    return this.province;
  }

  public setProvince(province: string) : void {
    this.province = province;
  }

  public getPostal () : string {
    return this.postal;
  }

  public setPostal (postal: string) : void {
    this.postal = postal;
  }

  public getCountry () : Country {
    return this.country;
  }

  public setCountry(newCountry: Country) : void {
    this.country = newCountry;
  }
}