import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { ClientService } from '../services/client.service';

export interface ValidationResult {
  [key: string]: { [value: string]: boolean };
}

export class CustomValidator extends SalonmonsterHttpClient {

  constructor(protected http: HttpClient,
    private clientService: ClientService) {
    super(http);
  }

  public static isEmailValid(email: string) {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEXP.test(email);
  }

  public static isPhoneValid(phone: string) {
    const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return PHONE_REGEXP.test(phone);
  }

  public static validateEmail(control: FormControl) {
    return (CustomValidator.isEmailValid(control.value) || control.value === '') ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  public static validatePhone(control: FormControl) {
    return (CustomValidator.isPhoneValid(control.value) || control.value === '') ? null : {
      validatePhone: {
        valid: false
      }
    };
  }
  public static requireOnePhoneNumber(group: FormGroup): ValidationErrors | null {
    const phone1 = group.get('phone1');
    const phone2 = group.get('phone2');

    if ((!phone1 || !phone1.value) && (!phone2 || !phone2.value)) {
      return { 'atLeastOnePhoneRequired': true };
    }

    // Reset the form error if at least one phone is filled
    if ((phone1 && phone1.value) || (phone2 && phone2.value)) {
      group.setErrors(null);
    }

    return null;

  }

  public static clientEmailAvailabilityChecker(control: FormControl): Observable<ValidationResult> {

    // const inject =  ReflectiveInjector.resolveAndCreate(HTTP_PROVIDERS);

    return new Observable<ValidationResult>((observer) => {

      setTimeout(() => {
        observer.next({
          clientEmailAvailability: {
            valid: false
          }
        });

        observer.complete();
      }, 3000);

    });

  }


}