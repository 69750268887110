import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss'],
})

export class ConfirmDialog {

  public title: string;
  
  public message: string;

  constructor (public mdDialogRef: MatDialogRef<ConfirmDialog>) {

  }

}
