<br />
<sm-booking-service-list
  *ngIf="fromSummary == false"
  [booking]="booking"
  [title]="title"
  [subTitle]="subTitle"
  [showDeleteButton]="showDeleteButton || readOnly"
  (serviceDelete)="removeService($event)"
>
</sm-booking-service-list>
<sm-booking-summary-list
  *ngIf="fromSummary == true && anyBooking == true"
  [booking]="booking"
>
</sm-booking-summary-list>
<sm-booking-selected-summary-list
  *ngIf="fromSummary == true && anyBooking == false"
  [booking]="booking"
  [title]="title"
  [subTitle]="subTitle"
  [showDeleteButton]="showDeleteButton || readOnly"
  (serviceDelete)="removeService($event)"
>
</sm-booking-selected-summary-list>

<!-- <mat-card sm-restrict-width *ngIf="!hideNotesField">
  <mat-card-content *ngIf="readOnly">
    {{ booking.getServices()[0].getClientNotes() }}
  </mat-card-content>

  <mat-input-container sm-full-width *ngIf="!readOnly">
    <label>Notes for your service provider</label>
    <textarea matInput class="md-no-underline" rows="3" cols="50" (change)="onClientNoteUpdate($event)"></textarea>
  </mat-input-container> 
</mat-card> -->

<div class="smc-photo-manager" sm-center>
  <div
    *ngFor="let photo of iPhotos; let photoIndex = index"
    class="smc-photo-manager-img-container"
  >
    <mat-progress-spinner
      *ngIf="photo.isProcessing"
      mode="indeterminate"
    ></mat-progress-spinner>

    <ng-container *ngIf="!photo.isProcessing">
      <!-- <button ion-button icon-only color="light" large clear no-padding class="campground-photo-manager-img-remove-button" *ngIf="iPhotos.length > 1 && !isTouch">
            <ion-icon name="ios-move-outline"></ion-icon>
          </button>
          <button ion-button icon-only color="light" large clear (tap)="onRemovePhoto(photoIndex)" no-padding class="campground-photo-manager-img-move-button">
            <ion-icon name="close" ></ion-icon>
          </button> -->

      <mat-icon
        class="smc-photo-manager-img-container-remove-button"
        (click)="onRemovePhoto(photoIndex)"
      >
        close
      </mat-icon>

      <img
        mat-card-image
        *ngIf="!photo.raw"
        src="{{ PHOTO_ROOT }}{{ photo.name }}"
      />
      <img mat-card-image *ngIf="photo.raw" [src]="photo.name" />
    </ng-container>
  </div>
</div>

<input
  type="file"
  #imageUploadInput
  accept="image/jpeg,image/jpg,image/png"
  style="display: none"
  (change)="onImageUploadInputChange($event)"
/>

<!-- <img-cropper #cropper hidden="true" (onCrop)="onCrop($event)" [image]="data" [settings]="cropperSettings"></img-cropper> -->
<!-- <image-cropper [imageChangedEvent]="imageChangedEvent" [imageBase64]="tempBase64" [maintainAspectRatio]="true" [aspectRatio]="4 / 3"
      format="png" (imageCropped)="onCrop($event)"></image-cropper> -->
<div *ngIf="fromSummary == true" class="flex-justfycontent-center">
  <button
    mat-raised-button
    style="margin-right: 3em"
    [disabled]="isLoading"
    class="smc-button-secondary"
    (click)="onAddPhoto()"
    *ngIf="iPhotos.length < 2"
  >
    Add Photo
  </button>
  <button
    mat-raised-button
    [disabled]="isLoading"
    class="smc-button-secondary"
    (click)="onAddNote()"
  >
    Add Notes
  </button>
</div>
