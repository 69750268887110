<mat-list *ngIf="combineServices">
  <h1 mat-subheader>
    Please select a provider for your <span></span>
  </h1>
  <mat-divider></mat-divider>
  <mat-list-item *ngFor="let stylist of stylistsWhoOfferAllServices" (click)="selectStylistForCombinedServices(stylist)">
    <span mat-line>
      {{ stylist.getFirstName() }} {{ stylist.getLastName() }}
    </span>
  </mat-list-item>
</mat-list>

<mat-list *ngIf="!combineServices">
  <h1 mat-subheader>
    Please select a provider for your {{ servicesWithAnyProvider[serviceIndex].getName() }}
  </h1>
  <mat-divider></mat-divider>
  <mat-list-item *ngFor="let option of options[serviceIndex]" (click)="selectStylistForIndividualService(option)">
    <span mat-line>
      {{ option.stylist.getFirstName() }} {{ option.stylist.getLastName() }}
    </span>
  </mat-list-item>
</mat-list>
