import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BookingSelectedSummaryListComponent } from './booking-selected-summary-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {SharedModule} from '../../shared.module';

import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    BookingSelectedSummaryListComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    SharedModule,
  ],
  exports: [
    BookingSelectedSummaryListComponent
  ],
  entryComponents: [],
  providers: []
})
export class BookingSelectedSummaryListModule {}
