import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Client } from '../../../models/client.model';
import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/user.service';
import { CustomValidator } from '../../../utilities/custom-validator';
import { Utilities } from '../../../utilities/utilities';
import { ClientSignUpComponent } from './client-sign-up.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var window: any;
declare var FB: any;

enum Tabs {
  logIn = 0,
  signUp = 1
}

@Component({
  selector: 'client-login',
  templateUrl: 'client-login.component.html',
  styleUrls: ['./client-login.component.scss']
})

export class ClientLoginComponent implements OnInit {

  public isLoading: boolean;

  public submitted: boolean;

  public clientForm: FormGroup;

  public email: FormControl;

  public password: FormControl;

  public allowSignUp: boolean;

  public allowFBLogin: boolean;

  public selectedTab: FormControl;

  public loginError: boolean;

  public tabIndex: Tabs;


  // @Output() login = new EventEmitter<Client>();

  constructor(private userService: UserService,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    public mdDialogRef: MatDialogRef<ClientLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
    this.submitted = false;
    this.isLoading = false;

    // form controls
    this.email = new FormControl('', Validators.compose([Validators.required]));
    this.password = new FormControl('');
    this.clientForm = formBuilder.group({
      email: this.email,
      password: this.password
    });
    this.allowSignUp = true;
    this.allowFBLogin = true;
    this.selectedTab = new FormControl(0);
  }

  public ngOnInit() {

    if (window.FB) {
      window.FB.XFBML.parse();
    }

    this.allowSignUp = this.modalData ? this.modalData['allowSignUp'] : false;
    this.allowFBLogin = this.modalData ? this.modalData['allowFBLogin'] : false;

    this.tabIndex = Tabs.logIn;

    if (!this.userService.getSalon() || this.userService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  public onClientLogin($event = null) {
    if ($event) {
      $event.preventDefault();
    }
    this.submitted = true;
    this.loginError = false;
    if (this.clientForm.valid === false) {
      return;
    }

    this.isLoading = true
    this.loginService.loginUser(this.email.value, this.password.value)
      .subscribe(
        response => {
          this.isLoading = false;
          this.userService.login(response.user, response.token).then(result => {
            // this.login.emit(this.userService.getUser());
            // console.log("in client login component - should be logged in, just about to close dialog")
            this.mdDialogRef.close(this.userService.getUser());
            this.loginError = false;
          })
        },
        error => {
          this.isLoading = false;
          this.loginError = true;

          if (error.status >= 500) {
            // this.alertCtrl.create(Utilities.getDefaultErrorMessageConfig()).present();
          } else {
            // this.alertCtrl.create({
            //   message: `Username or password is invalid`,
            //   buttons: ['OK']
            // }).present();
          }

        }
      );
  }

  public onFacebookLogin() {
    this.isLoading = true;
    this.loginError = false;

    FB.login((response: any) => {
      if (response.status === 'connected') {
        FB.api('/me', { fields: 'first_name, last_name, email' }, (userResponse) => {
          const id: string = userResponse['id'];
          const email: string = userResponse['email'];
          const firstName: string = userResponse['first_name'];
          const lastName: string = userResponse['last_name'];

          this.loginService.facebookLogin(id, firstName, lastName, email)
            .subscribe((response) => {
              this.isLoading = false;
              this.userService.login(response.user, response.token);
              // this.login.emit(this.userService.getUser());
              this.mdDialogRef.close(this.userService.getUser());
              this.loginError = false;
            },
              (err) => {
                this.isLoading = false;
                this.loginError = true;
              });
        });
      } else {
        this.isLoading = false;
      }
    }, {

      // scopes: https://developers.facebook.com/docs/facebook-login/permissions#reference-public_profile
      scope: 'public_profile, email',
      return_scopes: true
    });
  }

  public onSignUp(client: Client) {
    this.email.setValue(client.getEmail());
    this.password.setValue(client.getPassword());
    this.onClientLogin();
  }

  public forgotPassword() {
    this.router.navigate(['/passwordreset/request']);
    this.mdDialogRef.close();
  }
  public staffLogin() {
    window.location.replace("https://" + this.userService.getSalon().getSubDomain() + ".salonmonster.com/6/salon");
  }
  public showLogin() {
    this.tabIndex = Tabs.logIn;
  }
  public showSignup() {
    this.tabIndex = Tabs.signUp;
  }

}
