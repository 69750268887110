import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BookingDetailsComponent } from './booking-details.component';
import { BookingServiceListModule } from '../booking-service-list/booking-service-list.module';
import {SharedModule} from '../../shared.module';
import { BookingSummaryListModule } from '../booking-summary-list/booking-summary-list.module';
import { BookingSelectedSummaryListModule } from '../booking-selected-summary-list/booking-selected-summary-list.module';
// import { MaterialModule, MdCardModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    BookingDetailsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    // MaterialModule,
    // MdCardModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FlexLayoutModule,
    BookingServiceListModule,
    BookingSummaryListModule,
    BookingSelectedSummaryListModule,
    ImageCropperModule
  ],
  exports: [
    BookingDetailsComponent
  ],
  entryComponents: [],
  providers: []
})
export class BookingDetailsModule {}
