import { Component, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { DialogsService } from '../../shared/services/dialogs/dialogs.service';

// export interface ClientChangePasswordErrors {
//   resetPasswordError: boolean;
//   emailSentError: boolean;
// }
@Component({
  selector: 'sm-client-change-password',
  templateUrl: 'client-change-password.component.html',
  styleUrls: ['client-change-password.component.scss']
})
export class ClientChangePasswordComponent implements OnInit {
  public isLoading: boolean;
  public passwordChangeForm: FormGroup;
  public password: FormControl;
  public newPassword: FormControl;
  public passwordHide: boolean;
  public newPasswordHide: boolean;
  public submitted: boolean;
  //public errors: ClientChangePasswordErrors;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dialogsService: DialogsService,

  ) {
    this.password = new FormControl('', Validators.required);
    this.newPassword = new FormControl('', Validators.required);
    this.passwordHide = true;
    this.newPasswordHide = true;
    this.submitted = false;
    //formControls
    this.passwordChangeForm = formBuilder.group({
      password: this.password,
      newPassword: this.newPassword,
    });
  }

  ngOnInit(): void {
  }
  onFormSubmit(): void {
    this.isLoading = true;
    this.submitted = true;
    if (this.passwordChangeForm.valid === false) {
      this.isLoading = false;
      return;
    }
    this.userService.changePassword(this.password.value, this.newPassword.value)
      .subscribe(
        res => {
          this.dialogsService.alert("Password Change", "Your password has been successfully changed.")
          this._resetFormValues();
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        },
        error => {
          this.isLoading = false;
          this.dialogsService.errorAlert().subscribe();
          this.changeDetectorRef.markForCheck();
        }
      );

  }
  sendPasswordChangeEmail(): void {
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();
    this.userService.requestPasswordReset(this.userService.getUser().getEmail(), this.userService.getSalon().getId())
      .subscribe(() => {
        this.isLoading = false;
        this.dialogsService.alert("Password Reset", "Your password reset email has been sent.")
        this.changeDetectorRef.markForCheck();
      },
        (err) => {
          this.isLoading = false;
          //this.errors.emailSentError = true;
          this.changeDetectorRef.markForCheck();
        });
  }
  private _resetFormValues(): void {
    this.password.setValue('');
    this.newPassword.setValue('');
    this.password.markAsPristine(); 
    this.newPassword.markAsPristine(); 
    this.password.markAsUntouched(); 
    this.newPassword.markAsUntouched(); 
    this.passwordHide = true;
    this.newPasswordHide = true;
    this.submitted = false;
  }

}
