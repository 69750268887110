<salonmonster-load-mask *ngIf="isLoading"></salonmonster-load-mask>
<div fxLayout="row" fxLayout.lt-md="column" style="min-height:100%;">
  <!-- Desktop stylist details -->
  <mat-card class="mat-elevation-z1" class='smc-stylist-card' *ngIf="stylist" fxShow.xs="false" fxShow.sm="false">
    <img class="smc-stylist-photo" fxShow.xs="false" fxShow.sm="false" mat-card-image *ngIf="stylist.getPhotoUrl() != undefined && stylist.getPhotoUrl() != ''" [alt]="stylist.getFirstName() + ' ' + stylist.getLastName()"  src='https://salonmonster.com/userfiles/profile_pics/{{stylist.getPhotoUrl()}}' />
    <mat-card-title fxShow.xs="false" fxShow.sm="false"> {{stylist.getFirstName()}} {{stylist.getLastName()}}</mat-card-title>
    <mat-card-subtitle fxShow.xs="false" fxShow.sm="false">{{stylist.getBio()}}  </mat-card-subtitle> 
    <mat-card-header fxShow="false" fxShow.xs="true" fxShow.sm="true">
      <div fxLayout="column" style="min-height:100%;">
        <img *ngIf="stylist.getPhotoUrl() != undefined && stylist.getPhotoUrl() != ''" [alt]="stylist.getFirstName() + ' ' + stylist.getLastName()"  src='https://salonmonster.com/userfiles/profile_pics/{{stylist.getPhotoUrl()}}' />
        <mat-card-title> {{stylist.getFirstName()}} {{stylist.getLastName()}}</mat-card-title>
        <mat-card-subtitle>{{stylist.getBio()}}</mat-card-subtitle>
      </div>
    </mat-card-header>

    <mat-card-content class='smc-stylist-bookingintro' [innerHtml]="stylist.getBookingIntro()">
    </mat-card-content>
    <mat-card-content sm-pale fxShow.xs="false" fxShow.sm="false" class='smc-cancellationpolicy'>
      <mat-icon style='font-size:18px;height:18px;width:18px;'>turned_in_not</mat-icon>
      <span [innerHtml]="stylist.getCancellationPolicy()">
      </span>
    </mat-card-content>
  </mat-card>
  <!-- Mobile stlist details -->
  <mat-card fxShow.md="false" fxShow.lg="false" fxShow.xl="false" class='smc-stylist-card' *ngIf="stylist">
    <div>
      <img mat-card-avatar class="smc-stylist-photo smc-avatar-large" *ngIf="stylist.getPhotoUrl() != undefined && stylist.getPhotoUrl() != ''" [alt]="stylist.getFirstName() + ' ' + stylist.getLastName()"  src='https://salonmonster.com/userfiles/profile_pics/{{stylist.getPhotoUrl()}}' />
    </div>
    <div>
        <div fxLayout="column" style="min-height:100%;">
          <mat-card-title> {{stylist.getFirstName()}} {{stylist.getLastName()}}</mat-card-title>
          <mat-card-subtitle>{{stylist.getBio()}}</mat-card-subtitle>
        </div>

      <mat-card-content class='smc-stylist-bookingintro' [innerHtml]="stylist.getBookingIntro()">
      </mat-card-content>
      <mat-card-content  sm-pale class='smc-cancellationpolicy'>
        <!-- <mat-icon style='font-size:18px;height:18px;width:18px;'>turned_in_not</mat-icon> -->
        <span [innerHtml]="stylist.getCancellationPolicy()">
        </span>
      </mat-card-content>
    </div>
  </mat-card>    
  <div fxFlex="2 1 auto" *ngIf="!isLoading">
    <mat-card [style.backgroundColor]="transparent" class="mat-elevation-z2"><mat-card-title>Please pick a service</mat-card-title></mat-card>
    <mat-nav-list>
      <ng-container *ngFor="let serviceCategory of serviceCategories">
        <ng-container *ngIf="serviceCategory.getBookableServiceDefinitions().length > 0">
          <mat-divider></mat-divider>
          <h1 mat-subheader>
            {{ serviceCategory.getCategoryName() }}
          </h1>
          <ng-container *ngFor="let serviceDefinition of serviceCategory.getBookableServiceDefinitions()">
            <mat-list-item class="smc-service-list-item"  (click)="serviceSelect(serviceDefinition)">
                <h2
                  class='smc-clickable-text'
                  tappable
                  mat-line>
                  {{serviceDefinition.getServiceName()}}
                </h2>
                <span mat-line *ngIf="serviceDefinition.getDescription() !== 'undefined'" [innerHtml]="serviceDefinition.getDescription() | safe: 'html'"></span>
                <p mat-line class='smc-service-details'>
                  {{ serviceDefinition.getStartingAt() == 1 ? 'Starting at' : '' }} 
                  {{ serviceDefinition.getPrice() | salonMonsterCurrency }}
                  {{ stylist.getHideDurations !== 1 ? 
                    ', about ' + (serviceDefinition.getDuration() + serviceDefinition.getProcessDuration() + serviceDefinition.getFinishDuration() | SalonMonsterFormatDurationPipe) 
                    : '' }}                </p>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </div>
  <mat-card sm-pale fxHide fxShow.xs fxShow.sm  *ngIf="stylist">
    <mat-card-content class='smc-cancellationpolicy'>
      <mat-icon style='font-size:18px;height:18px;width:18px;'>turned_in_not</mat-icon>
      <span [innerHtml]="stylist.getCancellationPolicy()">
      </span>
    </mat-card-content>
  </mat-card>
</div>