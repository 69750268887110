import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({ 
  selector: '[primaryButton]',
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()'
  }
})
export class SalonMonsterPrimaryDirective implements OnInit {


  constructor(private el: ElementRef) { 
  }

  public ngOnInit () {
   this.el.nativeElement.style.cursor = 'pointer';
   this.el.nativeElement.style.color = '#ffffff';
   this.el.nativeElement.style['background-color'] = '#07b6b7';
   this.el.nativeElement.style.border = '1px solid #07b6b7';
  }

  public onMouseEnter () {
    this.el.nativeElement.style.opacity = '0.8';
  }

  public onMouseLeave () {
    this.el.nativeElement.style.opacity = '1';
  }
}