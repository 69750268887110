import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../shared/services/user.service';
import { BookingStatus, UserBookingService } from '../../shared/services/user-booking.service';
import { Booking } from '../..//shared/models/booking.model';
import { Stylist } from '../../shared/models/stylist.model';
import { StylistService } from '../../shared/services/stylist.service';
import { Utilities } from '../../shared/utilities/utilities';


@Component({
  templateUrl: 'bookings-confirmation.component.html',
  styleUrls: ['bookings-confirmation.component.scss']
})
export class BookingsConfirmationComponent implements OnInit {

  public booking: Booking;
  public confirmationTitle: string;
  public requireApproval: boolean;
  
  constructor(private userService: UserService,
    private userBookingService: UserBookingService,
    private router: Router) {
    this.booking = this.userBookingService.getBooking().clone();
    this.userBookingService.resetBooking();
    this.confirmationTitle = Utilities.formatDate(this.booking.getStartDateTime(), 'h:mm a, dddd, MMMM D, Y');
  }

  public ngOnInit () {
    if (this.userService.isLoggedIn() === false ||
      ((this.booking.getStatus() === BookingStatus.Requested || this.booking.getStatus() === BookingStatus.Unconfirmed || this.booking.getStatus() === BookingStatus.WaitList) && this.booking.getServices().length === 0)) {
      this.newBookings();
    }
    this.requireApproval = false;
    for (var service of this.booking.getServices()){
      if(service.getStylist().getConfirmApptsManually()){
        this.requireApproval = true;
      }
    }
  }

  public newBookings () {
    this.router.navigate(['/']);
  }

}
