import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Utilities } from '../../shared/utilities/utilities';
import { UserService } from '../../shared/services/user.service';
import { UserBookingService } from '../../shared/services/user-booking.service';

@Component({
  selector: 'sm-help',
  templateUrl: 'help-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HelpPageComponent implements OnInit {

  constructor (private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private userBookingService: UserBookingService,
    private router: Router) {

    // src: https://stackoverflow.com/questions/36101756/angular2-routing-with-hashtag-to-page-anchor
    // router.events.subscribe(s => {
    //   if (s instanceof NavigationEnd) {
    //     const tree = router.parseUrl(router.url);
    //     if (tree.fragment) {
    //       const element = document.querySelector("#" + tree.fragment);
    //       if (element) { element.scrollIntoView(element); }
    //     }
    //   }
    // });
  }

  public ngOnInit () {}

  public scroll(el: HTMLElement) {
    el.scrollIntoView();
    return false;
  }


  public forgotPassword () {
    this.router.navigate(['/passwordreset/request']);
    return false;
  }
}
