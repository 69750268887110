import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { Salon } from '../shared/models/salon.model';
import { FooterService } from '../shared/services/footer.service';
@Component({
  selector: 'app-booking-disabled',
  templateUrl: './booking-disabled.component.html',
  styleUrls: ['./booking-disabled.component.scss']
})
export class BookingDisabledComponent implements OnInit {

  public salonPhone: string;

  constructor(
    private userService: UserService,
    private footerService: FooterService,
  ) { }

  ngOnInit(): void {
    const salon : Salon = this.userService.getSalon()
    this.salonPhone = salon.getPhone();
    this.footerService.toggleFooterVisibility(false);
  }

}
