import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({ 
  selector: '[tappable]',
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()'
  }
})
export class SalonMonsterTappableDirective implements OnInit {

  @Input() disabled: boolean;

  constructor(private el: ElementRef) {
  }

  public ngOnInit () {

   if (!this.disabled) {
     this.el.nativeElement.style.cursor = 'pointer';
     this.el.nativeElement.style.color = '#000'; 
   }
  }

  public onMouseEnter () {
    this.el.nativeElement.style.opacity = '0.5';
  }

  public onMouseLeave () {
    this.el.nativeElement.style.opacity = '1';
  }
}